import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";

import React, { useEffect, useState } from "react";
// estilos
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Checkbox } from "@mui/material";
import { CircularProgress, TablePagination } from "@material-ui/core";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import { encryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import { Link, useHistory } from "react-router-dom";

//estilos tablas
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckRole from "../CheckRole.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";
import AADService from "../funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "40vh",
    minHeight: "40vh",
  },
}));

//activos header
const headCells = [
  { id: "ID", numeric: false, disablePadding: true, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },

  {
    id: "compania",
    numeric: false,
    disablePadding: false,
    label: "Compañia",
  },
  {
    id: "responsable_ti",
    numeric: false,
    disablePadding: false,
    label: "Responsable TI",
  },
  {
    id: "responsable_negocio",
    numeric: false,
    disablePadding: false,
    label: "Responsable Negocio",
  },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",

    color: "white",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const NuevoEthicalHacking = () => {
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [validated, setValidated] = useState(false);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalOtros, setShowModalOtros] = useState(false);
  const [showModalClasificar, setShowModalClasificar] = useState(false);
  const [tipoClasificacion, setTipoClasificacion] = useState("");
  const [rowsc, setRowsC] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectOtros, setSelectOtros] = useState([]);
  const [selectedInfra, setSelectedInfra] = useState([]);
  const [
    clasificacionConfidencialidadPrin,
    setClasificacionConfidencialidadPrin,
  ] = React.useState("");
  const [clasificacionIntegridadPrin, setClasificacionIntegridadPrin] =
    React.useState("");
  const [clasificacionDisponibilidadPrin, setClasificacionDisponibilidadPrin] =
    React.useState("");
  const [metodoConfidencialidad, setMetodoConfidencialidad] =
    React.useState("");
  const [
    clasificacionConfidencialidadOtros,
    setClasificacionConfidencialidadOtros,
  ] = React.useState("");
  const [clasificacionIntegridadOtros, setClasificacionIntegridadOtros] =
    React.useState("");
  const [
    clasificacionDisponibilidadOtros,
    setClasificacionDisponibilidadOtros,
  ] = React.useState("");
  const [metodoIntegridad, setMetodoIntegridad] = React.useState("");
  const [metodoDisponibilidad, setMetodoDisponibilidad] = React.useState("");
  const [dataHallazgos, setDataHallazgos] = React.useState([]);
  let history = useHistory();
  const idRol = parseInt(localStorage.getItem("rolusuario"));
  //const isSelected = (name) => selectedInfra.indexOf(name) !== -1;
  const [usuarios, setUsuarios] = useState([]);
  const [DataClasificacion, setDataClasificacion] = React.useState([]);
  const [DataPreguntas, setDataPreguntas] = React.useState([]);
  const [rows, setRows] = useState([]);
  const [modalCompo, setModalCompo] = useState([]);
  const [archivo, setArchivo] = React.useState([]);
  const [info, setInfo] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const check = new CheckRole("/NuevoEthicalHacking");
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const [isCargaCompleta, setIsCargaCompleta] = useState(false);
  const [
    hayClasificacionesPendientesTablaApp,
    setHayClasificacionesPendientesTablaApp,
  ] = useState(false);
  const [
    hayClasificacionesPendientesTablaOtros,
    setHayClasificacionesPendientesTablaOtros,
  ] = useState(false);
  const clasificacion = [
    { id: "1", nombre: "Alto" },
    { id: "2", nombre: "Medio" },
    { id: "3", nombre: "Bajo" },
  ];
  const companiaOtros = [
    { id: "1", nombre: "Bancolombia" },
    { id: "2", nombre: "Nequi" },
    { id: "3", nombre: "BAM" },
    { id: "4", nombre: "Banco Agrícola" },
    { id: "5", nombre: "Banitsmo" },
  ];
  const programa = [
    { id: "1", nombre: "Ciberseguridad" },
    { id: "2", nombre: "Riesgos" },
    { id: "3", nombre: "Auditoria" },
  ];

  // Función para abrir el modales Carga - Otros - Clasificar
  const handleOpenModal = () => setShowModal(true);
  const handleOpenModalOtros = () => setShowModalOtros(true);
  const handleOpenModalClasificar = () => setShowModalClasificar(true);

  // Función para cerrar el modales  Carga - Otros - Clasificar
  const handleCloseModal = () => setShowModal(false);
  const handleCloseModalOtros = () => setShowModalOtros(false);
  const handleCloseModalClasificar = () => setShowModalClasificar(false);

  const handleClickInfra = (event, name) => {
    console.log("name", name);
    let newSelected = [];
    newSelected = newSelected.concat([], name.idactivo);
    setSelectedInfra(newSelected);
    setClasificacionConfidencialidadPrin(name.clasificacion_confidencialidad);
    setClasificacionIntegridadPrin(name.clasificacion_integridad);
    setClasificacionDisponibilidadPrin(name.clasificacion_disponibilidad);
    setMetodoConfidencialidad(name.metodo_confidencialidad);
    setMetodoIntegridad(name.metodo_integridad);
    setMetodoDisponibilidad(name.metodo_disponibilidad);
  };

  const handleClickOtros = (event, name) => {
    console.log("name", name);
    const selectedIndex = selectedInfra.indexOf(name.idotros_activo);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name.idotros_activo);
    }
    setSelectOtros(newSelected);
    setClasificacionConfidencialidadOtros(name.clasificacion_confidencialidad);
    setClasificacionIntegridadOtros(name.clasificacion_integridad);
    setClasificacionDisponibilidadOtros(name.clasificacion_disponibilidad);
  };

  const sendData = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let componentePrincipal = [];
      rowsc.map((dat) => {
        componentePrincipal.push({
          idcomponentedelcomponente: 0,
          idactivo: dat.idactivo,
          idtipo_activo: dat.idtipo_activo,
          idposicionresponsablenegocio: dat.idposicionresponsablenegocio,
          idposicionresponsableti: dat.idposicionresponsableti,
          idactivocomponente: 0,
          fechacreacion: today.toISOString(),
          estadoasociacion: true,
          idusuariocreacion: localStorage.getItem("idusuario"),
          fechamodificacion: today.toISOString(),
          idusuariomodificacion: localStorage.getItem("idusuario"),
          disp_numerico1: null,
          disp_numerico2: null,
          disp_varchar1: null,
          disp_varchar2: null,
        });
      });
      console.log("componente principal es", componentePrincipal);
      //Si el array de componentes principales está vacío, no se puede guardar
      if (componentePrincipal.length === 0) {
        alert("Debe seleccionar un componente principal");
        setValidated(false);
        setEstadoPost(4);
        return;
      }

      //Si nombre es vacío, no se puede guardar
      if (document.getElementById("NombreComponente").value === "") {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      //Si ip o dns son vacíos, no se puede guardar
      if (
        document.getElementById("IP").value === "" ||
        document.getElementById("DNS").value === ""
      ) {
        setValidated(false);
        setEstadoPost(4);
        return;
      }
      //Si ubicación lógica, relevancia o tipo ambiente son vacíos, no se puede guardar
      if (
        document.getElementById("Ubicacion_logica").value === "" ||
        document.getElementById("relevancia").value === "" ||
        document.getElementById("Tipo_Ambiente").value === ""
      ) {
        setValidated(false);
        setEstadoPost(4);
        return;
      }

      var data = {
        idcompania: localStorage.getItem("idcompania"),
        nombre: document.getElementById("NombreComponente").value,
        descripcion: document.getElementById("Descripcion").value,
        estado: true,
        componente_principal: 0,
        ubicacion_logica:
          document.getElementById("Ubicacion_logica") !== null
            ? document.getElementById("Ubicacion_logica").value
            : null,
        relevancia:
          document.getElementById("relevancia") !== null
            ? document.getElementById("relevancia").value
            : null,
        tipo_ambiente:
          document.getElementById("Tipo_Ambiente") !== null
            ? document.getElementById("Tipo_Ambiente").value
            : null,
        ip:
          document.getElementById("IP") !== null
            ? document.getElementById("IP").value
            : "",
        dns:
          document.getElementById("DNS") !== null
            ? document.getElementById("DNS").value
            : "",
        elemento_configuracion:
          document.getElementById("codigo_unico_aplicacion") !== null
            ? document.getElementById("codigo_unico_aplicacion").value
            : "",
        fechacreacion: today.toISOString(),
        idusuariocreacion: localStorage.getItem("idusuario"),
        fechamodificacion: today.toISOString(),
        idusuariomodificacion: localStorage.getItem("idusuario"),
        ComponentePrincipal: componentePrincipal,
      };
      console.log("data para post comp infra es", data);
      const objectToSend = JSON.stringify({
        ...data,
        ip: encryptData(data.ip),
        dns: encryptData(data.dns),
      });

      fetch(process.env.REACT_APP_API_URL + "onecomponent/0/", {
        method: "POST",
        body: objectToSend,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
        .then((data) =>
          data.json().then((response) => {
            if (data.status >= 200 && data.status < 300) {
              setEstadoPost(2);
              localStorage.setItem("idactivo", response.idactivo);
              history.push({
                pathname: "/EditarComponenteInfra", //Revisar //TODO
                state: "COM",
              });
            } else if (data.status >= 500) {
              setEstadoPost(5);
              if (
                data.non_field_errors[0] ===
                "The fields idactivo must make a unique set."
              ) {
                setEstadoPost(6);
              }
            } else if (data.status >= 400 && data.status < 500) {
              setEstadoPost(4);
            }
          })
        )
        .catch(function (err) {});
    }
    setValidated(true);
  };
  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}usuariosrol/1/${idRol}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        });

        const usuarios = await response.json();
        setUsuarios(usuarios); // Actualiza el estado con los usuarios obtenidos
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
      }
    };
    fetchUsuario();
    fetchUsuarios();
    obtenerPreguntas();
  }, []);

  useEffect(() => {
    if (rowsc) {
      const hayPendientesApp = rowsc.some(
        (row) =>
          !row.clasificacion_confidencialidad ||
          !row.clasificacion_integridad ||
          !row.clasificacion_disponibilidad
      );
      setHayClasificacionesPendientesTablaApp(hayPendientesApp);
    }
  }, [rowsc]);

  useEffect(() => {
    if (rows) {
      const hayPendientesOtros = rows.some(
        (row) =>
          !row.clasificacion_confidencialidad ||
          !row.clasificacion_integridad ||
          !row.clasificacion_disponibilidad
      );
      setHayClasificacionesPendientesTablaOtros(hayPendientesOtros);
    }
  }, [rows]);

  const obtenerComponentes = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "allcomponents/" +
        localStorage.getItem("idcompania") +
        "/1/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let datComponentes = await result.json();
      setModalCompo(datComponentes);

      return datComponentes;
    }
  };

  // const obtenerOneComponent = async () => {
  //   const result = await fetch(
  //     process.env.REACT_APP_API_URL +
  //       "onecomponent/" +
  //       localStorage.getItem("idcompania"),
  //     {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //       },
  //     }
  //   );
  //   if (result.status >= 200 && result.status <= 300) {
  //     let datComponentes = await result.json();
  //     setModalCompo(datComponentes);
  //   }
  // };

  const obtenerPreguntas = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "general/parametrosGeneralesxGrupoxParametro/Componentes/Clasificacion/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let datPreguntas = await result.json();
      setDataPreguntas(datPreguntas);
    } else if (result.status >= 400 && result.status <= 500) {
      setDataPreguntas([]);
      return result.status;
    }
  };
  const obtenerClasificacion = async (idactivo) => {
    const result = await fetch(
      process.env.REACT_APP_API_URL + "activoClasificacion/" + idactivo + "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let datClasificacion = await result.json();
      setDataClasificacion(datClasificacion[0]);
    } else if (result.status >= 400 && result.status <= 500) {
      setDataClasificacion([]);
      return result.status;
    }
  };

  const OtrasAplicaciones = async (data) => {
    const result = await fetch(
      process.env.REACT_APP_API_URL + "activoClasificacionOtros/0/",
      {
        method: "POST",
        body: JSON.stringify({
          idotros_activo: 0,
          idusuario: localStorage.getItem("idusuario"),
          idrol: localStorage.getItem("rolusuario"),
          info: data,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let dataOtraApp = await result.json();
      console.log("dataOtraApp", dataOtraApp);
      setRows((prevRows) => [
        ...prevRows,
        ...(Array.isArray(dataOtraApp) ? dataOtraApp : [dataOtraApp]),
      ]);
    } else if (result.status >= 400 && result.status <= 500) {
      //setRows([...rows, ...[]]);
      return result.status;
    }
  };

  const subirArchivo = (e) => {
    let tempArchivos = [];
    let temp = [];
    setEstadoPost(0);
    setArchivo([]);
    setInfo([]);

    for (let i = 0; i < e.length; i++) {
      if (
        e[i].name.split(".")[1] == "xlsx" ||
        e[i].name.split(".")[1] == "csv"
      ) {
        temp.push(e[i].name);
        tempArchivos.push(e[i]);
      }
    }
    setArchivo(tempArchivos);
    setInfo(temp);
    setLoading(false);
  };
  const postArchivo = async (event, file, mode) => {
    setLoading(true);
    if (file.length) {
      setEstadoPost(0);
      event.preventDefault();
      const form = event.currentTarget;
      let reader = new FileReader();
      reader.readAsArrayBuffer(file[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        var sheetName = workbook.SheetNames[0];
        var XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        procesarDatos(info[0], XL_row_object, mode);
      };
    } else {
      event.preventDefault();
      setLoading(false);
      setEstadoPost(3);
    }
    setLoading(false);
  };
  const procesarDatos = async (archivosKEY, hallazgos, mode) => {
    console.log("Hallazgos: ", hallazgos);
    let today = new Date().toISOString();
    let idUsuario = localStorage.getItem("idusuario");

    //Se debe recorrer el array de "hallazgos" para realizar validaciones de los campos
    hallazgos.every((hallazgo) => {
      //Validar que el campo "Nombre" no esté vacío
      if (!hallazgo["Vulnerability Name"]) {
        setEstadoPost(4);
        setLoading(false);
        return false;
      }

      // Validar que el campo "Description" no esté vacío
      if (!hallazgo.Description) {
        setEstadoPost(4);
        setLoading(false);
        return false;
      }

      // Validar que el campo "CVE/CWE" no esté vacío
      if (!hallazgo["CVE/CWE"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo CVE/CWE no puede estar vacío");
        return false;
      }

      // Validar que el campo "Attack Complexity (AC)" no esté vacío
      if (!hallazgo["Attack Complexity (AC)"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Attack Complexity no puede estar vacío");
        return false;
      }

      // Validar que el campo "Attack Vector (AV)" no esté vacío
      if (!hallazgo["Attack Vector (AV)"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Attack Vector no puede estar vacío");
        return false;
      }

      // Validar que el campo "Availability (A)" no esté vacío
      if (!hallazgo["Availability (A)"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Availability no puede estar vacío");
        return false;
      }

      // Validar que el campo "Confidentiality (C)" no esté vacío
      if (!hallazgo["Confidentiality (C)"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Confidentiality no puede estar vacío");
        return false;
      }

      // Validar que el campo "Integrity (I)" no esté vacío
      if (!hallazgo["Integrity (I)"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Integrity no puede estar vacío");
        return false;
      }

      // Validar que el campo "Privileges Required (PR)" no esté vacío
      if (!hallazgo["Privileges Required (PR)"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Privileges Required no puede estar vacío");
        return false;
      }

      // Validar que el campo "Scope (S)" no esté vacío
      if (!hallazgo["Scope (S)"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Scope no puede estar vacío");
        return false;
      }

      // Validar que el campo "Tipo ambiente" no esté vacío
      if (!hallazgo["Tipo ambiente"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Tipo ambiente no puede estar vacío");
        return false;
      }

      // Validar que el campo "User Interaction (UI)" no esté vacío
      if (!hallazgo["User Interaction (UI)"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo User Interaction no puede estar vacío");
        return false;
      }

      // Validar que el campo "Fecha de Observación" no esté vacío
      if (!hallazgo["Fecha de Observación"]) {
        setEstadoPost(4);
        setLoading(false);
        alert("El campo Fecha de Observación no puede estar vacío");
        return false;
      }

      //Cambiar fecha observación a formato yyyy-mm-dd
      let fechaObservacion = new Date(hallazgo["Fecha de Observación"]);
      hallazgo["Fecha de Observación"] = fechaObservacion.toISOString();

      // Validar que el campo "Evidencia" no esté vacío
      if (!hallazgo.Evidencia) {
        setEstadoPost(4);
        setLoading(false);
        return false;
      }
      hallazgo["Fecha creación"] = today;
      hallazgo["Fecha modificación"] = today;
      hallazgo["idusuariocreacion"] = idUsuario;
      hallazgo["idusuariomodificacion"] = idUsuario;
      return true;
    });

    const keyHallazgos = {
      idActivo: "idactivo",
      idActivoOtros: "idotros_activo",
      "Vulnerability Name": "nombre",
      Description: "descripcion",
      "CVE/CWE": "cve",
      "Attack Complexity (AC)": "AC",
      "Attack Vector (AV)": "AV",
      "Availability (A)": "A",
      "Confidentiality (C)": "C",
      "Integrity (I)": "I",
      "Privileges Required (PR)": "PR",
      Recomendación: "recomendacion",
      "Scope (S)": "S",
      "Tipo ambiente": "tipo_ambiente",
      "User Interaction (UI)": "UI",
      "Fecha de Observación": "fecha_observacion",
      Evidencia: "evidencia",
      "Fecha creación": "fechacreacion",
      "Fecha modificación": "fechamodificacion",
      idusuariocreacion: "idusuariocreacion",
      idusuariomodificacion: "idusuariomodificacion",
    };
    const transKeyHallazgos = (hallazgos) => {
      return hallazgos.map((hallazgo) => {
        const transformed = {};
        // Recorrer cada clave en el objeto `hallazgo`
        for (const key in hallazgo) {
          // Si existe un mapeo para la clave actual, usar el nuevo nombre
          const newKey = keyHallazgos[key] || key; // Si no hay mapeo, usa la clave original
          transformed[newKey] = hallazgo[key];
        }
        return transformed;
      });
    };
    const transformarHallazgos = transKeyHallazgos(hallazgos);
    const infoInforme = {
      nombre: document.getElementById("NombreInforme")?.value,
      descripcion: document.getElementById("Descripcion")?.value,
      programa: document.getElementById("programa")?.value,
      idusuario_experto: document.getElementById("NombreExperto")?.value,
      ids_activos: rowsc.map((row) => row.idactivo),
      ids_activos_otros: rows.map((row) => row.idotros_activo),
      estado: 1,
      fechacreacion: today,
      fechamodificacion: today,
      idusuariocreacion: idUsuario,
      idusuariomodificacion: idUsuario,
    };

    var data = {
      informe: infoInforme,
      hallazgos: transformarHallazgos,
    };

    // Verificar que los "idActivo" y "idActivoOtros" de data["hallazgos"]
    // Hagan parte de "ids_activos" y "ids_activos_otros" respectivamente de data["informe"]
    const idsActivos = new Set(data.informe.ids_activos);
    const idsActivosOtros = new Set(data.informe.ids_activos_otros);

    for (const hallazgo of data.hallazgos) {
      if (hallazgo.idactivo && !idsActivos.has(hallazgo.idactivo)) {
        alert(`El idActivo ${hallazgo.idactivo} no es válido.`);
        setLoading(false);
        setInfo([]);
        return;
      }
      if (
        hallazgo.idotros_activo &&
        !idsActivosOtros.has(hallazgo.idotros_activo)
      ) {
        alert(`El idActivoOtros ${hallazgo.idotros_activo} no es válido.`);
        setLoading(false);
        setInfo([]);
        return;
      }
    }

    let datos = JSON.stringify(data);

    console.log("Datos transformados:", transformarHallazgos);
    console.log("Datos a enviar: ", datos);
    fetch(
      process.env.REACT_APP_API_URL + "maestro/ethicalhacking/0/" + mode + "/",
      {
        method: "POST",
        //responseType: "blob",
        body: datos,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) =>
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            setEstadoPost(2);
            //Luego de que devuelve estado 200, es decir, que se crearon los registros
            //Se deberá pintar la información en el grid de hallazgos afuera
            setDataHallazgos(data.hallazgos);
            setLoading(false);
            //fetchdataPost();
            //setRowsC([...rowsc, ...data.data]);
            //alert(data.message);
            setShowModal(false);
            //setModalCompo([...modalCompo, ...data.data]);
            setIsCargaCompleta(true);
            data.onHide();
          } else if (response.status >= 500) {
            setEstadoPost(5);
            data.onHide();
            setLoading(false);
          } else if (response.status >= 400 && response.status < 500) {
            alert(data.message);
            setShowModal(false);
            setEstadoPost(4);
            setLoading(false);
          }
        })
      )
      .catch(function (err) {});
    setShowModal(true);
  };

  const fetchSendDataClasificacion = async (respuestas, type) => {
    console.log("Selected Infra:", selectedInfra);
    console.log("type:", type);
    if (type === "aplicacion") {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activoClasificacion/" +
          selectedInfra[0] +
          "/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            idactivo: selectedInfra[0],
            idusuario: localStorage.getItem("idusuario"),
            idrol: localStorage.getItem("rolusuario"),
            respuestas: respuestas,
            clasificacion_confidencialidad: clasificacionConfidencialidadPrin,
            clasificacion_integridad: clasificacionIntegridadPrin,
            clasificacion_disponibilidad: clasificacionDisponibilidadPrin,
            metodo_confidencialidad: metodoConfidencialidad,
            metodo_integridad: metodoIntegridad,
            metodo_disponibilidad: metodoDisponibilidad,
          }),
        }
      );

      if (result.status >= 200 && result.status <= 300) {
        //setEstadoPost(2);

        let dataComponentes = await obtenerComponentes();

        completarTabla(selectedInfra, "principales", dataComponentes);

        setShowModalClasificar(false);
      } else if (result.status >= 400 && result.status <= 500) {
        setEstadoPost(4);
      } else {
        setEstadoPost(5);
      }
    } else if (type === "otros") {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activoClasificacionOtros/" +
          selectOtros[0] +
          "/",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idotros_activo: selectOtros[0],
            idusuario: localStorage.getItem("idusuario"),
            idrol: localStorage.getItem("rolusuario"),
            respuestas: respuestas,
            clasificacion_confidencialidad: clasificacionConfidencialidadOtros,
            clasificacion_integridad: clasificacionIntegridadOtros,
            clasificacion_disponibilidad: clasificacionDisponibilidadOtros,
          }),
        }
      );

      if (result.status >= 200 && result.status <= 300) {
        let dataResult = await result.json();

        setRows((prevRows) =>
          prevRows.map((row) =>
            row.idotros_activo === dataResult.idotros_activo
              ? {
                  ...row,
                  clasificacion_confidencialidad:
                    dataResult.clasificacion_confidencialidad,
                  clasificacion_integridad: dataResult.clasificacion_integridad,
                  clasificacion_disponibilidad:
                    dataResult.clasificacion_disponibilidad,
                }
              : row
          )
        );

        setShowModalClasificar(false);
      } else if (result.status >= 400 && result.status <= 500) {
        setEstadoPost(4);
      } else {
        setEstadoPost(5);
      }
    }
  };

  const completarTabla = (obj, id, dataComponentes) => {
    console.log("obj: ", obj);
    console.log("id: ", id);
    console.log("ModalCompo: ", modalCompo);
    let temp = [];
    switch (id) {
      case "detalles":
        for (let i = 0; i < obj.length; i++) {
          const item = modalCompo.find((dato) => dato.idactivo === obj[i]);
          if (item) {
            temp.push(item);
          }
        }

        const updatedRows = rowsc.filter(
          (row) => !temp.some((newItem) => newItem.idactivo === row.idactivo)
        );

        setRowsC([...updatedRows, ...temp]);
        setModalShow(false);
        break;

      case "principales":
        for (let i = 0; i < obj.length; i++) {
          const item = dataComponentes.find((dato) => dato.idactivo === obj[i]);
          if (item) {
            temp.push(item);
          }
        }

        const updatedRowsOtros = rowsc.filter(
          (row) => !temp.some((newItem) => newItem.idactivo === row.idactivo)
        );

        setRowsC([...updatedRowsOtros, ...temp]);
        setModalShow(false);
        break;

      default:
        break;
    }
  };

  function ModalAplicaciones(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);

        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = contTecno.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contTecno, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contTecno.slice(1));
      } else if (selectedIndex === contTecno.length - 1) {
        newSelected = newSelected.concat(contTecno.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contTecno.slice(0, selectedIndex),
          contTecno.slice(selectedIndex + 1)
        );
      }
      setContTecno(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };

    const filteredData = modalCompo.filter((row) => {
      const query = queryText.toLowerCase();
      return (
        row.idactivo.toString().includes(query) ||
        row.nombre.toLowerCase().includes(query)
      );
    });

    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(contTecno, "detalles", [])}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalCompo.length}
                  />
                  <TableBody>
                    {filteredData.map((row, index) => {
                      const isItemSelected = isSelected(row.idactivo);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.idactivo,
                              row.idtipo_activo,
                              row
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.idactivo}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.idactivo}
                          </TableCell>
                          <TableCell align="left">{row.nombre}</TableCell>
                          <TableCell align="left">
                            {row.companias_usan}
                          </TableCell>
                          <TableCell align="left">
                            {row.UsuarioResponsableTI}
                          </TableCell>
                          <TableCell align="left">
                            {row.UsuarioResponsableNegocio}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={modalCompo.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  const ModalOtros = (props) => {
    const [loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState("");
    const [descripcionOtros, setDescripcionOtros] = useState("");
    const [compania, setCompania] = useState("");
    const [responsableNegocio, setResponsableNegocio] = useState("");
    const [clasificacionConfidencialidad, setClasificacionConfidencialidad] =
      useState("");
    const [clasificacionIntegridad, setClasificacionIntegridad] = useState("");
    const [clasificacionDisponibilidad, setClasificacionDisponibilidad] =
      useState("");

    const handleSave = () => {
      if (!nombre.trim() || !compania.trim() || !responsableNegocio.trim()) {
        alert("Por favor, complete todos los campos obligatorios.");
        return;
      }
      // Crear la nueva fila
      const newRow = {
        idotros_activo: 0,
        nombre,
        compania: compania,
        email_responsable: responsableNegocio,
        clasificacion_confidencialidad: clasificacionConfidencialidad,
        clasificacion_integridad: clasificacionIntegridad,
        clasificacion_disponibilidad: clasificacionDisponibilidad,
        descripcion: descripcionOtros,
      };

      OtrasAplicaciones(newRow);

      // Actualizar las filas de la tabla
      //setRows([...rows, newRow]);

      // Limpiar el formulario
      setNombre("");
      setCompania("");
      setResponsableNegocio("");
      setClasificacionConfidencialidad("");
      setClasificacionIntegridad("");
      setClasificacionDisponibilidad("");
      setDescripcionOtros("");
      props.onHide(); // Cierra el modal
    };

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Añadir Otras Aplicaciones
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableContainer component={Paper} className="table table-bordered">
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Compañia</StyledTableCell>
                    <StyledTableCell align="left">
                      Email del responsable
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Confidencialidad
                    </StyledTableCell>
                    <StyledTableCell align="left">Integridad</StyledTableCell>
                    <StyledTableCell align="left">
                      Disponibilidad
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableCell>
                    <input
                      type="text"
                      className="form-control text-center"
                      placeholder="Nombre"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                      required
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <select
                      className="form-control text-center"
                      onChange={(e) => setCompania(e.target.value)}
                      required
                      id="compania"
                    >
                      <option value="">Seleccione la compañia</option>
                      {companiaOtros.map((campo) => (
                        <option key={campo.id} value={campo.nombre}>
                          {campo.nombre}
                        </option>
                      ))}
                    </select>
                  </StyledTableCell>
                  <StyledTableCell>
                    <input
                      type="text"
                      className="form-control text-center"
                      placeholder="Email del responsable"
                      value={responsableNegocio}
                      onChange={(e) => setResponsableNegocio(e.target.value)}
                      required
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setClasificacionConfidencialidad(e.target.value)
                      }
                      id="confidencialidad"
                      required
                    >
                      <option value="">Seleccione la calificación...</option>
                      {clasificacion.map((campo) => (
                        <option key={campo.id} value={campo.nombre}>
                          {campo.nombre}
                        </option>
                      ))}
                    </select>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setClasificacionIntegridad(e.target.value)
                      }
                      id="integridad"
                      required
                    >
                      <option value="">Seleccione la calificación...</option>
                      {clasificacion.map((campo) => (
                        <option key={campo.id} value={campo.nombre}>
                          {campo.nombre}
                        </option>
                      ))}
                    </select>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setClasificacionDisponibilidad(e.target.value)
                      }
                      id="disponibilidad"
                      required
                    >
                      <option value="">Seleccione la calificación...</option>
                      {clasificacion.map((campo) => (
                        <option key={campo.id} value={campo.nombre}>
                          {campo.nombre}
                        </option>
                      ))}
                    </select>
                  </StyledTableCell>
                </TableBody>
              </Table>{" "}
              <Col sm={4} xs={12}>
                <label className="label form-label">Descripción</label>
              </Col>
              <Col>
                <textarea
                  type="text"
                  className="form-control text-center"
                  placeholder="Descripcion de la reclasificacion"
                  value={descripcionOtros}
                  onChange={(e) => setDescripcionOtros(e.target.value)}
                  rows="3"
                  id="descripcionOtros"
                ></textarea>
              </Col>
            </TableContainer>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <>{"Guardando..."}</>
            ) : (
              <Button
                className="botonPositivo"
                style={{ minWidth: "20%" }}
                onClick={handleSave}
              >
                Guardar
              </Button>
            )}
            <Button onClick={props.onHide} className="botonNegativo">
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };
  const ModalClasificar = (props) => {
    const [guardar, setLoading] = useState(false);
    let type = props.type;
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={true}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Clasificación
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TableContainer component={Paper} className="table table-bordered">
              <Table aria-label="customized table">
                <TableBody>
                  <InteractiveTable
                    idrol={idRol}
                    onSave={handleSaveClasificacion}
                    preguntas={DataPreguntas}
                    dataClasificacion={DataClasificacion}
                    type={type}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide} className="botonNegativo">
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };
  const ModalCarga = (props) => {
    const [loading, setLoading] = useState(false);
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Form
        // onSubmit={(e) => {
        // postArchivo(e, archivo);
        //     }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Cargue Masivo de Hallazgos
              <br></br>
              <a
                href="https://bancolombia.sharepoint.com/teams/CdulaRiesgoTI-Ciber/_layouts/15/download.aspx?UniqueId=08dd2ada%2D4a89%2D4639%2D884a%2D38df406be7d9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar plantilla
              </a>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col>
                <input
                  type="file"
                  name="files"
                  accept=".xlsx,.csv"
                  multiple
                  onChange={(e) => subirArchivo(e.target.files)}
                ></input>
              </Col>
            </Row>
            {info.length > 0 && (
              <Row>
                <Col>
                  <h5>Archivos cargados:</h5>
                  <ul>
                    {info.map((archivo, index) => (
                      <li key={index}>{archivo}</li>
                    ))}
                  </ul>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <>{"404"}</>
            ) : (
              <>
                <Button
                  className="botonPositivo"
                  style={{ minWidth: "20%" }}
                  onClick={(e) => {
                    postArchivo(e, archivo, "P");
                  }}
                >
                  Cargar
                </Button>

                {(props.idrol === 9 || props.idrol === 1) && (
                  <Button
                    className="botonPositivo"
                    style={{ minWidth: "20%", marginLeft: "10px" }}
                    onClick={(e) => {
                      postArchivo(e, archivo, "AI");
                    }}
                  >
                    Cargar por Auditoria
                  </Button>
                )}
              </>
            )}
            <Button onClick={props.onHide} className="botonNegativo">
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  const InteractiveTable = ({
    idrol,
    onSave,
    preguntas,
    dataClasificacion,
    type,
  }) => {
    console.log("InteractiveTable rendered");
    const [selectedRows, setSelectedRows] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [answersSend, setAnswersSend] = useState({});

    const handleSelectChange = (event, rowIndex, idparametrosgenerales) => {
      event.stopPropagation();
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [rowIndex]: event.target.value,
      }));

      setAnswersSend((prevAnswersSend) => ({
        ...prevAnswersSend,
        [idparametrosgenerales]: event.target.value === "Si" ? 1 : 0,
      }));
    };

    let globalRowIndex = 0;

    return (
      <div>
        <div
          style={{
            textAlign: "right",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Button
            className="botonPositivo3"
            onClick={() => {
              console.log("Guardando:", answersSend);
              onSave(answersSend, type);
            }}
          >
            Evaluar
          </Button>{" "}
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 800 }} aria-label="interactive table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "black" }}>
                <TableCell sx={{ color: "white" }}>Atributo</TableCell>
                <TableCell sx={{ color: "white" }}>Pregunta</TableCell>
                <TableCell sx={{ color: "white" }}>Clasificación</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {preguntas.map((item, itemIndex) => {
                const rowIndex = globalRowIndex++;
                const isDisabled =
                  type !== "otros" &&
                  ((dataClasificacion.metodo_confidencialidad !==
                    "Por evaluación" &&
                    item.disp_varchar1 === "Confidencialidad") ||
                    (dataClasificacion.metodo_integridad !== "Por evaluación" &&
                      item.disp_varchar1 === "Integridad") ||
                    (dataClasificacion.metodo_disponibilidad !==
                      "Por evaluación" &&
                      item.disp_varchar1 === "Disponibilidad"));

                return (
                  <TableRow key={`${itemIndex}-${item.vlrmetrica}`}>
                    <TableCell>{item.disp_varchar1}</TableCell>
                    <TableCell>{item.vlrmetrica}</TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <select
                        className="form-control"
                        value={answers[rowIndex] || ""}
                        onChange={(e) =>
                          handleSelectChange(
                            e,
                            rowIndex,
                            item.idparametrosgenerales
                          )
                        }
                        disabled={isDisabled}
                      >
                        <option value="">Seleccione</option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  const ExportarExcel = () => {
    // Datos Generales
    const idInforme =
      document.getElementById("idInforme")?.value || "No disponible";
    const nombreInforme =
      document.getElementById("NombreInforme")?.value || "No disponible";
    const descripcion =
      document.getElementById("Descripcion")?.value || "No disponible";
    const programa =
      document.getElementById("programa")?.value || "No disponible";
    const experto =
      document.getElementById("NombreExperto")?.value || "No disponible";

    const datosGenerales = [
      { Campo: "Id del informe", Valor: idInforme },
      { Campo: "Nombre del informe", Valor: nombreInforme },
      { Campo: "Descripción", Valor: descripcion },
      { Campo: "Programa", Valor: programa },
      { Campo: "Experto", Valor: experto },
    ];

    // Tabla de Aplicaciones
    const tablaAplicaciones = document.querySelectorAll(
      "#tablaAplicaciones tbody tr"
    );
    const datosAplicaciones = Array.from(tablaAplicaciones).map(
      (row, index) => {
        const celdas = row.querySelectorAll("td");
        return {
          ID: celdas[0]?.innerText || "No disponible",
          Nombre: celdas[1]?.innerText || "No disponible",
          Compañía: celdas[2]?.innerText || "No disponible",
          ResponsableTI: celdas[3]?.innerText || "No disponible",
          ResponsableNegocio: celdas[4]?.innerText || "No disponible",
          Confidencialidad: celdas[5]?.innerText || "No disponible",
          Integridad: celdas[6]?.innerText || "No disponible",
          Disponibilidad: celdas[7]?.innerText || "No disponible",
        };
      }
    );

    // Tabla Hallazgos
    const tablaHallazgos = document.querySelectorAll(
      "#tablaHallazgos tbody tr"
    );
    const datosHallazgos = Array.from(tablaHallazgos).map((row) => {
      const celdas = row.querySelectorAll("td");
      return {
        IdActivo: celdas[0]?.innerText || "No disponible",
        IdOtroActivo: celdas[1]?.innerText || "No disponible",
        IdHallazgo: celdas[2]?.innerText || "No disponible",
        Nombre: celdas[3]?.innerText || "No disponible",
        Descripción: celdas[4]?.innerText || "No disponible",
        fecha_observacion: celdas[5]?.innerText || "No disponible",
        cve: celdas[6]?.innerText || "No disponible",
        vector_string: celdas[7]?.innerText || "No disponible",
        base_score: celdas[8]?.innerText || "No disponible",
        criticidad: celdas[9]?.innerText || "No disponible",
        enviroment_score: celdas[10]?.innerText || "No disponible",
        enviroment_criticidad: celdas[11]?.innerText || "No disponible",
      };
    });

    // Tabla de Otros
    const tablaOtros = document.querySelectorAll("#tablaOtros tbody tr");
    const datosOtros = Array.from(tablaOtros).map((row) => {
      const celdas = row.querySelectorAll("td");
      return {
        ID: celdas[0]?.innerText || "No disponible",
        Nombre: celdas[1]?.innerText || "No disponible",
        Compañía: celdas[2]?.innerText || "No disponible",
        Responsable: celdas[3]?.innerText || "No disponible",
        Descripcion: celdas[4]?.innerText || "No disponible",
        Confidencialidad: celdas[5]?.innerText || "No disponible",
        Integridad: celdas[6]?.innerText || "No disponible",
        Disponibilidad: celdas[7]?.innerText || "No disponible",
      };
    });

    // Crear libro de trabajo
    const workbook = XLSX.utils.book_new();

    // Agregar hojas
    const hojaGenerales = XLSX.utils.json_to_sheet(datosGenerales);
    XLSX.utils.book_append_sheet(workbook, hojaGenerales, "Datos Generales");

    const hojaAplicaciones = XLSX.utils.json_to_sheet(datosAplicaciones);
    XLSX.utils.book_append_sheet(workbook, hojaAplicaciones, "Aplicaciones");

    const hojaOtros = XLSX.utils.json_to_sheet(datosOtros);
    XLSX.utils.book_append_sheet(workbook, hojaOtros, "Otros");

    const hojaHallazgos = XLSX.utils.json_to_sheet(datosHallazgos);
    XLSX.utils.book_append_sheet(workbook, hojaHallazgos, "Hallazgos");

    // Exportar archivo
    XLSX.writeFile(workbook, "InformeCompleto.xlsx");
  };

  const handleSaveClasificacion = (answersSend, type) => {
    if (Object.keys(answersSend).length === 0) {
      alert(
        "Por favor, responda todas las preguntas disponibles antes de guardar."
      );
      return;
    }
    const respuestas = DataPreguntas.map((pregunta) => ({
      idpregunta: pregunta.idparametrosgenerales,
      respuesta: answersSend.hasOwnProperty(pregunta.idparametrosgenerales)
        ? answersSend[pregunta.idparametrosgenerales]
        : "",
    }));
    fetchSendDataClasificacion(respuestas, type);
  };

  return (
    <>
      <AlertDismissibleExample alerta={estadoPOST} />
      <ModalClasificar
        show={showModalClasificar}
        onHide={handleCloseModalClasificar}
        type={tipoClasificacion}
      />
      <ModalOtros show={showModalOtros} onHide={handleCloseModalOtros} />
      <ModalCarga
        idrol = {idRol}
        show={showModal}
        onHide={() => {
          handleCloseModal();
          setInfo([]);
        }}
      />

      <ModalAplicaciones
        componentes={componentesSelected}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Form
        id="formData"
        onSubmit={(e) => sendData(e)}
        noValidate
        validated={validated}
      >
        <Row className="mb-3">
          <Col>
            {" "}
            <h2 className="subtitulo">Info General</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Nombre del informe*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              placeholder="Nombre del informe"
              defaultValue={""}
              required
              id="NombreInforme"
            ></input>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca un nombre.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Descripción*</label>
          </Col>
          <Col sm={8} xs={12}>
            <textarea
              type="text"
              className="form-control text-center"
              placeholder="Descripcion de la reclasificacion"
              defaultValue={""}
              rows="3"
              required
              id="Descripcion"
            ></textarea>
            <Form.Control.Feedback type="invalid">
              Por favor introduzca una descripcion.
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label forn-label">Programa*</label>
          </Col>
          <Col sm={8} xs={12}>
            <input
              type="text"
              className="form-control text-center"
              // el valor 1 se deja de prueba, se debe cambiar por el valor del programa
              value={
                idRol === 1
                  ? "Administrador"
                  : idRol === 9
                  ? "Auditoria"
                  : idRol === 2
                  ? "Riesgos"
                  : idRol === 10
                  ? "Ciberseguridad"
                  : "Sin programa"
              }
              required
              id="programa"
              readOnly
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={4} xs={12}>
            <label className="label form-label">Experto*</label>
          </Col>
          <Col sm={8} xs={12}>
            <select
              className="form-control text-center"
              id="NombreExperto"
              required
            >
              <option value="">Seleccione un experto</option>
              {usuarios.map((usuario) => (
                <option
                  key={usuario.idusuario}
                  value={usuario.nombre && usuario.idusuario}
                >
                  {usuario.nombre} {usuario.apellido}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <br />
            <hr />
          </Col>
        </Row>

        <>
          <Row className="mb-3">
            <Col md={6}>
              <h2 className="subtitulo">Alcance de la prueba</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={9}>
              <h2 className="subtitulo">Aplicaciones</h2>
            </Col>

            <Col md={2}>
              <Button
                className="botonIngreso"
                onClick={() => {
                  setModalShow(true);
                  obtenerComponentes();
                }}
              >
                Añadir
              </Button>{" "}
            </Col>
          </Row>
          {hayClasificacionesPendientesTablaApp && (
            <Row className="mb-3">
              <Col>
                <p style={{ color: "red" }}>
                  Existen aplicaciones con clasificaciones pendientes. Por
                  favor, revíselas.
                </p>
              </Col>
            </Row>
          )}
          <Row className="mb-3">
            <TableContainer component={Paper} className="table table-bordered">
              <Table aria-label="customized table" id="tablaAplicaciones">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">ID</StyledTableCell>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Compañia</StyledTableCell>
                    <StyledTableCell align="left">
                      Responsable TI
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Responsable Negocio
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Confidencialidad
                    </StyledTableCell>
                    <StyledTableCell align="left">Integridad</StyledTableCell>
                    <StyledTableCell align="left">
                      Disponibilidad
                    </StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsc !== null
                    ? rowsc.map((row, index) => {
                        //const isItemSelected = isSelected(row.idactivo);
                        return (
                          <StyledTableRow
                            key={row.idactivo}
                            //onClick={(event) => handleClickInfra(event, row)}
                            //selected={isItemSelected}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <StyledTableCell align="left">
                              {row.idactivo}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.nombre}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.companias_usan}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.UsuarioResponsableTI}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.UsuarioResponsableNegocio}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.clasificacion_confidencialidad ||
                                "No Clasificado"}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.clasificacion_integridad || "No Clasificado"}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.clasificacion_disponibilidad ||
                                "No Clasificado"}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Button
                                type="button"
                                title="La clasificación por evaluación podría estar deshabilitada, ya que se hereda del activo de información y de las marcaciones BIA y SOX."
                                onClick={(event) => {
                                  handleClickInfra(event, row);
                                  setTipoClasificacion("aplicacion");
                                  handleOpenModalClasificar(row);
                                  obtenerClasificacion(row.idactivo);
                                }}
                                className={"p-2 m-2"}
                                disabled={
                                  row.metodo_confidencialidad &&
                                  row.metodo_confidencialidad !==
                                    "Por evaluación" &&
                                  row.metodo_integridad &&
                                  row.metodo_integridad !== "Por evaluación" &&
                                  row.metodo_disponibilidad &&
                                  row.metodo_disponibilidad !== "Por evaluación"
                                }
                              >
                                Clasificar
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Row>

          <Row className="mb-3">
            <Col md={9}>
              <h2 className="subtitulo">Otros</h2>
            </Col>

            <Col md={2}>
              <Button onClick={handleOpenModalOtros} className="botonIngreso">
                Añadir Otros
              </Button>
            </Col>
          </Row>
          {hayClasificacionesPendientesTablaOtros && (
            <Row className="mb-3">
              <Col>
                <p style={{ color: "red" }}>
                  Existen aplicaciones con clasificaciones pendientes. Por
                  favor, revíselas.
                </p>
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <TableContainer component={Paper} className="table table-bordered">
              <Table aria-label="customized table" id="tablaOtros">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">ID</StyledTableCell>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Compañia</StyledTableCell>
                    <StyledTableCell align="left">
                      Email Responsable
                    </StyledTableCell>
                    <StyledTableCell>Descripción</StyledTableCell>
                    <StyledTableCell align="left">
                      Confidencialidad
                    </StyledTableCell>
                    <StyledTableCell align="left">Integridad</StyledTableCell>
                    <StyledTableCell align="left">
                      Disponibilidad
                    </StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows !== null
                    ? rows.map((row, index) => {
                        //const isItemSelected = isSelected(row.idotros_activo);
                        return (
                          <StyledTableRow
                            key={row.idotros_activo}
                            hover
                            //onClick={(event) => handleClickOtros(event, row)}
                            //selected={isItemSelected}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <StyledTableCell>
                              {row.idotros_activo}
                            </StyledTableCell>
                            <StyledTableCell>{row.nombre}</StyledTableCell>
                            <StyledTableCell>{row.compania}</StyledTableCell>
                            <StyledTableCell>
                              {row.email_responsable}
                            </StyledTableCell>
                            <StyledTableCell>{row.descripcion}</StyledTableCell>
                            <StyledTableCell>
                              {/* <select
                                className="form-control"
                                id="clasificacionConfidencialidad"
                                required
                              >
                                <option value="">
                                  {row.clasificacion_confidencialidad ||
                                    "No Clasificado"}
                                </option>
                                {clasificacion.map((campo) => (
                                  <option key={campo.id} value={campo.nombre}>
                                    {campo.nombre}
                                  </option>
                                ))}
                              </select> */}
                              {row.clasificacion_confidencialidad ||
                                "No Clasificado"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {/* <select
                                className="form-control"
                                id="clasificacionIntegridad"
                                required
                              >
                                <option value="">
                                  {row.clasificacion_integridad ||
                                    "No Clasificado"}
                                </option>
                                {clasificacion.map((campo) => (
                                  <option key={campo.id} value={campo.nombre}>
                                    {campo.nombre}
                                  </option>
                                ))}
                              </select> */}
                              {row.clasificacion_integridad || "No Clasificado"}
                            </StyledTableCell>
                            <StyledTableCell>
                              {/* <select
                                className="form-control"
                                id="clasificacionDisponibilidad"
                                required
                              >
                                <option value="">
                                  {row.clasificacion_disponibilidad ||
                                    "No Clasificado"}
                                </option>
                                {clasificacion.map((campo) => (
                                  <option key={campo.id} value={campo.nombre}>
                                    {campo.nombre}
                                  </option>
                                ))}
                              </select> */}
                              {row.clasificacion_disponibilidad ||
                                "No Clasificado"}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Button
                                type="button"
                                onClick={(event) => {
                                  handleClickOtros(event, row);
                                  setTipoClasificacion("otros");
                                  handleOpenModalClasificar(row);
                                }}
                                className="p-2 m-2"
                                //disabled={
                                //  (row.clasificacion_confidencialidad &&
                                //    row.clasificacion_confidencialidad !==
                                //      "No Clasificado") ||
                                //  (row.clasificacion_integridad &&
                                //    row.clasificacion_integridad !==
                                //      "No Clasificado") ||
                                //  (row.clasificacion_disponibilidad &&
                                //    row.clasificacion_disponibilidad !==
                                //      "No Clasificado")
                                //}
                              >
                                Clasificar
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Row>
        </>

        <Row className="mb-3">
          <Col>
            <br />
            <hr />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={9}>
            <h2 className="subtitulo">Hallazgos o debilidades*</h2>
          </Col>

          <Col md={2}>
            {check.checkPermits(opcionesGenerales, "POST") ? (
              <Button className="botonDescargar" onClick={handleOpenModal}>
                Cargar
              </Button>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row className="mb-3">
          <TableContainer component={Paper} className="table table-bordered">
            <Table aria-label="customized table" id="tablaHallazgos">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Id Activo</StyledTableCell>
                  <StyledTableCell align="left">Id Otro Activo</StyledTableCell>
                  <StyledTableCell align="left">Id Hallazgo</StyledTableCell>
                  <StyledTableCell align="left">Nombre</StyledTableCell>
                  <StyledTableCell align="left">Descripcion</StyledTableCell>
                  <StyledTableCell align="left">
                    Fecha Observación
                  </StyledTableCell>
                  <StyledTableCell align="left">CVE/CWE</StyledTableCell>
                  <StyledTableCell align="left">Vector String</StyledTableCell>
                  <StyledTableCell align="left">Base Score</StyledTableCell>
                  <StyledTableCell align="left">Criticidad</StyledTableCell>
                  <StyledTableCell aling="left">
                    Environmental Score
                  </StyledTableCell>
                  <StyledTableCell aling="left">
                    Environmental Ctriticidad
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataHallazgos.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell align="left">
                      {row.idactivo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.idotros_activo}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.idhallazgos_ethical_hacking}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.nombre}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.descripcion}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.fecha_observacion}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.cve}</StyledTableCell>
                    <StyledTableCell align="left">{row.trama}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.base_score}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.criticidad}
                    </StyledTableCell>
                    <StyledTableCell aling="left">
                      {row.environmental_score}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.environmental_criticidad}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Row>

        <Row className="mb-3">
          <Col md={5}></Col>
          <Col md={2}>
            <Button
              className="botonDescargar"
              onClick={() => ExportarExcel()}
              disabled={!isCargaCompleta}
            >
              Descargar excel
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NuevoEthicalHacking;
