import { useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Modal } from "react-bootstrap";
import { makeStyles } from "@mui/styles";

const useStylesModal = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    container: {
        maxHeight: "55vh",
        minHeight: "55vh",
    },
}));

const ModalComponent = () => {
    const classes = useStylesModal();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const vulnerabilidades = [
        { id: 284, nombre: "Control de acceso inadecuado" },
        { id: 435, nombre: "Interacción indebida entre varias entidades que actúan correctamente" },
        { id: 664, nombre: "Control indebido de un recurso a lo largo de su vida útil" },
        { id: 682, nombre: "Cálculo incorrecto" },
        { id: 691, nombre: "Gestión insuficiente del flujo de control" },
        { id: 693, nombre: "Fallo del mecanismo de protección" },
        { id: 697, nombre: "Comparación incorrecta" },
        { id: 703, nombre: "Comprobación o gestión incorrecta de condiciones excepcionales" },
        { id: 707, nombre: "Neutralización incorrecta" },
        { id: 710, nombre: "Incumplimiento de las normas de codificación" },
    ];

    const amenazas = [
        { id: 156, nombre: "Participar en interacciones engañosas" },
        { id: 210, nombre: "Abusar de funcionalidad existente" },
        { id: 255, nombre: "Manipular estructuras de datos" },
        { id: 262, nombre: "Manipular recursos del sistema" },
        { id: 152, nombre: "Inyectar elementos inesperados" },
        { id: 223, nombre: "Emplear técnicas probabilísticas" },
        { id: 172, nombre: "Manipular la sincronización y el estado" },
        { id: 118, nombre: "Recopilar y analizar información" },
        { id: 225, nombre: "Alterar el control de acceso" },
    ];

    return (
        <>
            <HelpIcon style={{ cursor: "pointer" }} onClick={handleShow} />
            
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Vulnerabilidades y Amenazas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TableContainer component={Paper} className={classes.container}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID Vulnerabilidad Tipo (Filas)</TableCell>
                                    <TableCell>Nombre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {vulnerabilidades.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.nombre}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper} className={classes.container} style={{ marginTop: "16px" }}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID Amenaza Tipo (Columnas)</TableCell>
                                    <TableCell>Nombre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {amenazas.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.nombre}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalComponent;
