import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { TooltipComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

const BarChartComponent = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
      if (!chartRef.current || !data) return;

      const vulnerabilidades = [
        { id: 284, nombre: "Control\nde acceso\ninadecuado" },
        { id: 435, nombre: "Interacción\nindebida\nentre varias\nentidades" },
        { id: 664, nombre: "Control\nindebido\nde un recurso" },
        { id: 682, nombre: "Cálculo\nincorrecto" },
        { id: 691, nombre: "Gestión\ninsuficiente del\nflujo de\ncontrol" },
        { id: 693, nombre: "Fallo del\nmecanismo\nde protección" },
        { id: 697, nombre: "Comparación\nincorrecta" },
        { id: 703, nombre: "Comprobación\nincorrecta\nde condiciones" },
        { id: 707, nombre: "Neutralización\nincorrecta" },
        { id: 710, nombre: "Incumplimiento\nde normas\nde codificación" },
      ];

      const chart = echarts.init(chartRef.current);

      const values = data.map(item => item.Cantidad);
      const maxValue = Math.max(...values);
      
      const formattedData = data.map(item => ({
          value: item.Cantidad,
          itemStyle: item.Cantidad === maxValue ? { color: '#a90000' } : {}
      }));

      const xAxisLabels = data.map(item => 
        vulnerabilidades.find(v => v.id === Number(item.idVulnerabilidadTipo))?.nombre || item.idVulnerabilidadTipo
      );

      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xAxisLabels
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: formattedData,
            type: 'bar'
          }
        ]
      };

      chart.setOption(option);

      return () => {
        chart.dispose();
      };
  }, [data]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>;
};

export default BarChartComponent;