import AADService from "../../funcionesAuth.js";

const AzureADService = new AADService();

export const fetchRolesData = async () => {
  try {
    const token = AzureADService.getToken();
    const response = await fetch(process.env.REACT_APP_API_URL + "roles/1/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getOptionRolData = async (rol) => {
  try {
    const token = AzureADService.getToken();
    const response = await fetch(
      process.env.REACT_APP_API_URL + `roles/opcion/${rol}/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getOptionTableData = async () => {
  try {
    const token = AzureADService.getToken();
    const response = await fetch(process.env.REACT_APP_API_URL + `rolesId/0/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token
      },
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    return null;
  }
};
