import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { forwardRef } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import MaterialTable from "material-table";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterList from "@mui/icons-material/FilterList";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../../CheckRole";
import Select from "react-select";
//import ExportExcel from "react-export-excel";
import CircularProgress from "@mui/material/CircularProgress";
import AADService from "../../funcionesAuth.js";
import { IoMdDownload } from "react-icons/io";
import XLSX from "xlsx";
import styled from "@mui/material/styles/styled";
import { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return (
        <Alert variant="success">
          Cerradas correctamente las vulnerabilidades
        </Alert>
      );
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

//const ExcelFile = ExportExcel.ExcelFile;
//const ExcelSheet = ExportExcel.ExcelSheet;
//const ExcelColum = ExportExcel.ExcelColum;

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Eliminar_vul_tec() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      //margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  var columns = [
    {
      field: "idvulnerabilidad_tecnica",
      title: "Id Vulnerabilidad",
    },
    {
      field: "fuente",
      title: "Fuente de identificación",
    },
    {
      field: "nombrecomponente",
      title: "Nombre del componente",
    },
    {
      field: "ip",
      title: "Dirección IP",
    },
    {
      field: "detallevulnerabilidad",
      title: "Detalle de la Vulnerabilidad",
    },
    {
      field: "vt_CVE",
      title: "CVEs asociados",
      render: rowData => (
        rowData.vt_CVE ? (
          <ul>
            {rowData.vt_CVE.split(' ').map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <span></span>
        )
      ),
    },
    {
      field: "fuentecierre",
      title: "Motivo de cierre",
    },
    {
      field: "estadovulnerabilidad",
      title: "Estado de la Vulnerabilidad",
    }
  ];

  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [data, setData] = useState([]); //table data
  const [dataDescarga, setDataDescarga] = useState([]);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [filter, setFilter] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [selectedDetalle, setSelectedDetalle] = React.useState([]);
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const check = new CheckRole("/evaluacion-eliminar-vulnerabilidades-tecnicas");
  let dataput = [];
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [loadingCarge, setLoadingCarge] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowTaxonomia, setModalShowTaxonomia] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loadingDetalle, setLoadingDetalle] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [detalleTaxonomia, setDetalleTaxonomia] = React.useState([]);
  const [detalleCWE, setDetalleCWE] = React.useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  
  const opcionesCierre = [
    { value: "Error en la carga", label: "Error en la carga" },
    { value: "Cerrado por PA", label: "Cerrado por PA" },
    { value: "Re-escaneo", label: "Re-escaneo" },
  ];

  const stylesSelect = {
    control: (base) => ({
      ...base,
      fontFamily: "Roboto",
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "CIBFont Sans Regular",
    }),

    option: (provided, state) => ({
      ...provided,
      //color: state.isFocused ? 'white' : '#495057',
    }),
  };

  function ModalSelect(props) {
    const [valueCierre, setValueCierre] = React.useState([null]);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Seleccione el motivo de cierre de las Vulnerabilidades
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ padding: "10px", justifyContent: "center" }}>
            <Col sm={8} xs={10}>
              <Select
                id="valueoption"
                placeholder={"Seleccione una opción del motivo del cierre..."}
                styles={stylesSelect}
                options={opcionesCierre}
                required={"campo obligatorio"}
                onChange={(option) => {
                  setValueCierre(option.label);
                }}
              />
            </Col>
          </Row>
          <Row className="mb-3" style={{ padding: "10px" }}>
            <Col md={2}>
              <Button
                className="botonIngreso"
                type="button"
                onClick={() => DeleteVul(valueCierre, selected)}
              >
                Cerrar
              </Button>{" "}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  function ModalDetalleTaxonomia({ show, onHide, detalleTaxonomia, detalleCWE, error, loadingDetalle }) {
    return (
      <Modal
        show={show}
        onHide={() => {
          onHide();
          setErrorModal(null);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalle de Taxonomía
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {loadingDetalle ? (
          <Row style={{ padding: "10px", justifyContent: "center" }}>
            <CircularProgress />
          </Row>
        ) : error ? (
          <Row style={{ padding: "10px", justifyContent: "center" }}>
            <Col sm={12} xs={12}>
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {/* Contenido del modal cuando loadingDetalle es false */}
            <Row style={{ padding: "10px", justifyContent: "center" }}>
              <Col sm={12} xs={12}>
                <label className="label form-label">
                  Detalle de los CVEs seleccionados
                </label>
              </Col>
              <Col>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="detalle taxonomia table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>CVE ID</StyledTableCell>
                        <StyledTableCell>CWE IDs</StyledTableCell>
                        <StyledTableCell>Severidad</StyledTableCell>
                        <StyledTableCell>Descripción</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detalleTaxonomia.map((row) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell>{row.cve_id}</StyledTableCell>
                          <StyledTableCell>
                            <ul>
                              {row.cwe_id.split("|").map((cwe, index) => (
                                <li key={index}>{cwe.trim()}</li>
                              ))}
                            </ul>
                          </StyledTableCell>
                          <StyledTableCell>{row.severity}</StyledTableCell>
                          <StyledTableCell>{row.description}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>
            <Row style={{ padding: "10px", justifyContent: "center" }}>
              <Col sm={12} xs={12}>
                <label className="label form-label">
                  Detalle de las debilidades asociadas
                </label>
              </Col>
              <Col>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="detalle CWE table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ID CWE</StyledTableCell>
                        <StyledTableCell>Nombre</StyledTableCell>
                        <StyledTableCell>Abstracción</StyledTableCell>
                        <StyledTableCell>Descripción</StyledTableCell>
                        <StyledTableCell>Consecuencias</StyledTableCell>
                        <StyledTableCell>Link</StyledTableCell>
                        <StyledTableCell>Amenazas Relacionadas</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detalleCWE.map((row) => (
                        <StyledTableRow key={row[0].id}>
                          <StyledTableCell>{row[0].idcwe}</StyledTableCell>
                          <StyledTableCell>{row[0].name}</StyledTableCell>
                          <StyledTableCell>{row[0].abstraction}</StyledTableCell>
                          <StyledTableCell>{row[0].description}</StyledTableCell>
                          <StyledTableCell>
                            <ul>
                              {row[0].common_consequences[0].Scopes.map(
                                (scope, index) => (
                                  <li key={index}>{scope}</li>
                                )
                              )}
                            </ul>
                          </StyledTableCell>
                          <StyledTableCell>
                            <a
                              href={row[0].link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver más detalles
                            </a>
                          </StyledTableCell>
                          <StyledTableCell>
                            <ul>
                              {row[0].related_attack_patterns.map(
                                (pattern, index) => (
                                  <li key={index}>
                                    ID CAPEC: {pattern.CAPEC_ID},
                                    <a
                                      href={pattern.Link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Ver más detalles
                                    </a>
                                  </li>
                                )
                              )}
                            </ul>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    const fetchdata = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "vulnerabilidad/tecnica/cierre" +
          "/" +
          localStorage.getItem("idevaluacion") +
          "/",

        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let param = await result.json();
      if (result.status >= 200 && result.status < 300) {
        setData(param);
        setLoading(false);
        setError(true);
      } else {
        setData([]);
        setLoading(false);
        setError(false);
      }
    };
    fetchdata();

    const fetchdataDescarga = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "evaluacion/vultec/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );
      let paramdescarga = await result.json();
      const data = paramdescarga.map((item) => {
        let clasificacion = "";
        const score = item.cveb_vectorscorebco;
        if(score > 0 && score <=3.9){
          clasificacion="Bajo";
        }
        else if(score >= 4 && score <=6.9){
          clasificacion="Medio";
        }
        else if(score >= 7 && score <=8.9){
          clasificacion="Alto";
        }
        else if(score >= 9 && score <=10){
          clasificacion="Critico";
        }
        
        return{
          ...item,
          clasificacion,
        };
      });
      if (result.status >= 200 && result.status < 300) {
        setDataDescarga(data);
        setLoading(false);
        setError(true);
      } else {
        setDataDescarga([]);
        setLoading(false);
        setError(false);
      }
    };
    fetchdataDescarga();
  }, []);

  useEffect(() => {
    if (modalShowTaxonomia) {
      const fetchdataTaxonomia = async () => {
        try {
          const result = await fetch(
            process.env.REACT_APP_API_URL + "maestro/taxonomia/",
            {
              method: "POST",
              body: JSON.stringify(selectedDetalle),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          
          if (result.status >= 200 && result.status < 300) {
            const param = await result.json();
            setLoadingDetalle(false);
            setDetalleTaxonomia(param.main_data);
            setDetalleCWE(param.cwe_data);
            setErrorModal(null);
          } else {
            setDetalleTaxonomia([]);
            setDetalleCWE([]);
            //setErrorModal(`Error ${result.status}: ${result.statusText}`);
            setErrorModal(`No hay CVEs asociados`);
          }
        } catch (error) {
          setDetalleTaxonomia([]);
          setDetalleCWE([]);
          setErrorModal('Error al cargar los datos: ' + error.message);
        }
      };
      fetchdataTaxonomia();
    }
  }, [modalShowTaxonomia]);

  const handleClickSelect = (ids) => {
    setSelected(ids);
    setModalShow(true);
  };

  const handleRowClick = (evt, row) => {
    const selectedRows = [...data];
    const index = selectedRows.findIndex(
      (r) => r.tableData.id === row.tableData.id
    );
    selectedRows[index].tableData.checked =
      !selectedRows[index].tableData.checked;
    setSelectedRow(row);
    setData(selectedRows);

    const newSelectedRowsCount = selectedRows.filter(
      (r) => r.tableData.checked
    ).length;
    setSelectedRowsCount(newSelectedRowsCount);
  };

  const handleViewDetail = (selectedRows) => {
    selectedRows = selectedRows.map((item) => {
      return {
        idvulnerabilidad_tecnica: item.idvulnerabilidad_tecnica,
        vt_CVE: item.vt_CVE,
      };
    });
    setSelectedDetalle(selectedRows);
    setModalShowTaxonomia(true);
  };

  const DeleteVul = (valueCierre, ids) => {
    setModalShow(false);
    setLoadingCarge(true);

    if (ids !== null) {
      ids.map((id) => {
        dataput.push({
          estadovulnerabilidad: id.estadovulnerabilidad,
          fechaedicion: today.toISOString(),
          idevaluacion: id.idevaluacion,
          idvulnerabilidad_tecnica: id.idvulnerabilidad_tecnica,
          idusuariomodificacion: localStorage.getItem("idusuario"),
          motivocierre: valueCierre,
        });
      });
    }
    var data = JSON.stringify(dataput);
    fetch(
      process.env.REACT_APP_API_URL +
        "vulnerabilidad/tecnica/cierre" +
        "/" +
        localStorage.getItem("idevaluacion") +
        "/",
      {
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      }
    ).then((data) =>
      data.json().then((response) => {
        if (data.status >= 200 && data.status < 300) {
          setLoadingCarge(false);
          setEstadoPost(2);
          const fetchdatasuccess = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "vulnerabilidad/tecnica/cierre" +
                "/" +
                localStorage.getItem("idevaluacion") +
                "/",

              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            let param = await result.json();
            if (result.status >= 200 && result.status < 300) {
              setLoadingCarge(false);
              setData(param);
              setLoading(false);
              setError(true);
            } else {
              setData([]);
              setLoadingCarge(false);
              setLoading(false);
              setError(true);
            }
          };
          const fetchdataDescargasuccess = async () => {
            const result = await fetch(
              process.env.REACT_APP_API_URL +
                "evaluacion/vultec/" +
                localStorage.getItem("idevaluacion") +
                "/",
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                },
              }
            );
            let paramdescarga = await result.json();
            if (result.status >= 200 && result.status < 300) {
              setDataDescarga(paramdescarga);
              setLoading(false);
              setError(true);
            } else {
              setDataDescarga([]);
              setLoading(false);
              setError(false);
            }
          };
          fetchdataDescargasuccess();
          fetchdatasuccess();
        } else if (data.status >= 500) {
          setEstadoPost(5);
          setLoadingCarge(false);
        } else if (data.status >= 400 && data.status < 500) {
          setEstadoPost(4);
          setLoadingCarge(false);
        }
      })
    );
    dataput = [];
  };

  const handleChange = () => {
    setFilter(!filter);
  };
  const handleOnExport = () => {
    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(dataDescarga);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Descarga");
    XLSX.writeFile(newBook, "Descarga Vulnerabilidades Técnicas.xlsx");
  };
  return (
    <div className={classes.root}>
      <AlertDismissibleExample alerta={estadoPOST} />
      <ModalSelect show={modalShow} onHide={() => setModalShow(false)} />
      <ModalDetalleTaxonomia
        show={modalShowTaxonomia}
        onHide={() => setModalShowTaxonomia(false)}
        detalleTaxonomia={detalleTaxonomia}
        detalleCWE={detalleCWE}
        error={errorModal}
      />
      <Row>
        <Col md={8}>
          <h2 className="titulo">Cierre de Vulnerabilidades Técnicas</h2>
        </Col>
      </Row>
      {!loadingCarge ? (
        <>
          <Col
            style={{ textAlign: "end", marginBottom: "5px", padding: "0px" }}
          >
            {error ? (
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button className="botonIngreso" onClick={handleOnExport}>
                    Descargar Vulnerabilidades Técnicas &nbsp;
                    <IoMdDownload />
                  </Button>
                )}
              </>
            ) : (
              ""
            )}
          </Col>
          <Paper variant="outlined" className={classes.paper}>
            <MuiThemeProvider theme={theme}>
              <MaterialTable
                columns={columns}
                data={data}
                icons={tableIcons}
                options={{
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      selectedRow &&
                      selectedRow.tableData.id === rowData.tableData.id
                        ? "#fff8d3"
                        : "#FFF",
                  }),
                  showTitle: false,
                  filtering: filter,
                  searchFieldAlignment: "left",
                  searchFieldStyle: {},
                  headerStyle: {
                    backgroundColor: "#2c2a29",
                    color: "#ffffff",
                    position: "sticky",
                    top: 0,
                    fontFamily: "CIBFont Sans Regular",
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    flex: "1 1 100%",
                  },
                  maxBodyHeight: "650px",
                  selection: true,
                  disableMultipleSelection: true,
                  pageSizeOptions: [10, 20, 30],
                  showSelectAllCheckbox: true,
                  showTextRowsSelected: false,
                  pageSize: 10,
                }}
                onRowClick={handleRowClick}
                components={{
                  Pagination: (props) => (
                    <div className={classes.pagination}>
                      <FormControlLabel
                        className={classes.switch}
                        control={
                          <Checkbox
                            color="primary"
                            checked={filter}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Mostrar filtros por columnas
                          </Typography>
                        }
                      />
                      <TablePagination
                        {...props}
                        className={classes.pagination}
                      />
                    </div>
                  ),
                }}
                actions={[
                  {
                    icon: () => (
                      <Button className="botonPositivo">
                        Marcar Como Cerrada
                      </Button>
                    ),
                    onClick: (evt, selectedRow) => {
                      handleClickSelect(selectedRow);
                    },
                  },
                  selectedRowsCount === 1 && {
                    icon: () => (
                      <Button className="botonNegativo">
                        Ver detalle Taxonomía
                      </Button>
                    ),
                    onClick: (evt, selectedRow) => {
                      handleViewDetail(selectedRow);
                    },
                    
                  },
                ].filter(Boolean)}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    firstTooltip: "Primera página",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Última página",
                  },
                }}
              />
            </MuiThemeProvider>
          </Paper>
        </>
      ) : (
        <>
          <Loader
            type="Puff"
            color="#FFBF00"
            style={{ textAlign: "center", position: "static" }}
          />
          <Row className="mb-3">
            <Col className="text-center" sm={12} xs={12}>
              <label className="form-label">
                Marcando como cerrada las vulnerabilidades, por favor espere.
              </label>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Eliminar_vul_tec;
