import React, { useState, useRef } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Collapse } from "@mui/material";
import { Link } from "react-router-dom";

function SidebarItem({
  url_opcion,
  opcion,
  items,
  depthStep = 10,
  depth = 0,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (e) => {
    const target = e.target;
    const value = target.in;
    setIsOpen(!isOpen);
  };
  if (depth === 0) {
    return (
      <>
        <ListItem button dense {...rest} onClick={toggle}>
          <ListItemText style={{ paddingLeft: depth * depthStep }}>
            <span>
              <Link
                className="label"
                to={url_opcion}
                style={{ color: "#FFD700" }}
              >
                + {opcion}
              </Link>
            </span>
          </ListItemText>
        </ListItem>

        {Array.isArray(items) ? (
          <Collapse in={isOpen}>
            <List disablePadding dense>
              {items.map((subItem) => (
                <SidebarItem
                  key={subItem.idopcion}
                  depth={depth + 1}
                  depthStep={depthStep}
                  {...subItem}
                />
              ))}
            </List>
          </Collapse>
        ) : null}
      </>
    );
  } else {
    return (
      <>
        <ListItem button dense {...rest} onClick={toggle}>
          <ListItemText style={{ paddingLeft: depth * depthStep }}>
            <span>
              <Link
                to={url_opcion}
                className="texto"
                style={{ color: "#ffffff" }}
              >
                {opcion}
              </Link>
            </span>
          </ListItemText>
        </ListItem>

        {Array.isArray(items) ? (
          <Collapse in={isOpen}>
            <List disablePadding dense>
              {items.map((subItem) => (
                <SidebarItem
                  key={subItem.idopcion}
                  depth={depth + 1}
                  depthStep={depthStep}
                  {...subItem}
                />
              ))}
            </List>
          </Collapse>
        ) : null}
      </>
    );
  }
}

function Sidebar({ items, depthStep, depth, orden_menu, eval_type }) {
  orden_menu = parseInt(orden_menu, 10);
  if (orden_menu !== 0) {
    
    items = items.filter((item) => item.orden_menu != 0);
    //items = items.map((item) => item.items.filter(i => i.orden_menu !== "0.000000"))
  } else {
    items = items.filter((item) => item.orden_menu == 0);
  }
  items = items.filter((item)=> item.orden !=0);

// eval_type = 0 es por que es de las evaluaciones viejas
  if (eval_type == 0){
    items = items.filter((item) => item.url_opcion !== "/nuevo-cuestionario-de-riesgos");
    items = items.filter((item) => item.url_opcion !== "/NuevosRiesgos");
    items = items.filter((item) => item.url_opcion !== "/new-controles-evaluacion");
    items = items.filter((item) => item.url_opcion !== "/findings");
  }


// eval_type = 1 es por que es de las evaluaciones nuevas
  if (eval_type == 1){
    items = items.filter((item) => item.url_opcion !== "/evaluacion-cuestionario-de-riesgos");
    items = items.filter((item) => item.url_opcion !== "/evaluacion-riesgo");
    items = items.filter((item) => item.url_opcion !== "/controles-evaluacion");
    items = items.filter((item) => item.url_opcion !== "/frecuencia-riesgos");
    items = items.filter((item) => item.url_opcion !== "/evaluacion-vulnerabilidades-tecnicas");
    items = items.filter((item) => item.url_opcion !== "/evaluacion-eliminar-vulnerabilidades-tecnicas");
    items = items.filter((item) => item.url_opcion !== "/controles-evaluacion");  
    items = items.filter((item) => item.url_opcion !== "/planes-de-accion");
    items = items.filter((item) => item.url_opcion !== "/evaluacion-vulnerabilidades-no-tecnicas");
    //items = items.filter((item) => item.url_opcion !== "/descripcion-de-componentes");
    items = items.filter((item) => item.url_opcion !== "/decisiones");
    items = items.filter((item) => item.url_opcion !== "/resumen-efectividad");
    items = items.filter((item) => item.url_opcion !== "/escenarios-mitigacion");
    //items = items.filter((item) => item.url_opcion !== "/analisis-brechas");

  }

  return (
    <div className="sidebar">
      <List disablePadding dense>
        {items.map((sidebarItem, index) => (
          <>
            <SidebarItem
              key={`${sidebarItem.idopcion}${index}`}
              depthStep={depthStep}
              depth={depth}
              {...sidebarItem}
            />
          </>
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
