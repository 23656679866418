import React, { useEffect } from "react";
import * as echarts from "echarts/core";
import { DatasetComponent, GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([DatasetComponent, GridComponent, BarChart, CanvasRenderer]);

const colorMap = {
  cisa_kev: "#ff3341",
  metasploit: "#ffb74d",
  exploitdb: "#ba68c8",
  nuclei: "#64b5f6",
};

const getBarColor = (bases) => {
  if (!bases || bases.length === 0) return "#90a4ae";
  if (bases.length === 1) return colorMap[bases[0]];
  return {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: bases.map((base, index) => ({
      offset: index / (bases.length - 1),
      color: colorMap[base], // Crear gradiente basado en múltiples características
    })),
  };
};

const FindingsComponent = ({ data }) => {
  useEffect(() => {
    const chartDom = document.getElementById("main");
    const myChart = echarts.init(chartDom);

    const transformedData = data.map((item) => ({
      amount: item.amount,
      product: item.product,
      bases: item.bases,
      color: getBarColor(item.bases),
    }));

    const option = {
      dataset: {
        source: transformedData.map((item) => [
          item.amount,
          item.product,
          item.color,
        ]),
      },
      grid: { containLabel: true },
      xAxis: {
        name: "Porcentaje",
        axisLabel: {
          formatter: (value) => `${value * 100}%`,
        },
      },
      yAxis: {
        type: "category",
        name: "Vulnerabilidad",
        nameLocation: "start",
        inverse: true,
      },
      series: [
        {
          stack: "total",
          type: "bar",
          label: {
            show: true,
            position: "right",
            formatter: (params) => `${params.value[1]}`,
          },
          itemStyle: {
            color: (params) => params.value[2],
          },
          encode: {
            x: 0,
            y: 1,
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [data]);

  return (
    <div>
      <div id="main" style={{ width: "1200px", height: "800px" }}></div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#e57373",
                marginRight: "10px",
              }}
            ></div>
            CISA KEV
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#ffb74d",
                marginRight: "10px",
              }}
            ></div>
            Metasploit
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#ba68c8",
                marginRight: "10px",
              }}
            ></div>
            ExploitDB
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#64b5f6",
                marginRight: "10px",
              }}
            ></div>
            Nuclei
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: "#90a4ae",
                marginRight: "10px",
              }}
            ></div>
            No aparece en ninguna base
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindingsComponent;
