import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import FindingsComponent from "./FindingsComponent.js";
import { Row, Col } from "react-bootstrap";
import MaterialTable from "material-table";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import { TablePagination } from "@material-ui/core";
import { Warning } from "@mui/icons-material"; 
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import AADService from "../../funcionesAuth.js";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const ResumenFindings = () => {
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [DataResumen, setDataResumen] = useState([]);
  const [DataTabla, setDataTabla] = useState([]);
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));
  const classes = useStyles();
  const columns = [
    { field: "cve", title: "CVE" },
    { field: "cantidad", title: "Cantidad" },
    { field: "publicacion", title: "Publicación" },
    { field: "cwes", title: "CWEs asociados" },
    { field: "cvss", title: "CVSS 3.1 BaseScore" },
    {
      title: "CISA KEV",
      field: "cisa_kev",
      render: rowData => rowData.cisa_kev ? <Warning style={{ color: "red" }} /> : "No"
    },
    {
      title: "Metasploit",
      field: "metasploit",
      render: rowData => rowData.metasploit ? <Warning style={{ color: "red" }} /> : "No"
    },
    {
      title: "Nuclei",
      field: "nuclei",
      render: rowData => rowData.nuclei ? <Warning style={{ color: "red" }} /> : "No"
    },
    {
      title: "ExploitDB",
      field: "exploitdb",
      render: rowData => rowData.exploitdb ? <Warning style={{ color: "red" }} /> : "No"
    }
  ];
  useEffect(() => {
    const obtenerResumen = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "maestro/findings/resumenVuln/" +
          localStorage.getItem("idevaluacion") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let datResumen = await result.json();
        setDataResumen(datResumen.resumen);
        setDataTabla(datResumen.data);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };
    obtenerResumen();
  }, []);
  return (
    <div>
      {DataResumen.length > 0 ? (
        <>
          <Row style={{ marginTop: "50px", justifyContent: "center" }}>
            <Col md={10} style={{ textAlign: "center" }}>
              <h2 className="subtitulo">
                Top 5 vulnerabilidades con mayor probabilidad de explotación
              </h2>
            </Col>
          </Row>
          <Row className="grafo">
            <FindingsComponent data={DataResumen} />
          </Row>
          <Row style={{ marginTop: "50px", justifyContent: "center" }}>
            <Col md={10} style={{ textAlign: "center" }}>
              <h2 className="subtitulo">Vulnerabilidades encontradas</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ zIndex: 100 }}>
                <Paper variant="outlined" className={classes.paper}>
                  <MaterialTable
                    columns={columns}
                    data={DataTabla}
                    icons={tableIcons}
                    options={{
                      showTitle: false,
                      searchFieldAlignment: "left",
                      searchFieldStyle: { height: 50 },
                      //zIndex: -1000,
                      headerStyle: {
                        //zIndex: 1,
                        backgroundColor: "#2c2a29",
                        color: "#ffffff",
                        position: "relative",
                        top: 0,
                        fontFamily: "CIBFont Sans Regular",
                        fontWeight: 400,
                        fontSize: "1.2rem",
                        lineHeight: 1.6,
                        letterSpacing: "0.0075em",
                        flex: "1 1 100%",
                      },
                      maxBodyHeight: "650px",
                      selection: false,
                      disableMultipleSelection: true,
                      pageSizeOptions: [10, 20, 30],
                      showSelectAllCheckbox: false,
                      showTextRowsSelected: false,
                      pageSize: 10,
                    }}
                    components={{
                      Pagination: (props) => (
                        <div className={classes.pagination}>
                          <TablePagination
                            {...props}
                            className={classes.pagination}
                          />
                        </div>
                      ),
                    }}
                    localization={{
                      toolbar: {
                        searchPlaceholder: "Buscar",
                      },
                      body: {
                        emptyDataSourceMessage: "No se encontraron registros",
                      },
                      pagination: {
                        labelRowsSelect: "Filas",
                        firstTooltip: "Primera página",
                        previousTooltip: "Anterior",
                        nextTooltip: "Siguiente",
                        lastTooltip: "Última página",
                      },
                    }}
                  />
                </Paper>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="grafo">
          <Col md={2}>
            <t> </t>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ResumenFindings;
