import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function M_Implementadores() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  var columns = [
    
    {
      field: "idimplementador",
      title: "Id Control",
    },
    {
      field: "nombre",
      title: "Nombre Control",
    },
    {
      field: "descripcion",
      title: "Descripción", 
    },
    {
      field: "nombre_compania",
      title: "Compañía", 
    },
    {
      field: "nivel_automatizacion",
      title: "Nivel Automatizacion",
    },
    {
      field: "tipo_control",
      title: "Naturaleza",
    },
  ];

  //Modifica temas predeterminados por Material-Table
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });
  const [loading, setLoading] = React.useState(false);  
  const classes = useStyles();
  const check = new CheckRole("/maestro-implementador");
  const [implementadores, setImplementadores] = useState([]);
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filter, setFilter] = useState(false);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [companiaDatOption, setCompaniaDatOption] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filterCompany, setFilterCompany] = useState(false);
  const [DataDespliegue,setDataDespliegue] = React.useState([]);
  const [selectedDespliegue, setSelectedDespliegue] = useState(null);
  const [filterDespliegue, setFilterDespliegue] = useState(false);
  const [dataDominio, setDataDominio] = useState([]);
  const [selectedDominio, setSelectedDominio] = useState(null);
  const [filterDominio, setFilterDominio] = useState(false);
  const [dataTipoUsuario, setDataTipoUsuario] = useState([]);
  const [selectedTipoUsuario, setSelectedTipoUsuario] = useState(null);
  const [filterTipoUsuario, setFilterTipoUsuario] = useState(false);
  const [niveles, setNiveles] = useState([]);
  const [servicio, setServicio] = useState([]);
  const [selectedServicio, setSelectedServicio] = useState(null);
  const [filterServicio, setFilterServicio] = useState(false);
  const [capacidades, setCapacidades] = useState([]);
  const [selectedCapacidad, setSelectedCapacidad] = useState(null);
  const [filterCapacidad, setFilterCapacidad] = useState(false);

  const fetchdata = async (rol) => {
    setLoading(true);
    const result = await fetch(
      process.env.REACT_APP_API_URL + "maestro/implementadores/0/"
      + localStorage.getItem("idcompania") + "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let data = await result.json();
      setImplementadores(data);
      setLoading(false);
    } else if (result.status >= 400 && result.status <= 500) {
      setImplementadores([]);
      setLoading(false);
    }
    
  };

  const myRef = React.useRef(fetchdata);


  useEffect(() => {

    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        myRef.current(usuario[0].idrol);
      }
    };
    const obtenerCompañias = async () => {
      const result = await fetch(
          process.env.REACT_APP_API_URL + "general/compania/",
          {
              method: "GET",
              headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + token,
              },
          }
      );
      let datCompania = await result.json();
      setCompaniaDatOption(datCompania);
  };
    const obtenerTipoDespliegue = async () => {
      const result = await fetch(
          process.env.REACT_APP_API_URL +
          "general/parametrosGeneralesxGrupoxParametro/Componentes/Tipo_Despliegue/" ,
          {
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
          },
          }
      );
  let datTipoDespliegue = await result.json();
  setDataDespliegue(datTipoDespliegue);
  };
  const obtenerDominio = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "general/parametrosGeneralesxGrupoxParametro/Implementadores/Dominio/" ,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    let datDominio = await result.json();

    setDataDominio(datDominio);
  };
  const obtenerTipoUsuario = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
        "general/parametrosGeneralesxGrupoxParametro/Implementadores/Tipo_Usuario/" ,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    let datTipoUsuario = await result.json();

    setDataTipoUsuario(datTipoUsuario);
  };
  const obtenerNiveles = async () => {
    const result = await fetch(process.env.REACT_APP_API_URL + "general/parametrosGeneralesxGrupoxParametro/Implementadores/Niveles/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
        },
    });
    let datNiveles = await result.json();
    setNiveles(datNiveles);
    };
    obtenerCompañias();
    obtenerTipoDespliegue();
    fetchUsuario();
    obtenerDominio();
    obtenerTipoUsuario();
    obtenerNiveles();
  }, []);

  const mapcompnusada = companiaDatOption.map(option => ({
    value: option.idcompania,
    label: option.compania,
    
  }));

  const maptipodespliegue = DataDespliegue.map(option => ({
    value: option.idparametrosgenerales,
    label: option.vlrmetrica
}));

const mapDominio = dataDominio.map(option => ({
  value: option.idparametrosgenerales,
  label: option.vlrmetrica
}));

const mapTipoUsuario = dataTipoUsuario.map(option => ({
  value: option.idparametrosgenerales,
  label: option.vlrmetrica
}));


const mapServicio = niveles.filter(nivel => parseInt(parseFloat(nivel.disp_numerico1)) === 0)
  .map(nivel => ({
    value: nivel.idparametrosgenerales,
    label: nivel.vlrmetrica
  }));


  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const handleChange = () => {
    setFilter(!filter);
  };
  function add_eval() {
    localStorage.setItem("idimplementador", 0);
  }
  const editar = () => {
    localStorage.setItem("idimplementador", selected);
  };


  const handleCompanyChange = (selectedOption) => {
    setFilterCompany(true);
  };

  const handleTipoDespliegueChange = (selectedOption) => {
    setFilterDespliegue(true);
  };

  const handleDominioChange = (selectedOption) => {
    setFilterDominio(true);
  };

  const handleTipoUsuarioChange = (selectedOption) => {
    setFilterTipoUsuario(true);
  };

  const handleServicioChange = (selectedOption) => {
    setFilterServicio(true);

    let idnivel = selectedOption.value;

    const mapCapacidad = niveles.filter(nivel => parseInt(parseFloat(nivel.disp_numerico1)) === idnivel)
    .map(nivel => ({
      value: nivel.idparametrosgenerales,
      label: nivel.vlrmetrica
    }));

    setCapacidades(mapCapacidad);
  };

  const handleChangeCapacidad = (selectedOption) => {
    setFilterCapacidad(true);
  };

  const filteredImplementadores = implementadores.filter(implementador => {
    
    let cumpleCompany = true; 
    let cumpleDespliegue = true; 
    let cumpleDominio = true;
    let cumpleTipoUsuario = true;
    let cumpleServicio = true;
    let cumpleCapacidad = true;
  
    if (filterCompany) {
      cumpleCompany = implementador.idcompania === selectedCompany.value;
    }
    
    if (filterDespliegue) {
      if (implementador.tipo_despliegue) {
        cumpleDespliegue = implementador.tipo_despliegue.split(',').includes(selectedDespliegue.label);
      } else {
        cumpleDespliegue = false; 
      }
    }

    if (filterDominio) {
      if (implementador.dominio) {
        cumpleDominio = implementador.dominio === selectedDominio.label;
      } else {
        cumpleDominio = false;
      }
    }

    if (filterTipoUsuario) {
      if (implementador.tipo_usuario) {
        cumpleTipoUsuario = implementador.tipo_usuario === selectedTipoUsuario.label;
      } else {
        cumpleTipoUsuario = false;
      }
    }

    if (filterServicio) {
      if (implementador.niveles) {
        let niveles = JSON.parse(implementador.niveles);
        let nivelSeleccionado = niveles.find(nivel => nivel.title === selectedServicio.label);
        if(nivelSeleccionado) {
          cumpleServicio = nivelSeleccionado.subSections.some(subSection => 
              subSection.checkboxes.some(checkbox => checkbox.value === true)
          );
        }
        else {
          cumpleServicio = false;
        }
    }
    else {
      cumpleServicio = false;
    }
  }

  if (filterCapacidad) {
    if (implementador.niveles) {
      let niveles = JSON.parse(implementador.niveles);
      let nivelSeleccionado = niveles.find(nivel => nivel.title === selectedServicio.label);
      if(nivelSeleccionado) {
        let subSectionSeleccionada = nivelSeleccionado.subSections.find(subSection => subSection.subtitle === selectedCapacidad.label);
        if(subSectionSeleccionada) {
          cumpleCapacidad = subSectionSeleccionada.checkboxes.some(checkbox => checkbox.value === true);
        }
        else {
          cumpleCapacidad = false;
        }
      }
      else {
        cumpleCapacidad = false;
      }
    }
    else {
      cumpleCapacidad = false;
    }
  }
    return cumpleCompany && cumpleDespliegue && cumpleDominio && cumpleTipoUsuario && cumpleServicio && cumpleCapacidad;
  });

  return (
    <div>
      <Row>
        <Col>
          <h2 className='titulo'>Controles</h2>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Button 
            className='botonGeneral'
            style={{ marginBottom: "20px" }}
            onClick={(e) => {
              setFilterCompany(false);
              setFilterDespliegue(false);
              setFilterDominio(false);
              setFilterTipoUsuario(false);
              setFilterServicio(false);
              setFilterCapacidad(false);
              setSelectedCompany(null);
              setSelectedDespliegue(null);
              setSelectedDominio(null);
              setSelectedTipoUsuario(null);
              setSelectedServicio(null);
              setSelectedCapacidad(null);
              myRef.current(0);
            }
          }
            >Limpiar</Button>
        </Col>
      </Row> */}
      {/* <div style={{ zIndex: 10000, position: "relative" }}>
        <Row>
        <Col>
            <Select 
              onChange={(e) => {
                setSelectedCompany(e)
                handleCompanyChange(e)
              }}
              value={selectedCompany} 
              placeholder="Compañía"
              options={mapcompnusada}
            />
        </Col>
        <Col>
            <Select 
              onChange={(e) => {
                setSelectedDespliegue(e)
                handleTipoDespliegueChange(e)
              }}
              value={selectedDespliegue} 
              placeholder="Implementación"
              options={maptipodespliegue}
            />
        </Col>
        <Col>
            <Select 
              onChange={(e) => {
                setSelectedDominio(e)
                handleDominioChange(e)
              }}
              value={selectedDominio} 
              placeholder="Dominio"
              options={mapDominio}
            />
        </Col>
        <Col>
            <Select 
              onChange={(e) => {
                setSelectedTipoUsuario(e)
                handleTipoUsuarioChange(e)
              }}
              value={selectedTipoUsuario} 
              placeholder="Tipo Usuario"
              options={mapTipoUsuario}
            />
        </Col>
        <Col>
            <Select 
              onChange={(e) => {
                setSelectedServicio(e)
                handleServicioChange(e)
              }}
              value={selectedServicio} 
              placeholder="Servicio"
              options={mapServicio}
            />
        </Col>
        <Col>
            <Select 
              onChange={(e) => {
                setSelectedCapacidad(e)
                handleChangeCapacidad(e)
              }}
              value={selectedCapacidad} 
              placeholder="Capacidad"
              options={capacidades}
            />
        </Col>
        </Row>
      </div> */}
      <div style={{ zIndex: 100 }}>
        <Paper variant='outlined' className={classes.paper}>
          <MaterialTable
            columns={columns}
            data={filteredImplementadores}
            icons={tableIcons}
            options={{
              rowStyle: (rowData) => ({
                backgroundColor:
                  ButtonEdit === true && selectedRow === rowData.tableData.id
                    ? "#ffdb94"
                    : "#FFF",
              }),
              showTitle: false,
              filtering: filter,
              searchFieldAlignment: "left",
              searchFieldStyle: { height: 50 },
              //zIndex: -1000,
              headerStyle: {
                //zIndex: 1,
                backgroundColor: "#2c2a29",
                color: "#ffffff",
                position: "relative",
                top: 0,
                fontFamily: "CIBFont Sans Regular",
                fontWeight: 400,
                fontSize: "1.2rem",
                lineHeight: 1.6,
                letterSpacing: "0.0075em",
                flex: "1 1 100%",
              },
              maxBodyHeight: "650px",
              selection: false,
              disableMultipleSelection: true,
              pageSizeOptions: [10, 20, 30],
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 10,
            }}
            components={{
              Pagination: (props) => (
                <div className={classes.pagination}>
                  <FormControlLabel
                    className={classes.switch}
                    control={
                      <Checkbox
                        checked={filter}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <Typography variant='body2'>
                        Mostrar filtros por columnas
                      </Typography>
                    }
                  />
                  <TablePagination {...props} className={classes.pagination} />
                </div>
              ),
            }}
            onRowClick={(evt, selectedRow) => {
              handleClick(selectedRow.idimplementador);
              return setSelectedRow(selectedRow.tableData.id);
            }}
            actions={[
              loading
                ? { 
                    icon: () => <CircularProgress />,
                    position: "toolbar",
                    isFreeAction: true,
                  }
                :
              // {
              //   icon: () => (
              //     <Link to='NuevoMImplementador'>
              //       <Button className='botonPositivo'>Nuevo</Button>
              //     </Link>
              //   ),
              //   onClick: add_eval(),
              //   position: "toolbar",
              //   isFreeAction: true,
              // },
              {
                icon: () => (
                  <Link to='EditarMimplementador'>
                    <Button className='botonGeneral'>Editar</Button>
                  </Link>
                ),
                onClick: editar(),
                position: "toolbar",
                isFreeAction: true,
                hidden: ButtonEdit === false,
              },
            ]}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No se encontraron registros",
              },
              pagination: {
                labelRowsSelect: "Filas",
                firstTooltip: "Primera página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Última página",
              },
            }}
          />
      </Paper>
      </div>
    </div>
  );
}
export default M_Implementadores;
