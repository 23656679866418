import React, { useEffect, useRef, useState } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import TableContainer from "@material-ui/core/TableContainer";
import { Table, TableHead, TableBody, TableRow, TableCell, Paper, Tooltip, List, ListItem, ListItemText} from "@material-ui/core";
import { HelpOutline } from '@material-ui/icons';
import {Row, Col, Button, Alert} from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import AADService from "../../funcionesAuth.js";

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Para el análisis de brechas no hay controles no implementados para mostrar o no son adherentes a ninguna amenaza
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}


  const useStyles = makeStyles({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },   
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "White",
    },
    
  });

function AnalisisBrechas() {
    const [dataRiesgo, setDataRiesgos] = useState("");
    const [dataBrechas, setDataBrechas] = useState([]);
    const [flatTable, setFlatTable] = useState(false);
    const [ValueIdRiesgo, setValueIdRiesgo] = useState("");
    const [dataResumenRiesgo, setDataResumenRiesgo] = useState([]);
    const [dataHistoricoEfectividad, setDataHistoricoEfectividad] = useState([]);
    const [columnasCapas, setcolumnasCapas] = useState([]);
    const [columnas, setcolumnas] = useState([]);
    const [amenazas, setamenazas] = useState([]);
    const FirstChangeRiesgoRef = useRef(false);
    const classes = useStyles();
    const [estadoGET, setEstadoGET] = React.useState(0);
    const AzureADService = new AADService();
    const token = AzureADService.getToken();

    useEffect(() => {
      const fetchRiesgo = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/newriesgos/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let dataFetchRiesgo = await result.json();
        const dataRiesgomap = dataFetchRiesgo.map(item => ({
          value: item.idriesgo,
          label: "ID Riesgo: " +item.idriesgo + " " +item.evento,
        }));
        setDataRiesgos(dataRiesgomap);
      }
      fetchRiesgo();

      const fetchBrechas = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "evaluacion/newbrechas/" +
            localStorage.getItem("idevaluacion") +
            "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token, 
            },
          }
        );
        if (result.status === 200) {
          let dataFetchBrechas = await result.json();
          const dataBrechasmap = dataFetchBrechas.map(item => ({
            idimplementador: item.idimplementador,
            nombre: item.nombre,
            efectividad: item.efectividad,
            nombres_amenazas: item.nombres_amenazas.map(nombre => ({
              nombre: nombre
            })),
            capa_control: item.capa_control.split(","),
            suma_pesos: item.suma_pesos,
            puntaje: item.puntaje,
          }));
          setDataBrechas(dataBrechasmap);
        }
        else if (result.status === 404) {
          setEstadoGET(6);
          return null;
        }
        else {
          setEstadoGET(5);
          return null;
        }
      }
      fetchBrechas();
    },[]);

    const renderCapaControlTooltip = () => (
      <Tooltip title={
        <React.Fragment>
          <List>
            <ListItem>
              <ListItemText primary="Peso de cada capa:" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Monitoreo y Respuesta: 10.9%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad perimetral: 21.4%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad de la red: 18.9%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad de Endpoints: 18.3%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad de aplicaciones: 18.8%" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seguridad de datos: 11.7%" />
            </ListItem>
          </List>
        </React.Fragment>
      }>
        <HelpOutline />
      </Tooltip>
    );

    const renderPuntajeTooltip = () => (
      <Tooltip 
        title={
          <React.Fragment>
          <List>
            <ListItem>
              <ListItemText primary="Puntaje calculado en base a las amenazas que mitiga, la suma de pesos de capas y el diseño del control." />
            </ListItem>
          </List>
        </React.Fragment>
      }>
        <HelpOutline />
      </Tooltip>
    );

    return (
      <>
        <AlertDismissibleExample alerta={estadoGET} />
        <div className={classes.root}>
            <div style={{ marginBottom: '30px', marginTop:'30' }}>
              <h1 className='titulo' style={{ marginBottom: '30px' }}>Resumen de efectividad de controles</h1>
              <Row>
                <Col sm={2} xs={12} style={{ marginRight: '10px' }} >
                    <label  className="label forn-label">Análisis de brechas</label>
                  </Col>
              </Row> 
              <Row>
                <Col sm={12} xs={12} style={{ marginRight: '10px' }} >
                  <p>Ranking de los controles no implementados que más le aportarían a mejorar la efectividad</p>
                </Col>
              </Row> 
              <hr></hr>
              <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby='tableTitle'
                    size={"medium"}
                    aria-label='enhanced table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.head}>ID Control</TableCell>
                        <TableCell className={classes.head}>Nombre</TableCell>
                        <TableCell className={classes.head}>Amenazas que mitiga</TableCell>
                        <TableCell className={classes.head}>
                          Capas que protege 
                          {renderCapaControlTooltip()}
                        </TableCell>
                        <TableCell className={classes.head}>Suma de Pesos</TableCell>
                        <TableCell className={classes.head}>Diseño control</TableCell>
                        <TableCell className={classes.head}>
                          Puntaje 
                          {renderPuntajeTooltip()}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataBrechas.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.idimplementador}</TableCell>
                          <TableCell>{row.nombre}</TableCell>
                          <TableCell>
                            <ul>
                              {row.nombres_amenazas.map((nombre, i) => (
                                <li key={i}>{nombre.nombre}</li>
                              ))}
                            </ul>
                          </TableCell>
                          <TableCell>
                            <ul>
                              {row.capa_control.map((capa, i) => (
                                <li key={i}>{capa}</li>
                              ))}
                            </ul>
                          </TableCell>
                          <TableCell>{row.suma_pesos}</TableCell>
                          <TableCell>{row.efectividad}</TableCell>
                          <TableCell>{parseFloat(row.puntaje).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>   
            </div>
        </div>  
      </>     
    );
}           
export default AnalisisBrechas;