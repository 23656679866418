import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AADService from "../funcionesAuth.js";
import * as echarts from "echarts";
import CircularProgress from "@mui/material/CircularProgress";
const PieChartComponent = ({ data }) => {
  const [selectedOption, setSelectedOption] = useState("total");


  useEffect(() => {
    const chartDom = document.getElementById("chart");
    const myChart = echarts.init(chartDom);

    // Definir los datos para cada opción
    const dataOptions = data;

    // Configuración del gráfico
    const option = {
      legend: {
        top: "top",
      },
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "pie",
          radius: "50%",
          center: ["50%", "50%"],
          data: dataOptions[selectedOption], // Datos dinámicos basados en la selección
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          label: {
            formatter: "{b}: {c} ({d}%)",
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [selectedOption]); // El gráfico se actualiza cuando cambia la opción seleccionada

  return (
    <div>
      <select
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        style={{ marginBottom: "20px" }}
      >
        <option value="total">Total</option>
        <option value="principalesmodulos">Principales-Modulos</option>
        <option value="esquemas">Esquemas</option>
        <option value="nubes">Nubes</option>
        <option value="bia">BIA</option>
        <option value="sox">SOX</option>
      </select>

      <div id="chart" style={{ width: "600px", height: "400px" }}></div>
    </div>
  );
};

const ResumenComponentes = () => {
  const [DataResumen, setDataResumen] = useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    const obtenerResumen = async () => {
      setLoading(true);
      const result = await fetch(
        process.env.REACT_APP_API_URL +
        "activoInformacion/0/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let datResumen = await result.json();
        setDataResumen(datResumen);
        setLoading(false);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };
    obtenerResumen();

  }, []);
  return (
    <div>
      <br />
      <br />
      {loading ? (
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </div>
            ) : (
      <Row>
        <Col sm={6} xs={12}>
          <TableContainer>
            <Table stickyHeader size={"medium"} maxHeightBody="55vh">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Clase
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Principales
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Modulos
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {DataResumen.initialData && DataResumen.initialData.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ backgroundColor: "#F2F2F2" }}>
                        {row.clase}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#F2F2F2" }}>
                        {row.principales}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#F2F2F2" }}>
                        {row.modulos}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#F2F2F2" }}>
                        {row.total}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
        <Col sm={6} xs={12}>
          {DataResumen.dataOptions && (
            <PieChartComponent data={DataResumen.dataOptions} />
          )}
        </Col>
      </Row>
            )}
    </div>
  );
};

export default ResumenComponentes;
