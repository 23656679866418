import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Input } from "reactstrap";
import styled from "@mui/material/styles/styled";
import { Rowing } from "@material-ui/icons";
import * as echarts from "echarts";
import AADService from "../funcionesAuth.js";

const StackedLineResidualChart = ({ historico }) => {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (historico.length === 0) return;

    if (!chart) {
      const chartDom = document.getElementById("main_residual_chart");
      const myChart = echarts.init(chartDom);
      setChart(myChart);
    }

    if (chart) {
      const staticColors = ["#5470C6", "#91CC75", "#EE6666", "#FAC858"];
      const getClasificationValue = (classification) => {
        switch (classification) {
          case "Bajo":
            return 1;
          case "Medio":
            return 2;
          case "Alto":
            return 3;
          default:
            return 0;
        }
      };

      const seriesData = historico.map((item, index) => ({
        // Eliminamos el prefijo "Atributo:"
        name: item.atributo,
        type: "line",
        data: item.clasificacion.map(getClasificationValue),
        lineStyle: {
          color: staticColors[index % staticColors.length],
        },
        itemStyle: {
          color: staticColors[index % staticColors.length],
        },
      }));
      const dates = historico[0].fechacreacion.map((date) =>
        new Date(date).toLocaleDateString()
      );

      const option = {
        title: {
          text: "Histórico de Clasificaciones",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: seriesData.map((item) => item.name), 
          top: 30,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dates,
        },
        yAxis: {
          type: "value",
          name: "Nivel de Clasificación",
          axisLabel: {
            formatter: (value) => {
              switch (value) {
                case 1:
                  return "Bajo";
                case 2:
                  return "Medio";
                case 3:
                  return "Alto";
                default:
                  return "";
              }
            },
          },
        },
        series: seriesData,
      };

      chart.setOption(option);
    }

    return () => {
      chart && chart.dispose();
    };
  }, [historico, chart]);

  return (
    <div
      id="main_residual_chart"
      style={{
        width: "90%",
        height: "600px", 
        margin: "0 auto", 
      }}
    ></div>
  );
};


function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}


const InteractiveTable = ({ idrol, onSave, preguntas, dataClasificacion }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [answersSend, setAnswersSend] = useState({});

  const handleSelectChange = (event, rowIndex, idparametrosgenerales) => {
    setAnswers({
      ...answers,
      [rowIndex]: event.target.value,
    });
    setAnswersSend({
      ...answersSend,
      [idparametrosgenerales]: event.target.value === "Si" ? 1 : 0,
    });
  };

  let globalRowIndex = 0;
  return (
    <div>
      <div style={{ textAlign: "right", marginTop: "10px", marginBottom: "10px" }}>
        <Button className="botonPositivo3" onClick={() => onSave(answersSend)}>
          {idrol == 3 || idrol == 4 || idrol == 5 ? "Autoevaluar" : "Evaluar"}
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} aria-label="interactive table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "black" }}>
              <TableCell sx={{ color: "white" }}>Atributo</TableCell>
              <TableCell sx={{ color: "white" }}>Pregunta</TableCell>
              <TableCell sx={{ color: "white" }}>Clasificación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preguntas.map((item, itemIndex) => {
              const rowIndex = globalRowIndex++;
              return (
                <TableRow key={`${itemIndex}-${item.vlrmetrica}`}>
                  <TableCell>
                    {item.disp_varchar1}
                  </TableCell>
                  <TableCell>{item.vlrmetrica}</TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <select
                      className="form-control"
                      value={answers[rowIndex] || ""}
                      onChange={(e) => handleSelectChange(e, rowIndex, item.idparametrosgenerales)}
                      disabled={
                        (dataClasificacion.metodo_confidencialidad !== 'Por evaluación' &&
                          item.disp_varchar1 == 'Confidencialidad') ||
                        (dataClasificacion.metodo_integridad !== 'Por evaluación' &&
                          item.disp_varchar1 == 'Integridad') ||
                        (dataClasificacion.metodo_disponibilidad !== 'Por evaluación' &&
                          item.disp_varchar1 == 'Disponibilidad')
                      }
                    >
                      <option value="" disabled>
                        Seleccione
                      </option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const ModalHistorico = ({
  open,
  handleClose,
  dataHistorico
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%", // Ajusta el ancho del modal
          maxWidth: "1200px", // Aumenta el tamaño máximo
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          outline: "none",
        }}
      >
        <Row>
          <Col sm={12}>
            <h1 style={{ textAlign: "center" }}>Histórico de clasificación</h1>
          </Col>
        </Row>
        {dataHistorico.length > 0 ? (
          <Row style={{ justifyContent: "center" }}>
            <Col md={10} sm={12}>
              {/* Aquí pasamos el gráfico con las modificaciones */}
              <StackedLineResidualChart
                historico={dataHistorico}
                width={800} // Aumenta el tamaño del gráfico
                height={400} // Ajusta el alto del gráfico
                legendFormatter={(legend) => legend.replace("Atributo: ", "")} // Elimina el prefijo
              />
            </Col>
          </Row>
        ) : (
          <Alert variant="info">No hay datos históricos para mostrar</Alert>
        )}
        {/* Footer con el botón para cerrar */}
        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
const ModalComponent = ({
  open,
  handleClose,
  DataClasificacion,
  Preguntas,
}) => {
  // Crear un mapeo entre idparametrosgenerales y el texto de la pregunta (vlrmetrica)
  const preguntasMap = {};
  Preguntas.forEach((pregunta) => {
    preguntasMap[pregunta.idparametrosgenerales] = pregunta.vlrmetrica;
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "1000px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          outline: "none",
        }}
      >
        <Row>
          <Col sm={12}>
            <h1>Resultados</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Pregunta</TableCell>
                    <TableCell>Dueño</TableCell>
                    <TableCell>Ciberseguridad</TableCell>
                    <TableCell>Analista ROTIC</TableCell>
                    <TableCell>Auditoría Interna</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Validar si DataClasificacion.answers tiene datos antes de mapear */}
                  {DataClasificacion?.answers?.length > 0 &&
                    Object.keys(DataClasificacion.answers[0].respuestas).map(
                      (preguntaId) => (
                        <TableRow key={preguntaId}>
                          {/* Muestra el ID de la pregunta y el texto usando el mapeo */}
                          <TableCell>
                            {preguntaId} -{" "}
                            {preguntasMap[preguntaId] || "Texto no disponible"}
                          </TableCell>
                          <TableCell>
                            {DataClasificacion.answers.find(
                              (answer) => answer.idrol === 1
                            )?.respuestas[preguntaId] === 1 ? (
                              <span style={{ color: "green" }}>Sí</span>
                            ) : DataClasificacion.answers.find(
                              (answer) => answer.idrol === 1
                            )?.respuestas[preguntaId] === 0 ? (
                              <span style={{ color: "red" }}>No</span>
                            ) : (
                              <span style={{ color: "gray" }}>N/A</span>
                            )}
                          </TableCell>
                          <TableCell>
                            {DataClasificacion.answers.find(
                              (answer) => answer.idrol === 5
                            )?.respuestas[preguntaId] === 1 ? (
                              <span style={{ color: "green" }}>Sí</span>
                            ) : DataClasificacion.answers.find(
                              (answer) => answer.idrol === 5
                            )?.respuestas[preguntaId] === 0 ? (
                              <span style={{ color: "red" }}>No</span>
                            ) : (
                              <span style={{ color: "gray" }}>N/A</span>
                            )}
                          </TableCell>
                          <TableCell>
                            {DataClasificacion.answers.find(
                              (answer) => answer.idrol === 2
                            )?.respuestas[preguntaId] === 1 ? (
                              <span style={{ color: "green" }}>Sí</span>
                            ) : DataClasificacion.answers.find(
                              (answer) => answer.idrol === 2
                            )?.respuestas[preguntaId] === 0 ? (
                              <span style={{ color: "red" }}>No</span>
                            ) : (
                              <span style={{ color: "gray" }}>N/A</span>
                            )}
                          </TableCell>
                          <TableCell>
                            {DataClasificacion.answers.find(
                              (answer) => answer.idrol === 3
                            )?.respuestas[preguntaId] === 1 ? (
                              <span style={{ color: "green" }}>Sí</span>
                            ) : DataClasificacion.answers.find(
                              (answer) => answer.idrol === 3
                            )?.respuestas[preguntaId] === 0 ? (
                              <span style={{ color: "red" }}>No</span>
                            ) : (
                              <span style={{ color: "gray" }}>N/A</span>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>

        {/* Footer con el botón para cerrar */}
        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
const ClasificacionComponente = () => {
  const [DataClasificacion, setDataClasificacion] = React.useState([]);
  const [DataPreguntas, setDataPreguntas] = React.useState([]);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [dataHistorico, setDataHistorico] = React.useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  const fetchSendData = async (respuestas) => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
      "activoClasificacion/" +
      localStorage.getItem("idactivo") +
      "/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          idactivo: localStorage.getItem("idactivo"),
          idusuario: localStorage.getItem("idusuario"),
          idrol: localStorage.getItem("rolusuario"),
          respuestas: respuestas,
          clasificacion_confidencialidad: DataClasificacion.clasificacion_confidencialidad,
          clasificacion_integridad: DataClasificacion.clasificacion_integridad,
          clasificacion_disponibilidad: DataClasificacion.clasificacion_disponibilidad,
          metodo_confidencialidad: DataClasificacion.metodo_confidencialidad,
          metodo_integridad: DataClasificacion.metodo_integridad,
          metodo_disponibilidad: DataClasificacion.metodo_disponibilidad,
        }),
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      setEstadoPost(2);
      obtenerPreguntas();
      obtenerClasificacion();
    } else if (result.status >= 400 && result.status <= 500) {
      setEstadoPost(4);
    } else {
      setEstadoPost(5);
    }
  };

  const obtenerColorFondo = (valor) => {
    switch (valor) {
      case "Alto":
        return "#e9645c";
      case "Medio":
        return "#fdefa7";
      case "Bajo":
        return "#cbffcb";
      case "No clasificado":
        return "#ffffff";
    }
  };
  const obtenerPreguntas = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
      "general/parametrosGeneralesxGrupoxParametro/Componentes/Clasificacion/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let datPreguntas = await result.json();
      setDataPreguntas(datPreguntas);
    } else if (result.status >= 400 && result.status <= 500) {
      setDataPreguntas([]);
      return result.status;
    }
  };
  const obtenerHistorico = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
      "historicoActivoInformacion/" +
      localStorage.getItem("idactivo") +
      "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let datHistorico = await result.json();
      setDataHistorico(datHistorico);
    } else if (result.status >= 400 && result.status <= 500) {
      const initialdata = [
        {
          "atributo": "Confidencialidad",
          "clasificacion": [
            "Bajo",
            "Bajo",
            "Alto",
            "Medio"
          ],
          "fechacreacion": [
            "2023-01-09T14:25:50Z",
            "2023-06-12T14:26:34Z",
            "2023-12-10T14:26:34Z",
            "2024-05-09T14:26:34Z"
          ]
        },
        {
          "atributo": "Integridad",
          "clasificacion": [
            "Alto",
            "Medio",
            "Alto",
            "Medio"
          ],
          "fechacreacion": [
            "2023-01-09T14:25:50Z",
            "2023-06-12T14:26:34Z",
            "2023-12-10T14:26:34Z",
            "2024-05-09T14:26:34Z"
          ]
        },
        {
          "atributo": "Disponibilidad",
          "clasificacion": [
            "Alto",
            "Alto",
            "Alto",
            "Alto"
          ],
          "fechacreacion": [
            "2023-01-09T14:25:50Z",
            "2023-06-12T14:26:34Z",
            "2023-12-10T14:26:34Z",
            "2024-05-09T14:26:34Z"
          ]
        }
      ]
      setDataHistorico(initialdata);
      return result.status;
    }
  };
  const obtenerClasificacion = async () => {
    const result = await fetch(
      process.env.REACT_APP_API_URL +
      "activoClasificacion/" +
      localStorage.getItem("idactivo") +
      "/",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status >= 200 && result.status <= 300) {
      let datClasificacion = await result.json();
      setDataClasificacion(datClasificacion[0]);
    } else if (result.status >= 400 && result.status <= 500) {
      setDataClasificacion([]);
      return result.status;
    }
  };
  useEffect(() => {
    obtenerPreguntas();
    obtenerClasificacion();
    obtenerHistorico();
  }, []);

  const idRol = localStorage.getItem("rolusuario");
  const [isResultadosOpen, setIsResultadosOpen] = useState(false);
  const [isHistoricoOpen, setIsHistoricoOpen] = useState(false);

  const handleResultadosOpen = () => setIsResultadosOpen(true);
  const handleResultadosClose = () => setIsResultadosOpen(false);

  const handleHistoricoOpen = () => setIsHistoricoOpen(true);
  const handleHistoricoClose = () => setIsHistoricoOpen(false);
  const [contador, setContador] = useState(1);

  const handleSave = (answersSend) => {
    if (Object.keys(answersSend).length === 0) {
      alert("Por favor, responda todas las preguntas disponibles antes de guardar.");
      return;
    }
    const respuestas = DataPreguntas.map((pregunta) => ({
      idpregunta: pregunta.idparametrosgenerales,
      respuesta: answersSend.hasOwnProperty(pregunta.idparametrosgenerales)
        ? answersSend[pregunta.idparametrosgenerales]
        : "",
    }));
    fetchSendData(respuestas);
  };

  const Contador = () => {
    setContador(contador + 1);
  };

  const Circle = styled("div")(({ color }) => ({
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: color,
    display: "inline-block",
    marginLeft: "5px",
  }));

  return (
    <div>
      <AlertDismissibleExample alerta={estadoPOST} />
      <Row>
        <Col sm={12}>
          <br />
          <h1>Clasificación de activos</h1>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <label className="label">Confidencialidad</label>
        </Col>
        <Col sm={2}>
          <input
            type="text"
            className="form-control text-center font-weight-bold"
            defaultValue={DataClasificacion.clasificacion_confidencialidad}
            disabled
            style={{
              backgroundColor: obtenerColorFondo(
                DataClasificacion.clasificacion_confidencialidad
              ),
            }}
          />
        </Col>

        <Col sm={2}>
          <label className="label">Integridad</label>
        </Col>
        <Col sm={2}>
          <input
            type="text"
            className="form-control text-center font-weight-bold"
            defaultValue={DataClasificacion.clasificacion_integridad}
            disabled
            style={{
              backgroundColor: obtenerColorFondo(
                DataClasificacion.clasificacion_integridad
              ),
            }}
          />
        </Col>

        <Col sm={2}>
          <label className="label">Disponibilidad</label>
        </Col>
        <Col sm={2}>
          <input
            type="text"
            className="form-control text-center font-weight-bold"
            defaultValue={DataClasificacion.clasificacion_disponibilidad}
            disabled
            style={{
              backgroundColor: obtenerColorFondo(
                DataClasificacion.clasificacion_disponibilidad
              ),
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <label style={{ fontWeight: 500 }}>Método de Clasificación</label>
        </Col>
        <Col sm={2}>
          <label>{DataClasificacion.metodo_confidencialidad}</label>
        </Col>
        <Col sm={2}>
          <label style={{ fontWeight: 500 }}>Método de Clasificación</label>
        </Col>
        <Col sm={2}>
          <label>{DataClasificacion.metodo_integridad}</label>
        </Col>
        <Col sm={2}>
          <label style={{ fontWeight: 500 }}>Método de Clasificación</label>
        </Col>
        <Col sm={2}>
          <label>{DataClasificacion.metodo_disponibilidad}</label>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={11} style={{ marginBottom: "10px" }}>
          <Button className="botonNegativo2" onClick={handleResultadosOpen}>
            Resultados
          </Button>
        </Col>
        <Col sm={1} style={{ alignItems: "right" }}>
          <Button 
            className="botonNegativo2" 
            onClick={handleHistoricoOpen}
            disabled={true}
            >
            Historico
          </Button>
        </Col>

        <ModalComponent
          open={isResultadosOpen}
          handleClose={handleResultadosClose}
          DataClasificacion={DataClasificacion}
          Preguntas={DataPreguntas}
        />
        <ModalHistorico
          open={isHistoricoOpen}
          handleClose={handleHistoricoClose}
          dataHistorico={dataHistorico}
        />
      </Row>
      <Row>
        <Col sm={12}>
          <br />
          <InteractiveTable
            idrol={idRol}
            onSave={handleSave}
            preguntas={DataPreguntas}
            dataClasificacion={DataClasificacion}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <h2 id="modal-title">Activos de Información</h2>
        <TableContainer component={Paper}>
          <Table aria-label="activos table">
            <TableHead sx={{ backgroundColor: "black" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }}>ID</TableCell>
                <TableCell sx={{ color: "white" }}>Nombre</TableCell>
                <TableCell sx={{ color: "white" }}>Confidencialidad</TableCell>
                <TableCell sx={{ color: "white" }}>Integridad</TableCell>
                <TableCell sx={{ color: "white" }}>Disponibilidad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {DataClasificacion.activos_info?.map((activo) => (
                <TableRow key={activo.idactivo}>
                  <TableCell>{activo.idactivo}</TableCell>
                  <TableCell>{activo.activo}</TableCell>
                  <TableCell>{activo.clasificacion_confidencialidad}</TableCell>
                  <TableCell>{activo.clasificacion_integridad}</TableCell>
                  <TableCell>{activo.clasificacion_disponibilidad}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Row>
    </div>
  );
};

export default ClasificacionComponente;
