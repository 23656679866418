import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip, List, ListItem, ListItemText } from "@material-ui/core";
import AADService from "../funcionesAuth.js";
import styled from "@mui/material/styles/styled";
import HelpIcon from "@mui/icons-material/Help";
import { tableCellClasses } from "@mui/material/TableCell";
import DomainChartComponent from "./DomainChartComponent.js";
import calificacionUmbral from "../calificacion-umbral.png"


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: "none", // Permite que el tooltip se ajuste al contenido
    padding: 0, // Elimina el padding para que la imagen ocupe todo el espacio
  },
  img: {
    display: "block",
    maxWidth: "100%",
    height: "auto",
  },

  container: {
    maxHeight: "55vh",
    minHeight: "auto",
    overflowY: "auto",
  },
  root: {
    width: "100%",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  switch: {
    paddingLeft: "1.5em",
  },
  inside: {
    backgroundColor: "white",
    width: "100%",
    // margintom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },

  head: {
    backgroundColor: "#2c2a29",
    color: "white",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
    //zIndex: 1,
  },
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#ff9100",
    },
  },
}));

function LineasBaseEro() {
  const classes = useStyles();

  const [informacion, setLineasBase] = useState([]);
  const [dominioschart, setDominiosChart] = useState([]);
  const [selectedLineaBase, setSelectedLineaBase] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [dominiosDisponibles, setDominiosDisponibles] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();


  useEffect(() => {
    const fetchLineasBase = async () => {
      try {
        const result = await fetch(
          `${process.env.REACT_APP_API_URL}evaluacion/lineasbase/` +
            `${localStorage.getItem("idactivo")}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await result.json();
        setLineasBase(data["info"]);
        setDominiosChart(data["dominios"]);
      } catch (error) {
        console.error("Error al obtener las líneas base:", error);
      }
    };
    fetchLineasBase();
  }, []);

  const handleLineaBaseChange = (event) => {
    const selectedBase = event.target.value;
    setSelectedLineaBase(selectedBase);

    const selectedBaseData = informacion.find(
      (item) => item.nombrecis === selectedBase
    );
    const domains = selectedBaseData
      ? Object.keys(selectedBaseData.detalle_cis.Domain)
      : [];
    setDominiosDisponibles(domains);
    setSelectedDomain("");

    filterData(selectedBase, "");
  };

  const handleDomainChange = (event) => {
    const domain = event.target.value;
    setSelectedDomain(domain);

    filterData(selectedLineaBase, domain);
  };

  const filterData = (lineaBase, domain) => {
    const filtered = informacion
      .filter((row) => !lineaBase || row.nombrecis === lineaBase)
      .flatMap((row) =>
        Object.keys(row.detalle_cis.Domain || {})
          .filter((domainKey) => !domain || domainKey === domain)
          .flatMap((domainKey) => {
            const recommendations =
              row.detalle_cis.Domain[domainKey]?.Recommendation || {};
            return Object.keys(recommendations).flatMap((recommendationKey) =>
              recommendations[recommendationKey].map((recommendation) => ({
                nombrecis: row.nombrecis,
                domain: domainKey,
                recommendationKey,
                recommendation,
              }))
            );
          })
      );
    setFilteredData(filtered);
  };

  useEffect(() => {
    if (informacion.length > 0) {
      filterData(selectedLineaBase, selectedDomain);
    }
  }, [informacion]);

    const CustomTooltip = (props) => {
      const classes = useStyles();
      return <Tooltip classes={{ tooltip: classes.tooltip }} {...props} />;
    };


  const renderClasificacion = () => (
    <CustomTooltip
      title={
        <List>
          <ListItem>
            <ListItemText primary="Umbrales y Atribuciones:" />
          </ListItem>
          <ListItem>
            <img src={calificacionUmbral} />
          </ListItem>
        </List>
      }
    >
      <HelpIcon />
    </CustomTooltip>
  );

  return (
    <div>
      <br />
      <hr />
      <Row>
        <Col sm={4} xs={12}>
          <label className="label forn-label">Cumplimiento</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            defaultValue={""}
          ></input>
        </Col>
        <Col sm={4} xs={12}>
          <label className="label forn-label">Efectividad capa</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            defaultValue={""}
          ></input>
        </Col>
      </Row>
      <Row>
        <Col sm={4} xs={12}>
          <label className="label forn-label">Cumplimiento por dominios</label>
          {renderClasificacion()}
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12}>
          {dominioschart.length > 0 && (
            <DomainChartComponent dominios={dominioschart} />
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12} xs={12}>
          <label className="label forn-label">
            Recomendaciones y Controles
          </label>
        </Col>
      </Row>
      <Row>
        <Col sm={4} xs={12} className="mb-3">
          <select value={selectedLineaBase} onChange={handleLineaBaseChange}>
            <option value="">Seleccione una línea base</option>
            {informacion.map((item) => (
              <option key={item.nombrecis} value={item.nombrecis}>
                {item.nombrecis}
              </option>
            ))}
          </select>
        </Col>
        <Col sm={4} xs={12} className="mb-3">
          <select
            value={selectedDomain}
            onChange={handleDomainChange}
            disabled={!selectedLineaBase}
          >
            <option value="">Seleccione un dominio</option>
            {dominiosDisponibles.map((domain) => (
              <option key={domain} value={domain}>
                {domain}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableContainer component={Paper} className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              maxHeightBody="55vh"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow className={classes.head}>
                  <StyledTableCell>Línea Base</StyledTableCell>
                  <StyledTableCell>Dominio</StyledTableCell>
                  <StyledTableCell>Recomendacion/Lineamiento</StyledTableCell>
                  <StyledTableCell>¿Cumplió?</StyledTableCell>
                  <StyledTableCell>Id Control CIS</StyledTableCell>
                  <StyledTableCell>Control CIS</StyledTableCell>
                  <StyledTableCell>Version Control</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredData.map((item, index) => {
                  const cumple = Math.random() < 0.5;
                  return (
                    <StyledTableRow
                      key={`${item.nombrecis}-${item.domain}-${index}`}
                    >
                      <StyledTableCell>{item.nombrecis}</StyledTableCell>
                      <StyledTableCell>{item.domain}</StyledTableCell>
                      <StyledTableCell>
                        {item.recommendationKey}
                      </StyledTableCell>
                      <StyledTableCell>
                        <span style={{ color: cumple ? "green" : "red" }}>
                          {cumple ? "Sí" : "No"}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.recommendation.ControlIdCIS}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.recommendation["Control Name"]}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.recommendation["Version Control"]}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
      </Row>
      <br />
      <hr />
      <br />
    </div>
  );
}
export default LineasBaseEro;
