import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import { IoMdDownload } from "react-icons/io";
import XLSX from "xlsx";
function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant='warning'>Alerta</Alert>;
      break;
    case 2:
      return <Alert variant='success'>Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant='danger'></Alert>;
      break;
    case 4:
      return <Alert variant='warning'>Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant='danger'>Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant='warning'>
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}

export default function Informes() {
  const [estadoPOST, setEstadoPost] = useState(0);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [riesgos, setRiesgos] = useState([]);
  const [planesAccion, setPlanesAccion] = useState([]);
  const [datosVSC, setDatosVSC] = useState([]);
  const [datosEROS, setDatosEROS] = useState([]);
  const [datosERF, setDatosERF] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingEROS, setLoadingEROS] = useState(true);
  const [loadingERF, setLoadingERF] = useState(true);

  useEffect(() => {
    const generarInfo = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "informes/VSC/",
        {
          method: "GET",
          headers: {
            "Content-type": "application/json;",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datainfo = await result.json();
      setDatosVSC(datainfo);
      setLoading(false);
    };

    const generarInfoEROS = async () => {
      const result2 = await fetch(
        process.env.REACT_APP_API_URL + "informes/evaluacionxriesgo/",
        {
          method: "GET",
          headers: {
            "Content-type": "application/json;",
            //Authorization: "Bearer " + token,
          },
        }
      );
      let datainfo2 = await result2.json();
      setDatosEROS(datainfo2.evaluaciones_por_riesgo);
      setLoadingEROS(false);

    };

      const generarinfoERF = async () => {
        const result3 = await fetch(
          process.env.REACT_APP_API_URL + "informes/ERF/",
          {
            method: "GET",
            headers: {
              "Content-type": "application/json;",
              //Authorization: "Bearer " + token,
            },
          }
        );
        let datainfo3 = await result3.json();
        setDatosERF(datainfo3);
        setLoadingERF(false);

    };
    generarInfo();
    generarInfoEROS();
    generarinfoERF();
    //informeEvaluaciones();
    //informeRiesgo();
    //informePA();
  }, []);

  const handleOnExport = () => {
    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(datosVSC);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Informe_VSC");
    XLSX.writeFile(newBook, "Informe_VSC.xlsx");
  };
  
  const handleOnExportEROS = () => {
    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(datosEROS);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Informe_EROS");
    XLSX.writeFile(newBook, "Informe_EROS.xlsx");
  }

  const handleOnExportERF = () => {
    const newBook = XLSX.utils.book_new(),
      sheet1 = XLSX.utils.json_to_sheet(datosERF);
    XLSX.utils.book_append_sheet(newBook, sheet1, "Informe_ERF");
    XLSX.writeFile(newBook, "Informe_ERF.xlsx");
  }

  return (
    <div className='d-flex flex-column'>
      <AlertDismissibleExample alerta={estadoPOST} />

      <h2 className='titulo'>Informe de VSC</h2>
      {datosVSC && datosVSC.length > 0 && (
        <>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className='d-flex justify-content-center align-items-center col-12'>
              <Button className='botonPositivo' onClick={handleOnExport}>
                Descargar informe de VSC &nbsp;
                <IoMdDownload />
              </Button>
            </div>
          )}
        </>
      )}
      
      <h2 className='titulo'>Informe de Evaluaciones por Riesgo</h2>
      {datosEROS && datosEROS.length > 0 && (
        <>
          {loadingEROS ? (
            <CircularProgress />
          ) : (
            <div className='d-flex justify-content-center align-items-center col-12'>
              <Button className='botonPositivo' onClick={handleOnExportEROS}>
                Descargar informe de Evaluaciones por Riesgo &nbsp;
                <IoMdDownload />
              </Button>
            </div>
          )}
        </>
      )}

      <h2 className='titulo'>Informe de ERF</h2>
      {datosERF && datosERF.length > 0 && (
        <>
          {loadingERF ? (
            <CircularProgress />
          ) : (
            <div className='d-flex justify-content-center align-items-center col-12'>
              <Button className='botonPositivo' onClick={handleOnExportERF}>
                Descargar informe de ERF &nbsp;
                <IoMdDownload />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
