import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { CircularProgress, TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import CheckRole from "../CheckRole";
import AADService from "../funcionesAuth.js";
import XLSX from "xlsx";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions";
import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
//import Checkbox from "@mui/material/Checkbox";

function App(props) {
  let tipoElemento = "";
  if (props.value === 1) {
    tipoElemento = "Aplicación";
  } else if (props.value === 2) {
    tipoElemento = "Módulo";
  }
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2c2a29",

      color: "white",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: "#f4f4f4",
    },
  }))(TableRow);

  const ExportToExcel = ({ apiData }) => {
    const downloadExcel = (data) => {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "informe.xlsx");
    };

    return (
      <Button
        className="botonPositivo"
        style={{ minWidth: "250px", float: "right" }}
        onClick={(e) => downloadExcel(apiData)}
      >
        Descargar Informe
      </Button>
    );
  };

  function AlertDismissibleExampleModal({ alertaModal, descargainforme }) {
    const [data, setData] = React.useState([]);
    const AzureADService = new AADService();
    const token = AzureADService.getToken();
    useEffect(() => {
      const excelData = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL +
            "activoInformeError/" +
            descargainforme,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (result.status >= 200 && result.status <= 300) {
          let data = await result.json();
          setData(data);
        } else if (result.status >= 400 && result.status <= 500) {
          return result.status;
        }
      };
      excelData();
    }, []);
    if (descargainforme != 0) {
      switch (alertaModal) {
        case 1:
          return <Alert variant="warning">Alerta</Alert>;
          break;
        case 2:
          return (
            <Alert variant="success">
              Se han cargado los datos exitosamente
            </Alert>
          );
          break;
        case 3:
          return <Alert variant="warning">Elija un Archivo</Alert>;
          break;
        case 4:
          return (
            <Alert variant="warning" style={{ paddingBottom: "22px" }}>
              <div className="App">
                <ExportToExcel apiData={data} />
              </div>
              Error al enviar la información
            </Alert>
          );
          break;
        case 5:
          return (
            <Alert variant="danger">El Archivo Cargado No es valido</Alert>
          );
          break;
        case 6:
          return <Alert variant="warning">Error procesando los datos</Alert>;
          break;
        default:
          return null;
          break;
      }
    } else {
      return null;
    }
  }

  var columns = [
    {
      field: "idactivo",
      title: "Id Modulo",
      align: "justify",
    },
    {
      field: "elemento_configuracion",
      title: "Codigo app",
      align: "justify",
    },
    {
      field: "nombre",
      title: "Nombre",
    },
    {
      field: "compania",
      title: "Compañia",
      render: (rowData) => bCompania(rowData.idcompania),
    },
    {
      field: "componente_principal",
      title: "Principal",
      render: (rowData) => cprincipal(rowData.componente_principal),
    },
    {
      field: "descripcion",
      title: "Descripción",
    },
    {
      field: "UsuarioResponsableTI",
      title: "Responsable TI",
    },
    {
      field: "UsuarioResponsableNegocio",
      title: "Responsable Negocio",
    },
    {
      field: "fechacreacion",
      title: "Fecha Creación",
    },
    {
      field: "fechamodificacion",
      title: "Fecha Actualización",
    },
  ];

  const classes = useStyles();
  const [eventos, setEventos] = useState([]);
  const [activo, setActivo] = useState([]); //TODO: table data
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [ButtonCreate, SetButtonCreate] = React.useState(
    localStorage.getItem("idcompania") != 1 ? true : false
  );
  const [filter, setFilter] = useState(false);
  const [nameCompa, setNameCompa] = React.useState("");
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const check = new CheckRole("/componente-tecnologico");
  const [showModal, setShowModal] = React.useState(false);
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [id_descargainforme, setId_descargainforme] = React.useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = React.useState(false);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = activo.filter((row) => {
    return (
      row.idactivo.toString().includes(searchTerm) ||
      row.nombre?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.UsuarioResponsableNegocio?.toLowerCase().includes(
        searchTerm.toLowerCase()
      ) ||
      row.UsuarioResponsableTI?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    //let data = [];
    const fetchdata_COM = async () => {
      setLoading(true);
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "allcomponents/" +
          localStorage.getItem("idcompania") +
          "/" +
          props.value +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let data = await result.json(); //TODO:
        const dataFiltroEstado = data.filter((item) => item.estado === true);
        setActivo(dataFiltroEstado);
        setLoading(false);
        //fetchdata_NUB(data);
      } else if (result.status >= 400 && result.status <= 500) {
        return result.status;
      }
    };
    const obtenerCompañias = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL + "general/compania/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let datCompania = await result.json();
      setNameCompa(datCompania);
    };
    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);

      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }

      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        fetchdata_COM();
        //fetchdata_NUB();
        obtenerCompañias();
      }
    };
    fetchUsuario();
  }, []);

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      newSelected = [];
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const editar = () => {
    localStorage.setItem('idactivo', selected);
  };

  function add_eval() {
    localStorage.setItem("idactivo", 0);
  }

  function ModalCarga(props) {
    const [stateArchivo, setStateArchivo] = React.useState([]);
    const [archivo, setArchivo] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [informeExcel, setInformeExcel] = React.useState([]);
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };
    let tempArchivos = [];
    let temp = [];

    const subirArchivo = (e) => {
      //console.log("Estoy en subir archivo");
      //console.log("info archivo", e);
      setEstadoPost(0);
      setArchivo([]);
      //setNames([]);
      setStateArchivo([]);
      setNames([]);
      //setNamesN([]);

      for (let i = 0; i < e.length; i++) {
        if (
          e[i].name.split(".")[1] == "xlsx" ||
          e[i].name.split(".")[1] == "csv"
        ) {
          temp.push(e[i].name);
          tempArchivos.push(e[i]);
        }
      }
      setArchivo(tempArchivos);
      setNames(temp);
      // setLoading(false)
    };

    const fetchdataPost = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "activoInfoMasivo/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      setActivo(data);
    };

    const postArchivo = async (event, file) => {
      //console.log("Estoy en post archivo");
      setLoading(true);
      if (file.length) {
        setEstadoPost(0);
        event.preventDefault();
        const form = event.currentTarget;
        let reader = new FileReader();
        reader.readAsArrayBuffer(file[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          var sheetName = workbook.SheetNames[0];
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          procesarDatos(names[0], XL_row_object);
        };
      } else {
        event.preventDefault();
        setLoading(false);
        setEstadoPost(3);
      }
      // setLoading(false)
    };

    const procesarDatos = async (archivosKEY, info) => {
      //console.log("Estoy en procesar datos")
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      //console.log("tipo de dato de info", typeof info);
      for (let i = 0; i < info.length; i++) {
        info[i]["fechacreacion"] = today.toISOString();
        info[i]["fechamodificacion"] = today.toISOString();
        info[i]["idusuariocreacion"] = localStorage.getItem("idusuario");
        info[i]["idusuariomodificacion"] = localStorage.getItem("idusuario");
        info[i]["componente_principal"] = 0;
      }
      var data = {
        idcompania:
          localStorage.getItem("idcompania") != null &&
          localStorage.getItem("idcompania") != undefined
            ? localStorage.getItem("idcompania")
            : 1,

        key: archivosKEY,
        message: info,
      };
      let datos = JSON.stringify(data);
      fetch(
        process.env.REACT_APP_API_URL +
          "activoInfoMasivo/" +
          localStorage.getItem("idcompania") +
          "/",
        {
          method: "POST",
          //responseType: "blob",
          body: datos,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) =>
          response.json().then((data) => {
            //console.log("$$$$", response);
            if (response.status >= 200 && response.status < 300) {
              setEstadoPost(2);
              setLoading(false);
              fetchdataPost();
              //props.onHide();
            } else if (response.status >= 500) {
              setEstadoPost(5);
              //props.onHide();
              setLoading(false);
            } else if (response.status >= 400 && response.status < 500) {
              setId_descargainforme(data.id_activo);
              // console.log(id_descargainforme)
              setEstadoPost(4);
              setLoading(false);
            }
          })
        )
        .catch(function (err) {});
      //setShowModal(true);
    };

    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Form
          onSubmit={(e) => {
            postArchivo(e, archivo);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Cargue Masivo de Componentes
              <br></br>
              <a
                href="https://bancolombia.sharepoint.com.mcas.ms/teams/CdulaRiesgoTI-Ciber/_layouts/15/download.aspx?UniqueId=ac10f560%2Db69d%2D4bc2%2Da755%2D8240645a720c&McasTsid=20892"
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar plantilla
              </a>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AlertDismissibleExampleModal
              alertaModal={estadoPOST}
              descargainforme={id_descargainforme}
            />
            <Row className="mb-3">
              <Col>
                <input
                  type="file"
                  name="files"
                  accept=".xlsx,.csv"
                  multiple
                  onChange={(e) => subirArchivo(e.target.files)}
                ></input>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <ul className="list-group">
                  <li className="list-group-item">
                    <Row>
                      <Col sm={4}>
                        <h5>Nombre del archivo</h5>
                      </Col>
                      <Col sm={4}>
                        <h5>Nombre en el servidor</h5>
                      </Col>
                      <Col sm={1}>
                        <h5>Estado</h5>
                      </Col>
                    </Row>
                  </li>
                  {names.map((item, index) => (
                    <li className="list-group-item" key={index}>
                      <Row>
                        <Col sm={4}>{item}</Col>
                        <Col sm={4}>{names[index]}</Col>
                        <Col sm={1}>{stateArchivo[index]}</Col>
                      </Row>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <Button
                type="submit"
                className="botonPositivo"
                style={{ minWidth: "20%" }}
              >
                Cargar
              </Button>
            )}
            <Button onClick={props.onHide} className="botonNegativo">
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  function cprincipal(componente_principal) {
    if (componente_principal === 1) {
      return "Principal";
    } else if (componente_principal === 2) {
      return "Módulo";
    } else if (componente_principal === 3) {
      return "Infraestructura";
    } else {
      return "Valor no reconocido";
    }
  }

  //Retorna el nombre de la compañía segun id
  function bCompania(id) {
    for (const clave in nameCompa) {
      if (id == nameCompa[clave]["idcompania"]) {
        return nameCompa[clave]["compania"];
      }
    }
    return "No Existe";
  }

  //Habilita y desabilita los filtros por columnas
  const handleChange = () => {
    setFilter(!filter);
  };
  return (
    <div className={classes.root}>
      <br />
      <ModalCarga show={showModal} onHide={() => setShowModal(false)} />
      <Row className="mb-3">
        <Col sm={6} xs={12}>
          <TextField
            label="Buscar"
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            InputProps={{
              style: {
                height: "40px",
                padding: "0px",
              },
            }}
          />
        </Col>
        {ButtonCreate ? (
          <Col sm={3} xs={12}>
            <Link
              to={{
                pathname: "NuevoComponente",
                state: { value: props.value },
              }}
            >
              {check.checkPermits(opcionesGenerales, "POST") ? (
                <Button className="botonPositivo" style={{ minWidth: "280px" }} onClick={add_eval}>
                  Nuevo
                </Button>
              ) : (
                ""
              )}
            </Link>
          </Col>
        ) : (
          <Col sm={3} xs={12}>
            {" "}
          </Col>
        )}
        {ButtonEdit ? (
          <Col sm={3} xs={12}>
            <Link
              to={{
                pathname: "MainEditarComponente",
                state: { value: props.value },
              }}
            >
              {check.checkPermits(opcionesGenerales, "PUT") ? (
                <Button className="botonGeneral" onClick={editar}>
                  Editar
                </Button>
              ) : (
                ""
              )}
            </Link>
          </Col>
        ) : (
          <Col sm={3} xs={12}>
            {" "}
          </Col>
        )}
      </Row>
      <br />
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <CircularProgress />
        </div>
      ) : (
      <Row>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            maxHeightBody="55vh"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow className={classes.head}>
                <StyledTableCell padding="checkbox"></StyledTableCell>
                <StyledTableCell>{"Id " + tipoElemento}</StyledTableCell>
                <StyledTableCell>Clase app</StyledTableCell>
                <StyledTableCell>Código app</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Nombre CMD</StyledTableCell>
                <StyledTableCell>Compañía</StyledTableCell>
                <StyledTableCell>Tipo de elemento</StyledTableCell>
                <StyledTableCell>Descripción</StyledTableCell>
                <StyledTableCell>Responsable TI</StyledTableCell>
                <StyledTableCell>Responsable Negocio</StyledTableCell>
                <StyledTableCell>Fecha Creación</StyledTableCell>
                <StyledTableCell>Fecha actualización</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow
                    hover
                    onClick={() => handleClick(row.idactivo)}
                    role="checkbox"
                    aria-checked={isSelected(row.idactivo)}
                    tabIndex={-1}
                    key={row.idactivo}
                    selected={isSelected(row.idactivo)}
                  >
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={selected.indexOf(row.idactivo) !== -1}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{row.idactivo}</StyledTableCell>
                    <StyledTableCell>{row.clase_app}</StyledTableCell>
                    <StyledTableCell>
                      {row.elemento_configuracion}
                    </StyledTableCell>
                    <StyledTableCell>{row.nombre}</StyledTableCell>
                    <StyledTableCell>{row.nombrecmdb}</StyledTableCell>
                    <StyledTableCell>
                      {bCompania(row.idcompania)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {cprincipal(row.componente_principal)}
                    </StyledTableCell>
                    <StyledTableCell>{row.descripcion}</StyledTableCell>
                    <StyledTableCell>
                      {row.UsuarioResponsableTI}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.UsuarioResponsableNegocio}
                    </StyledTableCell>
                    <StyledTableCell>{row.fechacreacion}</StyledTableCell>
                    <StyledTableCell>{row.fechamodificacion}</StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredRows.length}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Row>
      )}
    </div>
  );
}

export default App;
