import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Findings from './Findings'
import ResumenFindings from './ResumenFindings';


const MasterFindings = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Findings" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                    <Tab label="Resumen" style={{ color: "#ffffff", padding: "3px 7px 3px"}}  />
                </Tabs>
            </AppBar>
            {value === 0 && <Findings />}
            {value === 1 && <ResumenFindings />}
        </div>
    );
};

export default MasterFindings;

