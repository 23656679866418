import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RiesgoNew from './RiesgoNew';
import AsociacionEfectos from './AsociacionEfectos'


const MainRiesgosNew = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Resumen" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                    <Tab label="Asociación Efectos" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                </Tabs>
            </AppBar>
            {value === 0 && <RiesgoNew />}
            {value === 1 && <AsociacionEfectos/>}
        </div>
    );
};

export default MainRiesgosNew;

