import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { lighten } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, TextField } from "@mui/material";
import { Tooltip, List, ListItem, ListItemText } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import {
  Check,
  Clear,
  ChevronLeft,
  ChevronRight,
  FilterList,
  FirstPage,
  LastPage,
  Search,
  ArrowDownward,
  ViewColumn,
} from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckRole from "../CheckRole.js";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import styled from "@mui/material/styles/styled";
import { tableCellClasses } from "@mui/material/TableCell";

// const tableIcons = {
//   //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//   Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   DetailPanel: forwardRef((props, ref) => (
//     <ChevronRight {...props} ref={ref} />
//   )),
//   Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//   FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//   LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//   NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//   PreviousPage: forwardRef((props, ref) => (
//     <ChevronLeft {...props} ref={ref} />
//   )),
//   ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//   Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//   SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//   ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
// };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function EditarAmenazas() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const classes = useStyles();
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event, row) => {
    setSelected(selected === row.name ? null : row[0].idcapec);
    setOpen(true);
  };
  const isSelected = (idcapec) => selected === idcapec;
  const handleClose = () => {
    setOpen(false);
    setRowData([]);
  };

  const [amenazasasociadas, setAmenazasAsociadas] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [AmenazaNombre, setAmenazaNombre] = useState("");
  const [AmenazaDescripcion, setAmenazaDescripcion] = useState("");
  const [AmenazaLink, setAmenazaLink] = useState("");

  const [abstraction, setAbstraction] = useState("");
  const [PoA, setPoA] = useState("");
  const [nombre, setNombre] = useState("");
  const [description, setDescription] = useState("");
  const [extended_description, setExtendedDescription] = useState("");
  const [likelihood_of_attack, setLikelihoodOfAttack] = useState("");
  const [linkcapec, setLinkCapec] = useState("");
  const [typical_severity, setTypicalSeverity] = useState("");
  const [mitigations, setMitigations] = useState("");
  const [example_instances, setExampleInstances] = useState("");
  const [prerequisites, setPrerequisites] = useState("");
  const [skills_required, setSkillsRequired] = useState("");
  const [resources_required, setResourcesRequired] = useState("");

  const [consecuenciascomunes, setConsecuenciasComunes] = useState([]);
  const [debilidadesasociadas, setDebilidadesAsociadas] = useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  useEffect(() => {
    const fetchamenazas = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "maestro/amenazas/" +
          localStorage.getItem("idcapec"),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let data = await result.json();
      if (data != null && data[0].amenazas_asociadas !== null) {
        setAmenazasAsociadas(data[0].amenazas_asociadas);
      }
      setAmenazaNombre(data[0].evento_amenaza);
      setAmenazaDescripcion(data[0].disp_varchar1);
      setAmenazaLink(data[0].link);
    };
    fetchamenazas();
  }, []);

  useEffect(() => {
    if (open && selected !== null) {
      const fetchmodal = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL + "maestro/amenazas/" + selected,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        let data = await result.json();
        setRowData(data);
        if (data != null && data[0].abstraction !== null) {
          setAbstraction(data[0].abstraction);
        }
        if (data != null && data[0].name !== null) {
          setNombre(data[0].name);
        }
        if (data != null && data[0].name !== null) {
          setPoA(data[0].PoA);
        }
        if (data != null && data[0].link !== null) {
          setLinkCapec(data[0].link);
        }
        if (data != null && data[0].description !== null) {
          setDescription(data[0].description);
        }
        if (data != null && data[0].extended_description !== null) {
          setExtendedDescription(data[0].extended_description);
        }
        if (data != null && data[0].likelihood_of_attack !== null) {
          setLikelihoodOfAttack(data[0].likelihood_of_attack);
        }
        if (data != null && data[0].typical_severity !== null) {
          setTypicalSeverity(data[0].typical_severity);
        }
        if (data != null && data[0].mitigations !== null) {
          setMitigations(data[0].mitigations);
        }
        if (data != null && data[0].example_instances !== null) {
          setExampleInstances(data[0].example_instances);
        }
        if (data != null && data[0].prerequisites !== null) {
          setPrerequisites(data[0].prerequisites);
        }
        if (data != null && data[0].skills_required !== null) {
          setSkillsRequired(data[0].skills_required);
        }
        if (data != null && data[0].resources_required !== null) {
          setResourcesRequired(data[0].resources_required);
        }
        if (data != null && data[0].common_consequences !== null) {
          setConsecuenciasComunes(data[0].common_consequences);
        }
        if (data != null && data[0].related_weaknesses !== null) {
          setDebilidadesAsociadas(data[0].related_weaknesses);
        }
      };
      fetchmodal();
    }
  }, [open, selected]);

  return (
    <div>
      <Row>
        <Col>
          <h2 className="subtitulo">Ver Amenazas</h2>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Id CAPEC</label>
        </Col>
        <Col sm={1} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            defaultValue={localStorage.getItem("idcapec")}
          ></input>
        </Col>
        <Col sm={1} xs={12}>
          <label className="label forn-label">Nombre</label>
        </Col>
        <Col sm={8} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={AmenazaNombre}
          ></input>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Frecuencia de Contacto</label>
        </Col>
        <Col sm={10} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={"N/A"}
          ></input>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Descripción</label>
        </Col>
        <Col sm={10} xs={12}>
          <textarea
            style={{ resize: "none", textAlign: "left" }}
            className="form-control text-center"
            rows="8"
            disabled
            value={AmenazaDescripcion}
            id="Descripcion"
          ></textarea>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col sm={12} xs={12}>
          <label className="label forn-label">
            Amenazas Asociadas (CAPEC'S)
          </label>
        </Col>
        <Col>
          <TableContainer component={Paper} className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              maxHeightBody="55vh"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow className={classes.head}>
                  <StyledTableCell padding="checkbox"></StyledTableCell>
                  <StyledTableCell>Id CAPEC</StyledTableCell>
                  <StyledTableCell>Nombre</StyledTableCell>
                  <StyledTableCell>Descripción</StyledTableCell>
                  <StyledTableCell>Probabilidad de Ataque</StyledTableCell>
                  <StyledTableCell>Severidad Típica</StyledTableCell>
                  <StyledTableCell>Link</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {amenazasasociadas.map((row) => {
                  const isItemSelected = isSelected(row[0].idcapec);
                  const labelId = `enhanced-table-checkbox-${row[0].idcapec}`;

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row[0].idcapec}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row[0].idcapec}</StyledTableCell>
                      <StyledTableCell>{row[0].name}</StyledTableCell>
                      <StyledTableCell>{row[0].description}</StyledTableCell>
                      <StyledTableCell>
                        {row[0].likelihood_of_attack}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row[0].typical_severity}
                      </StyledTableCell>
                      <StyledTableCell><a href={row[0].link} target="_blank" rel="noopener noreferrer">Ver más detalles</a></StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* Crear modal basico */}
            <Modal
              size="xl"
              show={open}
              onHide={handleClose}
              //   dialogClassName="modal-100w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Detalles de la Amenaza
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="grid-example">
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Id CAPEC</label>
                  </Col>
                  <Col sm={2} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={selected}
                    ></input>
                  </Col>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Nombre</label>
                  </Col>
                  <Col sm={6} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={nombre}
                    ></input>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Nivel de abstración 
                    </label>
                  </Col>
                  <Col sm={2} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={abstraction}
                    ></input>
                  </Col>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Ver más detalles 
                    </label>
                  </Col>
                  <Col sm={6} xs={12}>
                  <a href={linkcapec} target="_blank" rel="noopener noreferrer">
                    <input
                      style={{ backgroundColor: "white", color: "blue" }}
                      type="text"
                      className="form-control text-center font-weight-bold"
                      value="Ver más"
                      ></input>
                  </a>
                  </Col>
                </Row>
                <Row>
                <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Probabilidad de Acción (PoA)
                    </label>
                  </Col>
                  
                  <Col sm={2} xs={12}>
                    <input
                      type="text"
                      disabled
                      className="form-control text-center font-weight-bold"
                      value={PoA}
                      style={{ backgroundColor: "#a17ad4", color: "#495057" }}
                    ></input>
                  </Col>
                </Row>
                <br></br>
                {description ? (
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Descripción</label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "left" }}
                      className="form-control text-center"
                      rows="6"
                      disabled
                      value={description}
                      id="Descripcion"
                    ></textarea>
                  </Col>
                </Row>
                ): null}
                <br></br>
                {extended_description ? (
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Descripción Extendida
                    </label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "left" }}
                      className="form-control text-center"
                      rows="10"
                      disabled
                      value={extended_description}
                      id="Descripcion Extendida"
                    ></textarea>
                  </Col>
                </Row>
                ): null}
                <br></br>
                <Row>
                {likelihood_of_attack !== "" && (
                  <>
                    <Col sm={2} xs={12}>
                      <label className="label form-label">Probabilidad de ataque</label>
                    </Col>
                    <Col sm={4} xs={12}>
                      <input
                        type="text"
                        disabled
                        className="form-control text-center font-weight-bold"
                        value={likelihood_of_attack}
                        id="likelihood_of_attack"
                      />
                    </Col>
                  </>
                )}
                {typical_severity !== "" && (
                  <>
                    <Col sm={2} xs={12}>
                      <label className="label form-label">Severidad Típica</label>
                    </Col>
                    <Col sm={4} xs={12}>
                      <input
                        type="text"
                        disabled
                        className="form-control text-center font-weight-bold"
                        value={typical_severity}
                        id="Severidad Típica"
                      />
                    </Col>
                  </>
                )}
                </Row>
                <br></br>
                {mitigations ? (
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Mitigaciones</label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "left" }}
                      className="form-control text-center"
                      rows="8"
                      disabled
                      value={mitigations}
                      id="Mitigaciones"
                    ></textarea>
                  </Col>
                </Row>
                ): null}
                <br></br>
                {example_instances ? (
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Instancias de Ejemplo
                    </label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "left" }}
                      className="form-control text-center"
                      rows="7"
                      disabled
                      value={example_instances}
                      id="Instancias de Ejemplo"
                    ></textarea>
                  </Col>
                </Row>
                ): null}
                <br></br>
                {prerequisites ? (
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">Prerrequisitos</label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "left" }}
                      className="form-control text-center"
                      rows="7"
                      disabled
                      value={prerequisites}
                      id="Prerequisitos"
                    ></textarea>
                  </Col>
                </Row>
                ): null}
                <br></br>
                {skills_required ? (
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Habilidades Requeridas
                    </label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "left" }}
                      className="form-control text-center"
                      rows="4"
                      disabled
                      value={skills_required}
                      id="Habilidades Requeridas"
                    ></textarea>
                  </Col>
                </Row>
                ): null}
                <br></br>
                {resources_required ? (
                <Row>
                  <Col sm={2} xs={12}>
                    <label className="label forn-label">
                      Recursos Requeridos
                    </label>
                  </Col>
                  <Col sm={10} xs={12}>
                    <textarea
                      style={{ resize: "none", textAlign: "left" }}
                      className="form-control text-center"
                      rows="4"
                      disabled
                      value={resources_required}
                      id="Recursos Requeridos"
                    ></textarea>
                  </Col>
                </Row>
                ): null}
                <br></br>
                {consecuenciascomunes ? (
                <Row>
                  <Col sm={12} xs={12}>
                    <label className="label forn-label">
                      Consecuencias Comunes
                    </label>
                  </Col>
                  <Col>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow className={classes.head}>
                            <StyledTableCell padding="checkbox"></StyledTableCell>
                            <StyledTableCell>Consecuencias</StyledTableCell>
                            <StyledTableCell>Impacto</StyledTableCell>
                            <StyledTableCell>Notas</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {consecuenciascomunes.map((row) => (
                            <StyledTableRow>
                              <StyledTableCell padding="checkbox"></StyledTableCell>
                              <StyledTableCell>
                                <ul>
                                  {row.Scopes.map((scope) => (
                                    <li>{scope}</li>
                                  ))}
                                </ul>
                              </StyledTableCell>
                              <StyledTableCell>{row.Impacts}</StyledTableCell>
                              <StyledTableCell>{row.Notes}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Col>
                </Row>
                ): null}
                <hr />
                {debilidadesasociadas ? (
                <>
                  <Row>
                    <Col sm={12} xs={12}>
                      <label className="label forn-label">
                        Debilidades relacionadas (CWE's)
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={7} xs={12}>
                      <TableContainer
                        component={Paper}
                        className={classes.container}
                      >
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size={"medium"}
                          aria-label="enhanced table"
                          stickyHeader
                        >
                          <TableHead>
                            <TableRow className={classes.head}>
                              <StyledTableCell padding="checkbox"></StyledTableCell>
                              <StyledTableCell>ID CWE</StyledTableCell>
                              <StyledTableCell>Link</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {debilidadesasociadas.map((row) => (
                              <StyledTableRow>
                                <StyledTableCell padding="checkbox"></StyledTableCell>
                                <StyledTableCell>{row.CWE_ID}</StyledTableCell>
                                <StyledTableCell>
                                  <a
                                    href={row.Link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Ver más detalles
                                  </a>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Col>
                  </Row>
                </>
                ): null}
                <hr />
              </Modal.Body>
            </Modal>
          </TableContainer>
        </Col>
      </Row>
    </div>
  );
}

export default EditarAmenazas;
