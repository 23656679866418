import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Alert } from "react-bootstrap";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import AADService from "../funcionesAuth.js";


function AlertDismissibleExample({ alerta }) {
    switch (alerta) {
        case 1:
            return <Alert variant="warning">Alerta</Alert>;
            break;
        case 2:
            return <Alert variant="success">Guardó exitosamente el nuevo usuario</Alert>;
            break;
        case 3:
            return <Alert variant="danger"></Alert>;
            break;
        case 4:
            return <Alert variant="warning">Error al enviar la información, por favor verificar los datos del usuario</Alert>;
            break;
        case 5:
            return <Alert variant="danger">Error en el servidor</Alert>;
            break;
        case 6:
            return (
                <Alert variant="warning">
                    Ya existe una evaluación para el activo seleccionado
                </Alert>
            );
            break;
        default:
            return <p></p>;
            break;
    }
}


var ListSelect = [
    {
        value: "1",
        label: "Agregar Usuario"
    },
    {
        value: "2",
        label: "Editar Usuario"
    },
    {
        value: "3",
        label: "Consultar Usuario"
    }
]

function CrearUsuario() {
    const [companiaDat, setCompaniaDat] = React.useState([]);
    const [selectedValueCompania, setSelectedValueCompania] = useState(null);
    const [selectedValueRol, setSelectedValueRol] = useState(null);
    const [selectedValueOpcion, setSelectedValueOpcion] = useState("");
    const [validated, setValidated] = useState(false);
    const [estadoPOST, setEstadoPost] = React.useState(0);
    const [dataConsultar, setDataConsultar] = useState([]);
    const [viewConsulta, SetViewConsulta] = React.useState(false);
    const [ListRol, setListRol] = React.useState([]);
    const AzureADService = new AADService();
    const token = AzureADService.getToken();

    useEffect(() => {
        const obtenerCompañias = async () => {
            const result = await fetch(
                process.env.REACT_APP_API_URL + "general/compania/",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    },
                }
            );

            let datCompania = await result.json();
            let companiaSelect = [];
            companiaSelect = datCompania.map(
                ({ idcompania: value, compania: label }) => ({
                    value,
                    label,
                })
            );

            setCompaniaDat(companiaSelect);

        };
        const Usuario = async () =>{
            const result = await fetch(
                process.env.REACT_APP_API_URL + "roles/1/",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                        },
                    }
                );
                let data = await result.json();
                let roles = data.map(
                    ({ idrol: value, nombre_rol: label }) => ({
                        value,
                        label,
                    })
                );
                setListRol(roles);
        };
        Usuario();
        obtenerCompañias();
    }, []);


    const sendDataCrear = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setEstadoPost(4);

        } else {
            var data = JSON.stringify({
                email: document.getElementById("EmailUsuario").value,
                nombre: document.getElementById("NombreUsuario").value.toUpperCase(),
                idcompania: parseInt(selectedValueCompania.value),
                idrol: parseInt(selectedValueRol.value),
                estado_usuario: 1,
                estado_login: 1
            });
            fetch(process.env.REACT_APP_API_URL + "administrador/agregar_usuario/0/", {
                method: "POST",
                body: data,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    //Authorization: "Bearer " + token,
                },
            })
                .then((data) =>
                    data.json().then((response) => {
                        if (data.status >= 200 && data.status < 300) {
                            setEstadoPost(2);
                        } else if (data.status >= 500) {
                            setEstadoPost(4);
                        } else if (data.status >= 400 && data.status < 500) {
                            setEstadoPost(4);
                        }
                    })
                )
                .catch(function (err) { });
        }
        setValidated(true);

    }

    const sendDataEditar = (event) => {
        event.preventDefault();
        const timeElapsed = Date.now();
        const today = new Date(timeElapsed);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setEstadoPost(4);
        } else {
            var data = JSON.stringify({
                email: document.getElementById("EmailUsuario").value,
                idrol: parseInt(selectedValueRol.value),
                fechamodificacion: today.toISOString(),
                idusuariomodificacion: parseInt(localStorage.getItem("idusuario")),
            });
            fetch(process.env.REACT_APP_API_URL + "administrador/agregar_usuario/0/", {
                method: "PUT",
                body: data,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    //Authorization: "Bearer " + token,
                },
            })
                .then((data) =>
                    data.json().then((response) => {
                        if (data.status >= 200 && data.status < 300) {
                            setEstadoPost(2);
                        } else if (data.status >= 500) {
                            setEstadoPost(4);
                        } else if (data.status >= 400 && data.status < 500) {
                            setEstadoPost(4);
                        }
                    })
                )
                .catch(function (err) { });
        }
        setValidated(true);
    }
    const sendDataBuscar = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        SetViewConsulta(false)
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setEstadoPost(4);
        } else {
            const fetchdataConsulta = async () => {
                const result = await fetch(
                    process.env.REACT_APP_API_URL +
                    "administrador/agregar_usuario/" + document.getElementById("EmailUsuario").value + "/",
                    {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            //Authorization: "Bearer " + token,
                        },
                    })
                let data = ""
                try {
                    data = await result.json();
                    setDataConsultar(data);
                    setEstadoPost(2);

                    SetViewConsulta(true)
                } catch {
                    setEstadoPost(4);
                }


            };

            fetchdataConsulta();
        }
        setValidated(true);
    }

    const handleChange = (e) => {
        setSelectedValueCompania({ value: e.value, label: e.label });
    };

    const handleChangeRol = (e) => {
        setSelectedValueRol({ value: e.value, label: e.label });
    };
    const MySelect = (props) => (
        <Select
            {...props}
            className="texto"
            options={props.options}
            placeholder={props.placeholder}
        />
    );

    const selectChange = (e) => {
        setSelectedValueOpcion({ value: e.value, label: e.label })
        setValidated(false);
        setEstadoPost(0);
        SetViewConsulta(false)
    }


    return (
        <>
            <AlertDismissibleExample alerta={estadoPOST} />
            <div>
                < Row >
                    <br />
                    <Col>
                        <h2 className="titulo">Usuarios</h2>
                    </Col>
                </Row>
                <hr />
                <br />
                <Row className="mb-3 " style={{ justifyContent: "center" }}>
                    <Col sm={6}>
                        <h4 className="titulo">Seleccione la acción que desea hacer</h4>
                    </Col>
                    <Col sm={4} xs={10}>
                        <MySelect
                            className="texto"
                            placeholder="Seleccione una opción"
                            options={ListSelect}
                            onChange={selectChange}
                            defaultValue={selectedValueOpcion}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor seleccione una compañía.
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <br />
                <br />
                {selectedValueOpcion.value == 1 ?
                    <>
                        < Row >
                            <br />
                            <Col>
                                <h2 className="titulo">Crear Usuario</h2>
                            </Col>
                        </Row>
                        <hr />
                        <br />
                        <Form
                            id="formData"
                            onSubmit={(e) => sendDataCrear(e)}
                            noValidate
                            validated={validated}
                        >
                            <Row className="mb-3">
                                <Col sm={4} xs={12}>
                                    <label className="label form-label">
                                        Nombre del Usuario*
                                    </label>
                                </Col>
                                <Col sm={6} xs={12}>
                                    <input
                                        type="text"
                                        className="form-control text-center"
                                        placeholder="Nombre del Usuario"
                                        required
                                        id="NombreUsuario"
                                    ></input>
                                    <Form.Control.Feedback type="invalid">
                                        Por favor introduzca el nombre del usuario.
                                    </Form.Control.Feedback>

                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-3">
                                <Col sm={4} xs={12}>
                                    <label className="label form-label">
                                        Email del Usuario*
                                    </label>
                                </Col>
                                <Col sm={6} xs={12}>
                                    <input
                                        type="text"
                                        className="form-control text-center"
                                        placeholder="Email del Usuario"
                                        required
                                        id="EmailUsuario"
                                    ></input>
                                    <Form.Control.Feedback type="invalid">
                                        Por favor introduzca el Email del Usuario.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-3 ">
                                <Col sm={4} xs={12}>
                                    <label className="label forn-label">Compañía*</label>
                                </Col>
                                <Col sm={4} xs={10}>
                                    <MySelect
                                        className="texto"
                                        onChange={handleChange}
                                        options={companiaDat}
                                        defaultValue={selectedValueCompania}
                                        placeholder="Seleccione una compañia"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Por favor seleccione una compañía.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-3 ">
                                <Col sm={4} xs={12}>
                                    <label className="label forn-label">Rol*</label>
                                </Col>
                                <Col sm={3} xs={10}>
                                    <MySelect
                                        className="texto"
                                        onChange={handleChangeRol}
                                        options={ListRol}
                                        defaultValue={selectedValueRol}
                                        placeholder="Seleccione un Rol"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Por favor seleccione un rol.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-3">
                                <Col sm={4} xs={12}>
                                    <label className="label forn-label">Estado usuario</label>
                                </Col>
                                <Col sm={2} xs={12}>
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control text-center font-weight-bold"
                                        defaultValue="1"
                                        id="EstadoUsuario"
                                    ></input>
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-3">
                                <Col sm={4} xs={12}>
                                    <label className="label forn-label">Estado Login</label>
                                </Col>
                                <Col sm={2} xs={12}>
                                    <input
                                        type="text"
                                        disabled
                                        className="form-control text-center font-weight-bold"
                                        defaultValue="1"
                                        id="EstadoLogin"
                                    ></input>
                                </Col>
                            </Row>
                            <br /> <br />
                            <Row className="mb-3" style={{ justifyContent: "center" }}>
                                <Col sm={3} xs={3}>
                                    <Button type="submit" className="botonPositivo" id="send">
                                        Guardar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </> :
                    selectedValueOpcion.value == 2 ?
                        <>
                            <Form
                                id="formData"
                                onSubmit={(e) => sendDataEditar(e)}
                                noValidate
                                validated={validated}
                            >
                                < Row >
                                    <br />
                                    <Col>
                                        <h2 className="titulo">Editar Usuario</h2>
                                    </Col>
                                </Row>
                                <hr />
                                <br />
                                <Row className="mb-3">
                                    <Col sm={4} xs={12}>
                                        <label className="label form-label">
                                            Email del Usuario*
                                        </label>
                                    </Col>
                                    <Col sm={6} xs={12}>
                                        <input
                                            type="text"
                                            className="form-control text-center"
                                            placeholder="Email del Usuario"
                                            // defaultValue={}
                                            required
                                            id="EmailUsuario"
                                        ></input>
                                        <Form.Control.Feedback type="invalid">
                                            Por favor introduzca el Email del Usuario.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <br />
                                <Row className="mb-3 ">
                                    <Col sm={4} xs={12}>
                                        <label className="label forn-label">Rol*</label>
                                    </Col>
                                    <Col sm={3} xs={10}>
                                        <MySelect
                                            className="texto"
                                            onChange={handleChangeRol}
                                            options={ListRol}
                                            defaultValue={selectedValueRol}
                                            placeholder="Seleccione un Rol"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Por favor seleccione un rol.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <br />
                                <Row className="mb-3" style={{ justifyContent: "center" }}>
                                    <Col sm={3} xs={3}>
                                        <Button type="submit" className="botonPositivo" id="send">
                                            Guardar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </> :
                        selectedValueOpcion.value == 3 ?
                            <>
                                <Form
                                    id="formData"
                                    onSubmit={(e) => sendDataBuscar(e)}
                                    noValidate
                                    validated={validated}
                                >
                                    < Row >
                                        <br />
                                        <Col>
                                            <h2 className="titulo">Consultar Usuario</h2>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <br />
                                    <Row className="mb-3">
                                        <Col sm={4} xs={12}>
                                            <label className="label form-label">
                                                Email del Usuario*
                                            </label>
                                        </Col>
                                        <Col sm={6} xs={12}>
                                            <input
                                                type="text"
                                                className="form-control text-center"
                                                placeholder="Email del Usuario"
                                                required
                                                id="EmailUsuario"
                                            ></input>
                                            <Form.Control.Feedback type="invalid">
                                                Por favor introduzca el Email del Usuario.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row className="mb-3" style={{ justifyContent: "center" }}>
                                        <Col sm={3} xs={3}>
                                            <Button type="submit" className="botonPositivo" id="send">
                                                Buscar
                                            </Button>
                                        </Col>
                                    </Row>

                                </Form>
                            </> :
                            ""
                }
            </div>
            <div>
                {viewConsulta ?
                    <>
                        <br />
                        <hr />
                        <br />
                        <Row className="mb-3">
                            <Col sm={4} xs={12}>
                                <label className="label forn-label">ID Usuario</label>
                            </Col>
                            <Col sm={3} xs={12}>
                                <input
                                    type="text"
                                    disabled
                                    className="form-control text-center font-weight-bold"
                                    defaultValue={dataConsultar.idusuario}
                                    id="IDusuario"
                                ></input>
                            </Col>
                        </Row>
                        <br />

                        <Row className="mb-3">
                            <Col sm={4} xs={12}>
                                <label className="label forn-label">Nombre del Usuario</label>
                            </Col>
                            <Col sm={6} xs={12}>
                                <input
                                    type="text"
                                    disabled
                                    className="form-control text-center font-weight-bold"
                                    defaultValue={dataConsultar.nombre}
                                    id="Nombreusuario"
                                ></input>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mb-3">
                            <Col sm={4} xs={12}>
                                <label className="label forn-label">Email del Usuario</label>
                            </Col>
                            <Col sm={6} xs={12}>
                                <input
                                    type="text"
                                    disabled
                                    className="form-control text-center font-weight-bold"
                                    defaultValue={dataConsultar.email}
                                    id="Emailusuario"
                                ></input>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mb-3">
                            <Col sm={2} xs={12}>
                                <label className="label forn-label">Rol</label>
                            </Col>
                            <Col sm={4} xs={12}>
                                <input
                                    type="text"
                                    disabled
                                    className="form-control text-center font-weight-bold"
                                    defaultValue={dataConsultar.idrol}
                                    id="rol"
                                ></input>
                            </Col>
                            <Col sm={2} xs={12}>
                                <label className="label forn-label">Compañia</label>
                            </Col>
                            <Col sm={4} xs={12}>
                                <input
                                    type="text"
                                    disabled
                                    className="form-control text-center font-weight-bold"
                                    defaultValue={dataConsultar.idcompania}
                                    id="compania"
                                ></input>
                            </Col>
                        </Row>
                    </>
                    : ""
                }
            </div>
        </>
    );

};
export default CrearUsuario;