import React, { useState } from 'react';
import { Button, Row, Col, Form } from "react-bootstrap";
import AADService from '../funcionesAuth';
import Select from "react-select";

export default function Adjuntos() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [idElemento, setIdElemento] = useState(0);
    const [elemento, setElemento] = useState('');
    const [fileExists, setFileExists] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [fileName, setFileName] = useState(null);
    const AzureADService = new AADService();
    const token = AzureADService.getToken();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleCheckFile = async () => {
        const url = `${process.env.REACT_APP_API_URL}administrador/adjuntos/${elemento}/${idElemento}/`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': "Bearer " + token,
                },
            });
            if (response.ok) {
                setFileExists(true);
                const blob = await response.blob();
                const contentDisposition = response.headers.get('Content-Disposition');
                const fileNameMatch = contentDisposition && contentDisposition.match(/filename="([^"]+)"/);
                const fileName = fileNameMatch ? fileNameMatch[1] : `${idElemento}_${elemento}`;
                const fileNameEncoded = encodeURIComponent(fileName);
                const downloadUrl = window.URL.createObjectURL(blob);
                setFileUrl(downloadUrl);
                setFileName(fileNameEncoded);
            } else {
                setFileExists(false);
                setFileUrl(null);
                console.error('File not found');
            }
        } catch (error) {
            console.error('Error checking file existence:', error);
        }
    };
    const allowedExtensions = ['.pdf', '.docx', '.xlsx', '.jpg', '.png', '.msg'];

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Seleccione un archivo por favor');
            return;
        }
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        //if (!allowedExtensions.includes('.' + fileExtension)) {
        //    alert('El tipo de archivo no está permitido. Por favor seleccione un archivo con alguna de las siguientes extensiones: ' + allowedExtensions.join(', '));
        //    return;
        //}

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const method = fileExists ? 'PUT' : 'POST';
            const url = `${process.env.REACT_APP_API_URL}administrador/adjuntos/${elemento}/${idElemento}/`;

            const response = await fetch(url, {
                method: method,
                body: formData,
                headers: {
                    'Authorization': "Bearer " + token,
                },
            });

            if (response.ok) {
                console.log('File uploaded/replaced successfully');
                //handleCheckFile();
                alert('File uploaded/replaced successfully');
            } else {
                console.error('Upload/Replace failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDelete = async () => {
        const url = `${process.env.REACT_APP_API_URL}administrador/adjuntos/${elemento}/${idElemento}/`;
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': "Bearer " + token,
                },
            });
            if (response.ok) {
                console.log('File deleted successfully');
                setFileExists(false);
                setFileUrl(null);
            } else {
                console.error('Delete failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSelectChange = (selectedOption) => {
        setElemento(selectedOption.value);
    };


    return (
        <div>
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <Form.Label>Selecciona un elemento</Form.Label>
                </Col>
                <Col sm={2} xs={12}>
                    <Select
                        options={[
                            { value: 'ERO', label: 'ERO' },
                            { value: 'Decisiones', label: 'Decisiones' },
                            { value: 'VultrackerxERO', label: 'VultrackerxERO' },
                            { value: 'Ontologia', label: 'Ontologia' },
                        ]}
                        onChange={handleSelectChange}
                    />
                </Col>
                <Col sm={3} xs={12}>
                    <Form.Control 
                        type='text'
                        placeholder='Digite Nombre'
                        onChange={(e) => setIdElemento(e.target.value)}
                    />
                </Col>
                <Col sm={2} xs={12}>
                    <Button onClick={handleCheckFile}>Check File</Button>
                </Col>
            </Row>
            {fileExists && (
                <Row className="mb-3">
                    <Col sm={2} xs={12}>
                        <Button onClick={handleDelete} variant="danger">Delete</Button>
                    </Col>
                    <Col sm={10} xs={12}>
                        {fileUrl && (
                            <a href={fileUrl} download={fileName}>
                                {decodeURIComponent(fileName)}
                            </a>
                        )}
                    </Col>
                </Row>
            )}
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <Form.Control type='file' onChange={handleFileChange} />
                </Col>
                <Col sm={2} xs={12}>
                    <Button onClick={handleUpload}>
                        {fileExists ? 'Replace' : 'Upload'}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
