import React, { useEffect, useState } from 'react';
import Cuestionario from './NewCuestionario';
import AdvertenciaModal from './AdvertenciaModal';
import AADService from "../funcionesAuth.js";

function NuevoCuestionarioEvaluacion() {
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const [activeTab, setActiveTab] = useState(0);
  const [mostrarAdvertencia, setMostrarAdvertencia] = useState(false);
  const [noActualizado, setNoActualizado] = useState(true);
  const [cuestionario, setCuestionario] = React.useState(false);
  const [datosCuestionarioAntiguo, setDatosCuestionarioAntiguo] = useState({
    existeTipoDespCanal: false,
    rutaTipos: "",
    titulo: "Antiguo",
    proveedor: [],
    cuestionarioExist: false
  });
  const [datosCuestionarioNuevo, setDatosCuestionarioNuevo] = useState({
    existeTipoDespCanal: false,
    rutaTipos: "",
    titulo: "Nuevo",
    proveedor: [],
    cuestionarioExist: false
  });
  const dataStorageDespliegue = localStorage.getItem("variable_tipo_despliegue");
  const dataStorageTipoCanal = localStorage.getItem("variable_tipo_canal");
  let rutaGetTipos_old = localStorage.getItem("variable_old_tipo_activo") + "/";
  let rutaGetTipos = ""
  if (rutaGetTipos_old === "null/") {
    rutaGetTipos = localStorage.getItem("variable_tipo_activo") + "/";
  }
  else {
    rutaGetTipos = rutaGetTipos_old
  }

  useEffect(() => {
    cuestionarioExist();
    //Si variable_old_tipo_activo existe en el local storage, entonces no se ha actualizado el activo
    if (localStorage.getItem("variable_old_tipo_activo")) {
      setNoActualizado(false);
    }
  }, []);

  const cambiarPestana = () => {
    // Realiza el cambio de pestaña
    setActiveTab(1);
    // Cierra el modal de advertencia
    setMostrarAdvertencia(false);
  };


  const handleChangeTab = (event, newValue) => {
    if (newValue === 1 && noActualizado==false) {
      setMostrarAdvertencia(true);
    }
    else {
      setActiveTab(newValue);
    }
    if (newValue === 0) {
      cuestionarioExist();
      setActiveTab(newValue);
    }
  };

  const mapeLetrasInicialesDespliegue = (dataDespliegue) => {
    const palabras = dataDespliegue.split(",");
    const iniciales = palabras.map((palabra) => palabra[0]);
    return iniciales.join('');
  };

  const cuestionarioExist = async () => {
    if (dataStorageDespliegue && dataStorageTipoCanal) {
      // Lógica para obtener datos del cuestionario nuevo
      let rutaGetTipos2 = localStorage.getItem("variable_tipo_activo") + "/";
      const inicialesDespl = mapeLetrasInicialesDespliegue(dataStorageDespliegue);
      const rutaGetTiposNew = rutaGetTipos2 + inicialesDespl + "/" + dataStorageTipoCanal + "/";
      const resultNew = await fetch(
        process.env.REACT_APP_API_URL + "evaluacion/newcuestionarioNew/" + localStorage.getItem("idevaluacion") +
        "/" + rutaGetTiposNew,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (resultNew.status >= 200 && resultNew.status <= 300) {
        setCuestionario(true);
        const dataNew = await resultNew.json();
        setDatosCuestionarioNuevo({
          existeTipoDespCanal: true,
          rutaTipos: rutaGetTiposNew,
          titulo: 'Nuevo',
          proveedor: dataNew,
          cuestionarioExist: true
        });
      } else {
        const resultNew2 = await fetch(
          process.env.REACT_APP_API_URL + "evaluacion/cuestionariotactNew/" + rutaGetTiposNew,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (resultNew2.status >= 200 && resultNew2.status <= 300) {
          setCuestionario(false);
          const param = await resultNew2.json();
          setDatosCuestionarioNuevo({
            existeTipoDespCanal: true,
            rutaTipos: rutaGetTiposNew,
            titulo: 'Nuevo',
            proveedor: param,
            cuestionarioExist: true
          });
        }
      }
    }
  }

  return (
    <div>
      {/* Modal de advertencia */}
      <AdvertenciaModal
        mostrar={mostrarAdvertencia}
        onClose={() => setMostrarAdvertencia(false)}
        onConfirm={cambiarPestana}
      />

      {/* Renderizar el mismo componente en ambas pestañas */}
      {activeTab === 1 && (
        <Cuestionario 
          existeTipoDespCanal={datosCuestionarioAntiguo.existeTipoDespCanal}
          rutaTipos={datosCuestionarioAntiguo.rutaTipos}
          titulo={datosCuestionarioAntiguo.titulo}
          proveedor={datosCuestionarioAntiguo.proveedor}
          cuestionario_exist={datosCuestionarioAntiguo.cuestionarioExist} 
        />
      )}

      {activeTab === 0 && datosCuestionarioNuevo.existeTipoDespCanal && (
        <Cuestionario
          existeTipoDespCanal={datosCuestionarioNuevo.existeTipoDespCanal}
          rutaTipos={datosCuestionarioNuevo.rutaTipos}
          titulo={datosCuestionarioNuevo.titulo}
          proveedor={datosCuestionarioNuevo.proveedor}
          cuestionario_exist={cuestionarioExist}
          cuestionario = {cuestionario}
        />
      )}

      {/* Mensaje de error si no se cumplen las condiciones */}
      {activeTab === 0 && !datosCuestionarioNuevo.existeTipoDespCanal && (
        <div>
          <p className='label forn-label' style={{ color: 'red' }}>
            Es necesario que al activo a evaluar se le asignen los atributos de tipo despliegue y canal para visualizar el cuestionario nuevo.
          </p>
        </div>
      )}
    </div>
  );
}

export default NuevoCuestionarioEvaluacion;
