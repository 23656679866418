import { Button, Row, Col, Modal, Form, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import AADService from "../../funcionesAuth.js";
import BarChartComponent from "./BarChartComponent";
import HeatMapChartComponent from "./HeatMapChartComponent";
import CircularProgress from "@mui/material/CircularProgress";
import ModalHelp from "./ModalHelp";
function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="danger"></Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    default:
      return <p></p>;
      break;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  columdetalles: {
    width: "33.33%",
  },
  paper: {
    backgroundColor: "white",
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "55vh",
    minHeight: "55vh",
  },
}));

function NuevosRiesgos() {
  const [estadoPOST, setEstadoPost] = React.useState(0);
  const [vulconteo, setVulConteo] = React.useState([]);
  const [heatmapC, setHeatmapC] = React.useState({});
  const [heatmapI, setHeatmapI] = React.useState({});
  const [heatmapD, setHeatmapD] = React.useState({});
  const [dataRiesgos, setDataRiesgos] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [mesagge, setMesagge] = React.useState(false);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  useEffect(() => {
    const fetchRiesgos = async () =>{
      setLoading(true);
      const result = await fetch(
        process.env.REACT_APP_API_URL + "evaluacion/newriesgos/"
        + localStorage.getItem("idevaluacion") + "/",
        {
          method: "GET",
          headers:{
            Accept:"application/json",
            Authorization: "Bearer " + token,
          }
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let data = await result.json();
        setDataRiesgos(data);
        setLoading(false);
      } else if (result.status >= 400 && result.status <= 500) {
        setDataRiesgos([]);
        setLoading(false);
        //setEstadoPost(5);
      }
    }
    const fetchOntologia = async () => {
      setLoading(true);
      setMesagge(true)
      const result = await fetch(
        process.env.REACT_APP_API_URL + "evaluacion/ontologia/"
        + localStorage.getItem("idevaluacion"),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let data = await result.json();
        setVulConteo(data["conteo_vul"]);
        setHeatmapC(data["heatmap"]["confidencialidad"]);
        setHeatmapI(data["heatmap"]["integridad"]);
        setHeatmapD(data["heatmap"]["disponibilidad"]);
        setLoading(false);
      } else {
        setVulConteo({});
        setHeatmapC({});
        setHeatmapI({});
        setHeatmapD({});
        setEstadoPost(5);
        setMesagge(false);
      }
    };
    fetchRiesgos();
    fetchOntologia();
  }, []);


  return (
    <div>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <>
            <AlertDismissibleExample alerta={estadoPOST} />
            <br />
            {dataRiesgos.map((riesgo, index) => (
              <div
                key={index}
                style={{ border: "2px solid black", padding: "10px" }}
              >
                <Row className="row_n_riesgo">
                  <Col md={6}>
                    <h2 className="estilo_titulos">
                      {riesgo.caracteristica_seguridad}
                    </h2>
                  </Col>
                </Row>
                <Row className="row_n_riesgo">
                  <Col md={6}>
                    <label className="label">Descripción:</label>
                  </Col>
                  <Col md={8}>
                    <label className="label">{riesgo.descripcion}</label>
                  </Col>
                </Row>
                <br></br>
                <Row className="row_n_riesgo">
                  <Col md={3}>
                    <label className="label">Riesgo inherente</label>
                  </Col>
                  <Col md={3}>
                    <label className="texto">
                      {riesgo.exposicioninherentep95}
                    </label>
                  </Col>
                  <Col md={3}>
                    <label className="texto">{riesgo.nivelriesgo}</label>
                  </Col>
                </Row>
                <Row className="row_n_riesgo">
                  <Col md={3}>
                    <label className="label">Efectividad</label>
                  </Col>
                  <Col md={3}>
                    <label className="texto">
                      {riesgo.efectividadcontrol}%
                    </label>
                  </Col>
                </Row>
                <Row className="row_n_riesgo">
                  <Col md={3}>
                    <label className="label">Riesgo residual</label>
                  </Col>
                  <Col md={3}>
                    <label className="texto">{riesgo.exposicionresidual}</label>
                  </Col>
                </Row>
                <Row className="row_n_riesgo">
                  <Col md={6}>
                    <h4 className="estilo_titulos">Vulnerabilidad y Amenaza</h4>
                    <ModalHelp />
                    <Row className="mb-3">
                      {mesagge ? (
                        <div>
                          {riesgo.caracteristica_seguridad ===
                            "Confidencialidad" && (
                            <HeatMapChartComponent
                              datos={
                                Object.keys(heatmapC).length ? heatmapC : {}
                              }
                            />
                          )}
                          {riesgo.caracteristica_seguridad === "Integridad" && (
                            <HeatMapChartComponent
                              datos={
                                Object.keys(heatmapI).length ? heatmapI : {}
                              }
                            />
                          )}
                          {riesgo.caracteristica_seguridad ===
                            "Disponibilidad" && (
                            <HeatMapChartComponent
                              datos={
                                Object.keys(heatmapD).length ? heatmapD : {}
                              }
                            />
                          )}
                        </div>
                      ) : (
                        <div className="alert alert-warning">
                          <p>No hay información de vulnerabilidades con CVE</p>
                        </div>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row className="row_n_riesgo">
                  <Col md={6}>
                    <h4 className="estilo_titulos">Frecuencia del evento</h4>
                  </Col>
                </Row>
                <Row className="row_n_riesgo">
                  <Col md={6}>
                    <h4 className="estilo_titulos">Impactos</h4>
                  </Col>
                </Row>
              </div>
            ))}

            <Row className="row_n_riesgo">
              <h2 className="estilo_titulos">Conteo por Vulnerabilidad Tipo</h2>
              <ModalHelp />
            </Row>
            <Row>
              <Col>
                <BarChartComponent data={vulconteo.length ? vulconteo : []} />
              </Col>
            </Row>
          </>
        </div>
      )}
    </div>
  );
}
export default NuevosRiesgos;
