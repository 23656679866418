import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, TableContainer, Paper, Table, TableHead, TableRow, TableBody } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import AADService from "../../funcionesAuth.js";

const useStyles = makeStyles({
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 650,
  },
  head: {
    backgroundColor: '#f5f5f5',
  },
});

const ModalFinding = ({ open, setOpen, selected }) => {
  const classes = useStyles();
  const [finding, setFinding] = useState(null);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  useEffect(() => {
    if (selected) {
      const fetchFinding = async () => {
        const result = await fetch(
          process.env.REACT_APP_API_URL + "maestro/finding/" + selected + "/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (result.ok) {
          const data = await result.json();
          setFinding(data);
        } else {
          setFinding(null);
        }
      };
      fetchFinding();
    }
  }, [selected]);

  const handleClose = () => setOpen(false);

  return (
    <Modal size="xl" show={open} onHide={handleClose} aria-labelledby="modal-finding-title">
      <Modal.Header closeButton>
        <Modal.Title id="modal-finding-title">Detalle de la Vulnerabilidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {finding ? (
          <>
            <Row>
              <Col sm={3}>
                <label>ID:</label>
                <input type="text" className="form-control" disabled value={finding.id} />
              </Col>
              <Col sm={9}>
                <label>Título:</label>
                <input type="text" className="form-control" disabled value={finding.title} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={3}>
                <label>Estado:</label>
                <input type="text" className="form-control" disabled value={finding.display_status} />
              </Col>
              <Col sm={3}>
                <label>Fecha:</label>
                <input type="text" className="form-control" disabled value={finding.date} />
              </Col>
              <Col sm={3}>
                <label>SLA Expiration:</label>
                <input type="text" className="form-control" disabled value={finding.sla_expiration_date} />
              </Col>
              <Col sm={3}>
                <label>Componente:</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={`${finding.component_name} ${finding.component_version}`}
                />
              </Col>
            </Row>              
            <Row>
              <Col sm={3}>
                <label>CVSS Score:</label>
                <input type="text" className="form-control" disabled value={finding.cvssv3_score} />
              </Col>
              <Col sm={3}>
                <label>Severidad:</label>
                <input type="text" className="form-control" disabled value={finding.severity} />
              </Col>
              <Col sm={3}>
                <label>EPS Score:</label>
                <input type="text" className="form-control" disabled value={finding.epss_score} />
              </Col>
              <Col sm={3}>
                <label>EPS Percentile:</label>
                <input type="text" className="form-control" disabled value={finding.epss_percentile} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12}>
                <label>Descripción:</label>
                <textarea
                  className="form-control"
                  rows="4"
                  disabled
                  value={finding.description || ""}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12}>
                <label>Mitigación:</label>
                <textarea
                  className="form-control"
                  rows="3"
                  disabled
                  value={finding.mitigation || ""}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12}>
                <label>Impacto:</label>
                <textarea
                  className="form-control"
                  rows="4"
                  disabled
                  value={finding.impact || ""}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12}>
                <label>Referencias:</label>
                <textarea
                  className="form-control"
                  rows="5"
                  disabled
                  value={finding.references || ""}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={4}>
                <label>CVE:</label>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={
                    finding.vulnerability_ids && finding.vulnerability_ids.length
                      ? finding.vulnerability_ids[0].vulnerability_id
                      : ""
                  }
                />
              </Col>
            </Row>
          </>
        ) : (
          <div>No se encontró la información de la vulnerabilidad.</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalFinding;
