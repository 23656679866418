import React, { useEffect } from "react";
import * as echarts from "echarts/core";
import { DatasetComponent, GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([DatasetComponent, GridComponent, BarChart, CanvasRenderer]);

const LoadingComponent = () => {
    useEffect(() => {
    var chartDom = document.getElementById("main");
    var myChart = echarts.init(chartDom);
    var option = {
        graphic: {
            elements: [
            {
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                text: 'GRC CIBER',
                fontSize: 80,
                fontWeight: 'bold',
                lineDash: [0, 200],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#000',
                lineWidth: 1
                },
                keyframeAnimation: {
                duration: 3000,
                loop: true,
                keyframes: [
                    {
                    percent: 0.7,
                    style: {
                        fill: 'transparent',
                        lineDashOffset: 200,
                        lineDash: [200, 0]
                    }
                    },
                    {
                    // Stop for a while.
                    percent: 0.8,
                    style: {
                        fill: 'transparent'
                    }
                    },
                    {
                    percent: 1,
                    style: {
                        fill: 'black'
                    }
                    }
                ]
                }
            }
            ]
        }
        };
    myChart.setOption(option);

    return () => {
    myChart.dispose();
    
    };
    }, []);

return <div id="main" style={{ width: "900px", height: "600px" }}></div>;
};

export default LoadingComponent;
