import React, { useEffect, useState } from "react";
import { Checkbox, makeStyles } from "@material-ui/core";
import swal from "sweetalert2";
import { Row, Col, Form, Alert, Modal } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import {
  Box,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ChevronLeft, ChevronRight, Search } from "react-bootstrap-icons";
import {
  ArrowDownward,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AADService from "../funcionesAuth.js";

import CheckRole from "../CheckRole.js";
function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;
      break;
    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;
      break;
    case 3:
      return <Alert variant="success">Editado exitosamente</Alert>;
      break;
    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;
      break;
    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;
      break;
    case 6:
      return (
        <Alert variant="warning">
          Ya existe una evaluación para el activo seleccionado
        </Alert>
      );
      break;
    default:
      return <p></p>;
      break;
  }
}
const EditarOpcion = ({ opcionToEdit }) => {
    const check = new CheckRole("/EditarOpcion");

    const [optionsData, setOptionsData] = useState([]);
    const [tableData, setTableData] = useState(null);
    const [parentOptions, setOpciones] = useState([]);
    const [opcionesGenerales, setOpcionesGenerales] = useState([]);

    const [idOpcion, setId] = useState("");
    const [nombreOpcion, setNombreOpcion] = useState("");
    const [padre, setPadre] = useState("");
    const [orden, setOrden] = useState("");
    const [urlOpcion, setUrlOpcion] = useState("");
    const [menuType, setMenuType] = useState("");
    const [estadoPOST, setEstadoPost] = React.useState(0);
    const AzureADService = new AADService();
    const token = AzureADService.getToken();

  const handleChangePermissions = (e, rol, key) => {
    setTableData(
      tableData.map((roles) => {
        if (roles.idopcion === rol.idopcion) {
          return { ...roles, [key]: e.target.checked };
        } else {
          return roles;
        }
      })
    );
  };

  const handleDeleteOption = (rowData) => {
    setOptionsData(
      optionsData.filter((option) => option.idopcion !== rowData.idopcion)
    );
  };

  const handleSave = async () => {
    if (!nombreOpcion || !orden || !menuType) {
      swal.fire("Error", "Todos los campos son obligatorios", "error");
      return;
    }

    const updatedOption = {
      id: opcionToEdit.id, // Asegúrate de incluir el ID de la opción a editar
      nombreOpcion,
      orden,
      urlOpcion,
      padre,
      menuType,
    };

  };

  useEffect(() => {
    const getOptionTableData = async () => {
        const result = await fetch(process.env.REACT_APP_API_URL + `rolesId/`+ 
          localStorage.getItem("idopcion") + "/"
        , {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (result.status >= 200 && result.status <= 300) {
            const data = await result.json();
            let filteredData = data.filter((item) => item.padre === 0 || item.idopcion === 12);
                setOpciones(filteredData);
            let filteredData2 = data.filter((item) => item.idopcion === parseInt(localStorage.getItem("idopcion")));
            if (filteredData2 && filteredData2[0]) {
                const mopcion = filteredData2[0];
                setId(mopcion.idopcion || "");
                setNombreOpcion(mopcion.opcion || []);
                setPadre(mopcion.padre || "");
                setOrden(mopcion.orden || "");
                setUrlOpcion(mopcion.url_opcion || "");
                setMenuType(mopcion.disp_numerico1 || "");
            }
        }
    };

    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
      }
    };

    getOptionTableData();
    fetchUsuario();
  }, []);

  const procesarDatos = async () => {
    const infoInforme = {
      opcion: document.getElementById("NombreOpcion")?.value,
      padre: parseInt(document.getElementById("Padre")?.value),
      orden: parseInt(document.getElementById("Orden")?.value),
      url_opcion: document.getElementById("UrlOpcion")?.value,
      disp_numerico1: parseInt(document.getElementById("MenuType")?.value),
    };
    let datos = JSON.stringify(infoInforme);

    fetch(process.env.REACT_APP_API_URL + "rolesId/" + localStorage.getItem("idopcion") + "/", {
      method: "PUT",
      //responseType: "blob",
      body: datos,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status >= 200 && response.status < 300) {
            setEstadoPost(3);
          } else if (response.status >= 500) {
          } else if (response.status >= 400 && response.status < 500) {
            setEstadoPost(4);
          }
        })
      )
      .catch(function (err) {});
  };

  return (
    <Box p={4}>
      <AlertDismissibleExample alerta={estadoPOST} />
      <Box display="flex" justifyContent="space-between">
        <Box style={{ fontSize: "40px", fontWeight: "600" }}>Editar Opción</Box>
        <Box display="flex" alignItems="center">
          <Box paddingRight={3}>
          <Link to='/opciones'>
              <Button
              style={{
                backgroundColor: "#ff7f41",
                color: "#fff",
                width: "100px",
                borderRadius: "5px",
                height: "40px",
              }}
            >
              Cancelar
            </Button>
            </Link>
          </Box>
          <Box>
            <Button
              style={{
                backgroundColor: "#00c389",
                color: "#fff",
                width: "100px",
                borderRadius: "5px",
                height: "40px",
              }}
              onClick={() => {
                procesarDatos();
              }}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="Columns" justifyContent="space-between" paddingTop={3}>
      <Row className="mb-3">
          <Box display="flex">
            <Col sm={7} xs={12}>
              <Box style={{ fontSize: "20px", fontWeight: "500" }}>
                Id de la opción
              </Box>
            </Col>
            <Col sm={7} xs={12}>
              <Box paddingLeft={3}>
                <Input
                  type="text"
                  value={idOpcion}
                  onChange={(e) => setId(e.target.value)}
                  fullWidth={true}
                  disabled
                />
              </Box>
            </Col>
          </Box>
        </Row>
        <br></br>
        <Row className="mb-3">
          <Box display="flex">
            <Col sm={7} xs={12}>
              <Box style={{ fontSize: "20px", fontWeight: "500" }}>
                Nombre de la opción
              </Box>
            </Col>
            <Col sm={7} xs={12}>
              <Box paddingLeft={3}>
                <Input
                  id="NombreOpcion"
                  type="text"
                  value={nombreOpcion}
                  onChange={(e) => setNombreOpcion(e.target.value)}
                  fullWidth={true}
                />
              </Box>
            </Col>
          </Box>
        </Row>
        <br></br>
                <Row className="mb-3">
                  <Box display="flex">
                    <Col sm={7} xs={12}>
                      <Box style={{ fontSize: "20px", fontWeight: "500" }}>
                        Orden de la opción
                      </Box>
                    </Col>
                    <Col sm={7} xs={12}>
                      <Box paddingLeft={3}>
                        <Input
                          id="Orden"
                          type="number"
                          value={orden}
                          onChange={(e) => setOrden(e.target.value)}
                          fullWidth={true}
                        />
                      </Box>
                    </Col>
                  </Box>
                </Row>
        <br></br>
        <Row className="mb-3">
          <Box display="flex" alignItems="center">
            <Col sm={7} xs={12}>
              <Box style={{ fontSize: "20px", fontWeight: "500" }}>
                Selección del padre
              </Box>
            </Col>
            <Col sm={7} xs={12}>
              <FormControl variant="outlined" style={{ minWidth: 150 }}>
                <select
                  id="Padre"
                  value={padre}
                  onChange={(e) => setPadre(e.target.value)}
                  label="Seleccionar padre"
                >
                  {parentOptions.map((option) => (
                    <option key={option.idopcion} value={option.idopcion}>
                      {option.opcion}
                    </option>
                  ))}
                </select>
              </FormControl>
            </Col>
          </Box>
        </Row>
        <br></br>
        <Row className="mb-3">
          <Box display="flex" alignItems="center">
            <Col sm={7} xs={12}>
              <Box style={{ fontSize: "20px", fontWeight: "500" }}>
                Tipo del menú
              </Box>
            </Col>
            <Col sm={7} xs={12}>
              <FormControl variant="outlined" style={{ minWidth: 150 }}>
                <select
                  id="MenuType"
                  value={menuType}
                  type="number"
                  onChange={(e) => setMenuType(e.target.value)}
                  label="Tipo de menú"
                >
                  <option>{menuType}</option>
                  <option value={1}>Menú</option>
                  <option value={0}>Submenú</option>
                </select>
              </FormControl>
            </Col>
          </Box>
        </Row>
        <br></br>
        <Row className="mb-3">
          <Box display="flex">
            <Col sm={7} xs={12}>
              <Box style={{ fontSize: "20px", fontWeight: "500" }}>
                URL Opción
              </Box>
            </Col>
            <Col sm={7} xs={12}>
              <Box paddingLeft={3}>
                <Input
                  id="UrlOpcion"
                  type="text"
                  sx={{ width: "250%" }}
                  value={urlOpcion}
                  onChange={(e) => setUrlOpcion(e.target.value)}
                />
              </Box>
            </Col>
          </Box>
        </Row>
      </Box>
    </Box>
  );
};

export default EditarOpcion;