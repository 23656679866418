import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
    // Convertir los minutos almacenados en segundos
    let initialTimeLeft = Number(localStorage.getItem('timeLeft')) * 60;
    const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
    const [startTime, setStartTime] = useState(Date.now());

    const formatTime = (time) => {
        const min = Math.floor(time / 60);
        const sec = time % 60;
        const roundedSec = Math.round(sec);
        return `${min}:${roundedSec < 10 ? '0' : ''}${roundedSec}`;
    };

    useEffect(() => {
        setStartTime(Date.now());
        const timer = setInterval(() => {
            const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
            setTimeLeft(initialTimeLeft - elapsedTime);
            //console.log('Tiempo restante del token: ' + Math.trunc(timeLeft))
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    // agregamos un escucha para el evento 'storage' y actualizamos timeLeft cuando cambia el valor en localStorage
    useEffect(() => {
        const handleStorageChange = () => {
            const storedTimeLeft = Number(localStorage.getItem('timeLeft')) * 60;
            setTimeLeft(storedTimeLeft);
            setStartTime(Date.now());
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <div>
            Tiempo restante del token:<br/>
            <div style={{textAlign: 'center'}}>
                {formatTime(timeLeft)}
            </div>
        </div>
    );
};

export default CountdownTimer;