import React, { useEffect, useState, useRef } from "react";
import { Button, Row, Col, Form, Alert, Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { lighten } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, TextField } from "@mui/material";
import { Tooltip, List, ListItem, ListItemText } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import {
  Check,
  Clear,
  ChevronLeft,
  ChevronRight,
  FilterList,
  FirstPage,
  LastPage,
  Search,
  ArrowDownward,
  ViewColumn,
} from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckRole from "../CheckRole.js";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import styled from "@mui/material/styles/styled";
import { tableCellClasses } from "@mui/material/TableCell";
import DomainsChart from "./DomainsChart.js";
import HelpIcon from "@mui/icons-material/Help";
import calificacionUmbral from "../calificacion-umbral.png";
import LineamientosChart from "./LineamientosChart.js";
import DominiosLineamientosChart from "./DominiosLineamientosChart.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="label"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const headCells = [
  { id: "ID", numeric: false, disablePadding: true, label: "ID" },
  { id: "nombre", numeric: false, disablePadding: true, label: "Nombre" },

  {
    id: "compania",
    numeric: false,
    disablePadding: false,
    label: "Compañia",
  },
  {
    id: "responsable_ti",
    numeric: false,
    disablePadding: false,
    label: "Responsable TI",
  },
  {
    id: "responsable_negocio",
    numeric: false,
    disablePadding: false,
    label: "Responsable Negocio",
  },
];

const useStylesModal = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    maxHeight: "40vh",
    minHeight: "40vh",
  },
}));

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: "none", // Permite que el tooltip se ajuste al contenido
    padding: 0, // Elimina el padding para que la imagen ocupe todo el espacio
  },
  img: {
    display: "block",
    maxWidth: "100%",
    height: "auto",
  },

  container: {
    maxHeight: "55vh",
    minHeight: "auto",
    overflowY: "auto",
  },
  root: {
    width: "100%",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  switch: {
    paddingLeft: "1.5em",
  },
  inside: {
    backgroundColor: "white",
    width: "100%",
    // margintom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },

  head: {
    backgroundColor: "#2c2a29",
    color: "white",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
    //zIndex: 1,
  },
  palette: {
    primary: {
      main: "#4caf50",
    },
    secondary: {
      main: "#ff9100",
    },
  },
}));
function EditarMLineaBase() {
  const [rowsc, setRowsC] = React.useState([]);
  const [componentesSelected, setComponentesSelected] = React.useState([]);
  const [modalCompo, setModalCompo] = useState([]);
  const classes = useStyles();
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [companias, setCompanias] = useState("");
  const [nombreCIS, setNombreCIS] = useState("");
  const [version, setVersion] = useState("");
  const [nombre, setNombre] = useState("");
  const [responsables, setResponsables] = useState("");
  const [fechaEscaneo, setFechaEscaneo] = useState("");
  const [porcentajeCumplimiento, setPorcentajeCumplimiento] = useState("");
  const [totalActivos, setTotalActivos] = useState("");
  const [totalActivosEscaneados, setTotalActivosEscaneados] = useState("");
  const [informacion, setLineasBase] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const isSelected = (idlinea_base) => selected === idlinea_base;
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedInfra, setSelectedInfra] = useState([]);
  const rolusuario = localStorage.getItem("rolusuario");
  const isSelectedInfra = (name) => selectedInfra.indexOf(name) !== -1;
  const [dominiosOpciones, setDominiosOpciones] = React.useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [lineamientosIds, setLineamientosIds] = useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  const handleClickInfra = (event, name) => {
    const selectedIndex = selectedInfra.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedInfra(newSelected);
  };
  const CustomTooltip = (props) => {
    const classes = useStyles();
    return <Tooltip classes={{ tooltip: classes.tooltip }} {...props} />;
  };
  const handleClick = (event, row) => {
    setSelected(selected === row.name ? null : row.idlinea_base);
    setOpen(true);
  };

  function MyVerticallyCenteredModal(props) {
    const classes = useStylesModal();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [queryText, setqueryText] = React.useState("");
    const [contTecno, setContTecno] = React.useState(props.componentes);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const completarTabla = (obj, id) => {
      let temp = [];
      switch (id) {
        case "detalles":
          for (let i = 0; i < obj.length; i++) {
            temp.push(modalCompo.filter((dato) => dato.idactivo === obj[i])[0]);
          }

          setRowsC(temp);
          setModalShow(false);
          break;

        default:
          break;
      }
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = modalCompo.map((n) => n.idevaluacion);

        setContTecno(newSelecteds);
        return;
      }
      setContTecno([]);
    };
    const handleClick = (event, id, idtipo_activo, obj) => {
      const selectedIndex = contTecno.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(contTecno, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(contTecno.slice(1));
      } else if (selectedIndex === contTecno.length - 1) {
        newSelected = newSelected.concat(contTecno.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          contTecno.slice(0, selectedIndex),
          contTecno.slice(selectedIndex + 1)
        );
      }
      setContTecno(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
    const isSelected = (id) => contTecno.indexOf(id) !== -1;
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Añadir...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Row>
                <Col sm={4} xs={2}>
                  <Form className="buscar">
                    <Form.Control
                      type="text"
                      placeholder="Buscar"
                      onChange={(e) => setqueryText(e.target.value)}
                      style={{ align: "center" }}
                    />
                  </Form>
                </Col>
                <Col sm={4} xs={6}>
                  <Button
                    className="botonPositivo"
                    style={{ marginTop: "1%", width: "100%" }}
                    onClick={() => completarTabla(contTecno, "detalles")}
                  >
                    {" "}
                    Guardar{" "}
                  </Button>
                </Col>
              </Row>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={contTecno.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={modalCompo.length}
                  />
                  <TableBody>
                    {modalCompo?.map((row, index) => {
                      const isItemSelected = isSelected(row.idactivo);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleClick(
                              event,
                              row.idactivo,
                              row.idtipo_activo,
                              row
                            )
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.idactivo}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.idactivo}
                          </TableCell>
                          <TableCell align="left">{row.nombre}</TableCell>
                          <TableCell align="left">
                            {row.companias_usan}
                          </TableCell>
                          <TableCell align="left">
                            {row.UsuarioResponsableTI}
                          </TableCell>
                          <TableCell align="left">
                            {row.UsuarioResponsableNegocio}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={modalCompo.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Vista compacta"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  //renders de Graficas
  const renderDominios = () => <DomainsChart info={informacion} />;
  const renderChart = () => <DominiosLineamientosChart info={informacion} />;
  const renderLineamientos = () => <LineamientosChart />;
  const renderClasificacion = () => (
    <CustomTooltip
      title={
        <List>
          <ListItem>
            <ListItemText primary="Umbrales y Atribuciones:" />
          </ListItem>
          <ListItem>
            <img src={calificacionUmbral} />
          </ListItem>
        </List>
      }
    >
      <HelpIcon />
    </CustomTooltip>
  );
  const renderDominiosToolTip = () => (
    <CustomTooltip
      title={
        <List>
          <ListItem>
            - Los dominios, lineamientos y controles vienen del estándar CIS
          </ListItem>
          <ListItem>
            - Las filas opacas no aplican a la organización
          </ListItem>
          <ListItem>
            - Aunque los lineamientos tengan varios controles CIS, la última versión es la que se toma en cuenta
          </ListItem>
        </List>
      }
    >
      <HelpIcon />
    </CustomTooltip>
  );
  //Peticiones API
  useEffect(() => {
    const fetchLineasBase = async () => {
      try {
        const result = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }maestro/lineasbase/${localStorage.getItem("idlinea_base")}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await result.json();

        if (data && data[0]) {
          const lineasBase = data[0];
          setCompanias(lineasBase.companias || []);
          setNombreCIS(lineasBase.nombrecis || "");
          setVersion(lineasBase.version || "");
          setNombre(lineasBase.nombre || "");
          setResponsables(lineasBase.responsables || "");
          setFechaEscaneo(
            lineasBase.fecha_escaneo
              ? lineasBase.fecha_escaneo.split("T")[0]
              : ""
          );
          setPorcentajeCumplimiento(lineasBase.porcentaje_cumplimiento || "");
          setTotalActivos(lineasBase.total_activos || "");
          setTotalActivosEscaneados(lineasBase.total_activos_escaneados || "");
          fetchUsuarios(lineasBase.idcompanias);
          setLineasBase(data);

          //Extraer los dominios
          const domainsOptions = new Set();
          data.forEach((row) => {
            row.detalle_cis.Domain &&
              Object.keys(row.detalle_cis.Domain).forEach((domain) => {
                domainsOptions.add(domain);
              });
          });
          setDominiosOpciones(Array.from(domainsOptions));

          if (lineasBase.lineamientosids) {
            setLineamientosIds(lineasBase.lineamientosids);
          } else {
            setLineamientosIds([]);
          }
        }
      } catch (error) {
        console.error("Error al obtener las líneas base:", error);
      }
    };

    const fetchUsuarios = async (idCompanias) => {
      const usuariosPorCompania = await Promise.all(
        idCompanias.map(async (idCompania) => {
          const result = await fetch(
            `${process.env.REACT_APP_API_URL}usuariosrol/${idCompania}/2`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          return result.json();
        })
      );

      const usuarios = usuariosPorCompania.flat().map((usuario) => ({
        id: usuario.idusuario,
        nombre: usuario.nombre,
      }));

      setUsuarios(usuarios);
    };

    const obtenerComponentes = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "allcomponents/" +
          localStorage.getItem("idcompania") +
          "/1/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let datComponentes = await result.json();
        setModalCompo(datComponentes);
      }
    };

    fetchLineasBase();
    obtenerComponentes();
  }, []);

  const handleFilterChange = (event) => {
    setSelectedDomain(event.target.value);
  };

  return (
    <div>
      <MyVerticallyCenteredModal
        componentes={componentesSelected}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Row>
        <Col>
          <h2 className="subtitulo">Detalle Linea Base</h2>
        </Col>
      </Row>
      <hr />
      <Row className="mb-3">
        {/* <Col sm={1} xs={12}>
          <label className="label forn-label">Compañias</label>
        </Col>
        <Col sm={4} xs={12} className="ml-4">
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={companias}
          ></input>
        </Col> */}
        <Col sm={2} xs={12}>
          <label className="label forn-label">Nombre</label>
        </Col>
        <Col sm={4} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={nombre}
          ></input>
        </Col>
        <Col sm={2} xs={12}>
          <label className="label forn-label">Id Linea Base</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            defaultValue={localStorage.getItem("idlinea_base")}
          ></input>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Nombre CIS Benchmark</label>
        </Col>
        <Col sm={4} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={nombreCIS}
          ></input>
        </Col>
        <Col sm={2} xs={12}>
          <label className="label forn-label">Version</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={version}
          ></input>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Fecha Escaneo</label>
        </Col>
        <Col sm={4} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={fechaEscaneo}
          ></input>
        </Col>
        <Col sm={2} xs={12}>
          <label className="label forn-label">Porcentaje de Cumplimiento</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={porcentajeCumplimiento + "%"}
          ></input>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={2} xs={12}>
          <label className="label forn-label">Total Activos</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={totalActivos}
          ></input>
        </Col>
        <Col sm={2} xs={12}>
          <label className="label forn-label">Total Activos Escaneados</label>
        </Col>
        <Col sm={2} xs={12}>
          <input
            type="text"
            disabled
            className="form-control text-center font-weight-bold"
            value={totalActivosEscaneados}
          ></input>
        </Col>
      </Row>

      {
        <Row>
          <Col sm={12} xs={12}>
            <label className="label forn-label">Responsables</label>
          </Col>
          <Col>
            <TableContainer component={Paper} className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                maxHeightBody="55vh"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow className={classes.head}>
                    <StyledTableCell>Compañias</StyledTableCell>
                    <StyledTableCell>Responsable</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {informacion.map((row) => {
                    return row.idcompanias.map(() => (
                      <StyledTableRow key={row} hover tabIndex={-1}>
                        <StyledTableCell component="th" scope="row">
                          {row.companias || "Sin compañía"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <select>
                            {usuarios.length > 0 ? (
                              usuarios.map((usuario) => (
                                <option key={usuario.id} value={usuario.id}>
                                  {usuario.nombre || "No se encuentran datos"}
                                </option>
                              ))
                            ) : (
                              <option disabled>
                                No hay usuarios disponibles
                              </option>
                            )}
                          </select>
                        </StyledTableCell>
                      </StyledTableRow>
                    ));
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      }

      <hr />
      {
        <Row>
          <Col sm={12} xs={12}>
            <label className="label forn-label">
              Recomendaciones y Controles
            </label>
            {renderDominiosToolTip()}
          </Col>
          <Col sm={4} xs={12} className="mb-3">
            <select
              value={selectedDomain}
              onChange={handleFilterChange}
              className="form-control"
            >
              <option value="">Todos</option>
              {dominiosOpciones.map((domain) => (
                <option key={domain} value={domain}>
                  {domain}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      }
      {
        <Row>
          <Col>
            <TableContainer component={Paper} className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                maxHeightBody="55vh"
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow className={classes.head}>
                    <StyledTableCell>Dominio</StyledTableCell>
                    <StyledTableCell>Recomendacion/Lineamiento</StyledTableCell>
                    <StyledTableCell>Id Control CIS</StyledTableCell>
                    <StyledTableCell>Control CIS</StyledTableCell>
                    <StyledTableCell>Version Control</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {informacion.map((row) => {
                    return Object.keys(row?.detalle_cis?.Domain || {})
                      .filter(
                        (domainKey) =>
                          selectedDomain === "" || domainKey === selectedDomain
                      )
                      .map((domainKey) => {
                        const recommendations =
                          row.detalle_cis.Domain[domainKey]?.Recommendation ||
                          {};
                        return Object.keys(recommendations).map(
                          (recommendationKey) => {
                            const recommendationId =
                              recommendationKey.split(" ")[0];

                            const isInLineamientosIds =
                              lineamientosIds.includes(recommendationId);

                            return recommendations[recommendationKey].map(
                              (recommendation, index) => (
                                <StyledTableRow
                                  hover
                                  onClick={(event) => handleClick(event, row)}
                                  tabIndex={-1}
                                  key={`${row.idlinea_base}-${domainKey}-${recommendationKey}-${index}`}
                                  style={{
                                    opacity: isInLineamientosIds ? 1 : 0.5,
                                    backgroundColor: isInLineamientosIds
                                      ? "inherit"
                                      : "#f5f5f5",
                                  }}
                                >
                                  <StyledTableCell>{domainKey}</StyledTableCell>
                                  <StyledTableCell>
                                    {recommendationKey}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {recommendation.ControlIdCIS}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {recommendation["Control Name"]}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {recommendation["Version Control"]}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            );
                          }
                        );
                      });
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      }
      <br />
      <hr />
      <br />
      {informacion && informacion.length > 0 && informacion[0].dominios ? (
        informacion[0].dominios.length > 0 ? (
          <Row className="mb-3">
            <Col sm={6} xs={12}>
              <label className="label forn-label">
                Cumplimiento por dominios
                {renderClasificacion()}
              </label>
              <div style={{ width: "100%", height: "auto" }}>
                {renderDominios()}
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <label className="label forn-label">
                Cumplimiento por Lineamientos
              </label>
              <div style={{ width: "100%", height: "auto" }}>
                {renderChart()}
              </div>
            </Col>
          </Row>
        ) : (
          <div className="text-center">
            <p style={{ color: "red" }}>
              No se han cargado los lineamientos que aplican a la organización, ni cumplimientos, o no hay activos relacionados
            </p>
          </div>
        )
      ) : (
        <div className="text-center">
          <p style={{ color: "red" }}>Cargando información...</p>
        </div>
      )}
      <br />
      <hr />
      <br />
      {
        <Row className="mb-3">
          <Col md={9}>
            <label className="label forn-label">
              Activos Tecnologicos Relacionados
            </label>
          </Col>
          <Col md={2}>
            <Button
              className="botonIngreso"
              onClick={() => setModalShow(true)}
              disabled={rolusuario !== "1"}
            >
              Añadir Activo
            </Button>{" "}
          </Col>
        </Row>
      }
      <Col>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            maxHeightBody="55vh"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow className={classes.head}>
                <StyledTableCell>ID Activo</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Codigo App</StyledTableCell>
                <StyledTableCell>Cumplimiento</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {[...(informacion || []), ...(rowsc || [])].map((row, index) => {
                const isItemSelected = isSelectedInfra(row.idactivo);

                if (row.activos && Array.isArray(row.activos)) {
                  return row.activos.map((activo, subIndex) => (
                    <StyledTableRow
                      key={`${index}-${subIndex}`}
                      hover
                      onClick={(event) => handleClickInfra(event, row.idactivo)}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <StyledTableCell component="th" scope="row">
                        {activo.idactivo || row.idactivo}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {activo.nombre || row.nombre}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {activo.elemento_configuracion ||
                          row.elemento_configuracion}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {`${activo.cumplimiento || row.cumplimiento}%`}
                      </StyledTableCell>
                    </StyledTableRow>
                  ));
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
      <hr />
    </div>
  );
}
export default EditarMLineaBase;
