import React, { useEffect, useState } from "react";
import { Checkbox, makeStyles } from "@material-ui/core";
import swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Input,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ChevronLeft, ChevronRight, Search } from "react-bootstrap-icons";
import {
  ArrowDownward,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ViewColumn,
} from "@material-ui/icons";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useQuery } from "react-query";
import { getOptionTableData } from "../utilities/request";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AADService from "../../funcionesAuth.js";

const NewRolView = ({
  setIsNewRole,
  setMonitoringRequest,
  monitoringRequest,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [buttonEdit, setButtonEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openOptionsSelection, setOpenOptionsSelection] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsData, setOptionsData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();

  const columnsOptions = [
    {
      field: "idopcion",
      title: "Id Opcion",
    },
    {
      field: "opcion",
      title: "Opcion",
    },
    {
      field: "url_opcion",
      title: "Url Opcion",
    },
  ];
  const columns = [
    {
      field: "opcion",
      title: "Opcion",
    },
    {
      field: "url_opcion",
      title: "URL",
    },
    {
      field: "crear",
      title: "Crear",
      type: "boolean",
      render: (rol) => (
        <Checkbox
          checked={handleFindTable("C", rol) | false}
          onChange={(e) =>
            handleChangePermissions(
              e,
              tableData &&
                tableData.find(
                  (permission) => permission.idopcion === rol.idopcion
                ),
              "C"
            )
          }
        />
      ),
    },
    {
      field: "leer",
      title: "Leer",
      type: "boolean",
      render: (rol) => (
        <Checkbox
          checked={handleFindTable("R", rol) | false}
          onChange={(e) =>
            handleChangePermissions(
              e,
              tableData &&
                tableData.find(
                  (permission) => permission.idopcionrol === rol.idopcionrol
                ),
              "R"
            )
          }
        />
      ),
    },
    {
      field: "editar",
      title: "Editar",
      render: (rol) => (
        <Checkbox
          checked={handleFindTable("U", rol) | false}
          onChange={(e) =>
            handleChangePermissions(
              e,
              tableData &&
                tableData.find(
                  (permission) => permission.idopcion === rol.idopcion
                ),
              "U"
            )
          }
        />
      ),
    },
    {
      field: "borrar",
      title: "Borrar",
      render: (rol) => (
        <Checkbox
          checked={handleFindTable("D", rol) | false}
          onChange={(e) =>
            handleChangePermissions(
              e,
              tableData &&
                tableData.find(
                  (permission) => permission.idopcion === rol.idopcion
                ),
              "D"
            )
          }
        />
      ),
    },
    {
      field: "descargar",
      title: "Descargar",
    },
    {
      field: "",
      title: "",
      render: (rowData) => (
        <Button
          size='3'
          style={{
            color: "#ef5350",
          }}
          endIcon={<DeleteOutlineIcon />}
          onClick={() => handleDeleteOption(rowData)}
        ></Button>
      ),
    },
  ];

  const tableIcons = {
    //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#2c2a29",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  });

  const { data: optionTable } = useQuery(["optionTable"], () =>
    getOptionTableData()
  );

  const changeName = (input) => {
    setName(input);
  };

  const handleFindTable = (key, rol) =>
    tableData &&
    tableData.find((permission) => permission.idopcion === rol.idopcion)?.[key];

  const changeDescription = (input) => {
    setDescription(input);
  };

  const createNewRole = async () => {
    const modifiedPermissions = handleCreateNewRolPermissions();
    const rolData = modifiedPermissions.map((permission) => {
      return {
        ...permission,
        nombre_rol: name,
        descripcion_rol: description,
      };
    });

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `roles/1/`, {
        method: "POST",
        body: JSON.stringify(rolData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,

        },
      });
      if (response.ok) {
        swal.fire("Guardado exitoso!", "", "success");
        setMonitoringRequest(!monitoringRequest);
        setIsNewRole(null);
      } else {
        throw new Error("Error en el post");
      }
    } catch (error) {
      swal.fire("Error", "Ha ocurrido un error", "error");
    }
  };

  const handleCreateNewRolPermissions = () => {
    const data =
      tableData &&
      tableData.map((rol) => {
        const getRolPermissions = (rol) => {
          const permissions = ["C", "R", "U", "D"].filter((perm) => rol[perm]);
          return permissions.map((perm) => `${perm};`);
        };

        const modifiedRol = {
          idopcionrol: rol.idopcion,
          permisos: getRolPermissions(rol).join(""),
        };

        return modifiedRol;
      });

    return data;
  };

  const handleClickRow = (id) => {
    setButtonEdit(!selected.includes(id));
  };

  const handleAddOption = () => {
    const selectedItems = optionTable.filter((option) =>
      selected.includes(option.tableData.id)
    );
  
    if (!selectedItems.length) {
      swal.fire("Error", "No ha seleccionado ninguna opción.", "error");
      return;
    }
  
    const alreadyAdded = selectedItems.some((option) =>
      optionsData.some((data) => data.idopcion === option.idopcion)
    );
  
    if (alreadyAdded) {
      setOpenAlertModal(true);
      return;
    }
  
    setOptionsData((prevOptionsData) => [
      ...prevOptionsData,
      ...selectedItems.map((option) => ({ ...option, permisos: "R" })),
    ]);
  
    // Limpiar selección y cerrar el modal
    setSelected([]);
    setOpenOptionsSelection(false);
  };
  
  

  const handleChangePermissions = (e, rol, key) => {
    setTableData(
      tableData.map((roles) => {
        if (roles.idopcion === rol.idopcion) {
          return { ...roles, [key]: e.target.checked };
        } else {
          return roles;
        }
      })
    );
  };

  const handleDeleteOption = (rowData) => {
    setOptionsData(
      optionsData.filter((option) => option.idopcion !== rowData.idopcion)
    );
  };

  useEffect(() => {
    const modifiedOptionsRol =
      optionsData &&
      optionsData.length >= 1 &&
      optionsData.map((row) => {
        const permissions = ["C", "R", "U", "D"];
        const newData = permissions.reduce((acc, permission) => {
          acc[permission] = row.permisos.includes(permission);
          return acc;
        }, {});
        return { ...row, ...newData };
      });
    setTableData(modifiedOptionsRol);
  }, [optionsData]);

  return (
    <Box p={4}>
      <Box display="flex" justifyContent="space-between">
        <Box style={{ fontSize: "40px", fontWeight: "600" }}>Nuevo Rol</Box>
        <Box display="flex" alignItems="center">
          <Box paddingRight={3}>
            <Button
              style={{
                backgroundColor: "#ff7f41",
                color: "#fff",
                width: "100px",
                borderRadius: "5px",
                height: "40px",
              }}
              onClick={() => setIsNewRole(false)}
            >
              Cancelar
            </Button>
          </Box>

          <Box>
            <Button
              style={{
                backgroundColor: "#00c389",
                color: "#fff",
                width: "100px",
                borderRadius: "5px",
                height: "40px",
              }}
              onClick={createNewRole}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" paddingTop={3}>
        <Box display="flex">
          <Box style={{ fontSize: "20px", fontWeight: "500" }}>Nombre</Box>
          <Box paddingLeft={3}>
            <Input
              type="text"
              value={name}
              onChange={(e) => {
                changeName(e.target.value);
              }}
              fullWidth={true}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box style={{ fontSize: "20px", fontWeight: "500" }}>Descripcion</Box>
          <Box paddingLeft={3}>
            <Input
              type="text"
              sx={{ width: "250%" }}
              value={description}
              onChange={(e) => {
                changeDescription(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            color="primary"
            size="large"
            endIcon={<AddIcon />}
            onClick={() => setOpenOptionsSelection(!openOptionsSelection)}
          >
            Añadir Opcion
          </Button>
        </Box>
      </Box>
      <Box paddingTop={3}>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            columns={columns}
            data={optionsData}
            icons={tableIcons}
            options={{
              showTitle: false,
              searchFieldAlignment: "left",
              searchFieldStyle: {}, //Llenar estilo de barra de busqueda
              headerStyle: {
                backgroundColor: "#2c2a29",
                color: "#ffffff",
                position: "sticky",
                top: 0,
                fontFamily: "CIBFont Sans Regular",
                fontWeight: 400,
                fontSize: "1.2rem",
                lineHeight: 1.6,
                letterSpacing: "0.0075em",
                flex: "1 1 100%",
              },
              maxBodyHeight: "650px",
              selection: false,
              disableMultipleSelection: true,
              pageSizeOptions: [10, 20, 30],
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 10,
              actionsColumnIndex: -1,
            }}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No se encontraron registros",
              },
              pagination: {
                labelRowsSelect: "Filas",
                firstTooltip: "Primera página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Última página",
              },
            }}
          />
        </MuiThemeProvider>
      </Box>

      {openOptionsSelection && (
        <Box className="loading-mask">
          <Box
            bgcolor="#f8f9fa"
            borderRadius="10px"
            sx={{
              width: "1000px",
              height: "600px",
              maxWidth: "100%",
              overflowY: "auto",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              paddingLeft={3}
              paddingTop={3}
            >
              <Box>
                <Button
                  style={{
                    backgroundColor: "#00c389",
                    color: "#fff",
                    width: "100px",
                    borderRadius: "5px",
                    height: "40px",
                  }}
                  onClick={handleAddOption}
                >
                  Guardar
                </Button>
              </Box>
              <Box display="flex" alignItems="center">
                <Button
                  color="primary"
                  size="large"
                  startIcon={<CloseIcon />}
                  onClick={() => setOpenOptionsSelection(!openOptionsSelection)}
                ></Button>
              </Box>
            </Box>
            <Box p={3}>
              <MuiThemeProvider theme={theme}>
                <MaterialTable
                  columns={columnsOptions}
                  data={optionTable}
                  icons={tableIcons}
                  options={{
                    rowStyle: (rowData) => ({
                      backgroundColor: selected.includes(rowData.tableData.id)
                        ? "#fff8d3" // Color de fondo para filas seleccionadas
                        : "#FFF", // Color de fondo para filas no seleccionadas
                    }),
                    showTitle: false,
                    searchFieldAlignment: "left",
                    headerStyle: {
                      backgroundColor: "#2c2a29",
                      color: "#ffffff",
                      position: "sticky",
                      top: 0,
                      fontFamily: "CIBFont Sans Regular",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                      lineHeight: 1.6,
                      letterSpacing: "0.0075em",
                      flex: "1 1 100%",
                    },
                    maxBodyHeight: "650px",
                    pageSizeOptions: [10, 20, 30],
                    pageSize: 10,
                  }}
                  onRowClick={(evt, rowData) => {
                    const rowId = rowData.tableData.id;
                    setSelected(
                      (prevSelected) =>
                        prevSelected.includes(rowId)
                          ? prevSelected.filter((id) => id !== rowId) // Quitar si ya está seleccionado
                          : [...prevSelected, rowId] // Agregar si no está seleccionado
                    );
                  }}
                  localization={{
                    toolbar: {
                      searchPlaceholder: "Buscar",
                    },
                    body: {
                      emptyDataSourceMessage: "No se encontraron registros",
                    },
                    pagination: {
                      labelRowsSelect: "Filas",
                      firstTooltip: "Primera página",
                      previousTooltip: "Anterior",
                      nextTooltip: "Siguiente",
                      lastTooltip: "Última página",
                    },
                  }}
                />
              </MuiThemeProvider>
            </Box>
          </Box>
        </Box>
      )}

      {openAlertModal && (
        <Dialog open={openAlertModal} sx={{ borderRadius: "10px" }}>
          <DialogTitle>No puede agregar esta opcion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              No la puede agregar debido a que ya esta agregada en este momento
              en las opciones de su rol.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: "#00c389",
                color: "#fff",
                width: "100px",
                borderRadius: "10px",
                height: "35px",
              }}
              onClick={() => setOpenAlertModal(!openAlertModal)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default NewRolView;
