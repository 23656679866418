import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NuevoControlesEva from './NuevoControlesEva';
import ResumenEfectividad from '../ResumenEfectividad'; //Pendiente cambio
import AnalisisBrechas from './NewAnalisisBrechas';
import EscenariosMitigacion from './NewEscenariosMitigacion'; //Pendiente cambio
//import LineasBaseEro from '../LineasBaseEro';


const MainControlesNew = () => {
    const [value, setValue] = useState(0);

    const existeTipoCanal = localStorage.getItem("variable_tipo_canal");
    const existeTipoDespliegue = localStorage.getItem("variable_tipo_despliegue");
    const existeTipoActivo = localStorage.getItem("variable_tipo_activo");

    const isInvalid = (val) => !val || val.trim() === "" || val === "NaN" || val === "null" || val === "undefined" || val === null || val === "Sin Clasificar";
    const mostrarMensaje = isInvalid(existeTipoCanal) || isInvalid(existeTipoDespliegue) || isInvalid(existeTipoActivo);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            {mostrarMensaje ? (
                <div>
                    <p className='label forn-label' style={{ color: 'red' }}>
                        Es necesario que al activo a evaluar se le asignen los atributos de tipo despliegue y canal para visualizar los controles.
                    </p>
                </div>
            ) : (
                <>
                    <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label="Controles" style={{ color: "#ffffff" }} />
                            <Tab label="Análisis de Brechas" style={{ color: "#ffffff", padding: "3px 7px 3px" }}/>
                            <Tab label="Escenarios de Mitigación" style={{ color: "#ffffff", padding: "3px 7px 3px" }}/>
                            <Tab label="Resumen Efectividad" style={{ color: "#ffffff", padding: "3px 7px 3px" }} disabled />
                        </Tabs>
                    </AppBar>
                    {value === 0 && <NuevoControlesEva />}
                    {value === 1 && <AnalisisBrechas />}
                    {value === 2 && <EscenariosMitigacion />}
                    {value === 3 && <ResumenEfectividad />}
                </>
            )}
        </div>
    );
};

export default MainControlesNew;
