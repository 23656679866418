import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import MaterialTable, { MTablePagination } from "material-table";
import { Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { TablePagination } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import CheckRole from "../CheckRole.js";
import AADService from "../funcionesAuth.js";
import { DecryptData } from "../DatosEncriptados/EncryptedFunctions.js";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const tableIcons = {
  //Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function M_EthicalHacking() {
  const useStyles = makeStyles((theme) => ({
    container: {
      maxHeight: "55vh",
      minHeight: "55vh",
    },
    root: {
      width: "100%",
    },
    pagination: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    switch: {
      paddingLeft: "1.5em",
    },
    inside: {
      backgroundColor: "white",
      width: "100%",
      // margintom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    head: {
      backgroundColor: "#2c2a29",
      color: "white",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
      //zIndex: 1,
    },
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#ff9100",
      },
    },
  }));

  const classes = useStyles();
  const [filter, setFilter] = useState(false);

  const columns = [
    {
      field: "idinformes_ethical_hacking",
      title: "Id Informe",
    },
      {
        field: "descripcion",
        title: "Descripcion",
      },

      {
        field: "programa",
        title:"Programa",
      },
      {
        field: "experto",
        title:"Experto",
      }
  ];

  const [ethicalhacking, setEthicalHacking] = React.useState([]);
  const check = new CheckRole("/ethical-hacking");
  const [loading, setLoading] = React.useState(false);
  const [opcionesGenerales, setOpcionesGenerales] = React.useState([]);
  const AzureADService = new AADService();
  const token = AzureADService.getToken();
  
  useEffect(() => {
    const fetchEthicalHacking = async () => {
      setLoading(true);
      const result = await fetch(
        process.env.REACT_APP_API_URL + "maestro/ethicalhacking/0/P/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      if (result.status >= 200 && result.status <= 300) {
        let data = await result.json();
        
        let idrol = localStorage.getItem("rolusuario");
        let programa = "";
        if (idrol === "2") {
          programa = "Riesgos";
        } else if (idrol === "9") {
          programa = "Auditoria";
        } else if (idrol === "10") {
          programa = "Ciberseguridad";
        }
        if (programa !== "") {
          data = data.filter((item) => item.programa === programa);
        }

        setEthicalHacking(data);
        setLoading(false);
      } else if (result.status >= 400 && result.status <= 500) {
        setEthicalHacking([]);
        setLoading(false);
      }
    };

    const fetchUsuario = async () => {
      const result = await fetch(
        process.env.REACT_APP_API_URL +
          "usuarios/menu/" +
          localStorage.getItem("usuario") +
          "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const encryptedUser = await result.json();
      const decryptedUser = DecryptData(encryptedUser);
      let usuario = JSON.parse(decryptedUser);
      let opciones = [];
      let permisos = [];
      for (let i = 0; i < usuario.length; i++) {
        permisos = usuario[i].permisos.split(";");
        opciones.push({ opcion: usuario[i].url_opcion, permisos: permisos });
      }
      setOpcionesGenerales(opciones);
      var validity = check.checkPermits(opciones, "GET");
      if (validity) {
        fetchEthicalHacking();
      }
    };
    fetchUsuario();
    
  }, []);

  const handleChange = () => {
    setFilter(!filter);
  };

  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], id);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };

  const ver = () => {
    localStorage.setItem("idinformes_ethical_hacking", selected);
  };

  return (
    <div>
      <Row>
        <Col>
          <h1>Ethical Hacking</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ zIndex: 100 }}>
            <Paper variant="outlined" className={classes.paper}>
              <MaterialTable
                columns={columns}
                data={ethicalhacking}
                icons={tableIcons}
                options={{
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      ButtonEdit === true && selectedRow === rowData.idinformes_ethical_hacking
                        ? "#ffdb94"
                        : "#FFF",
                  }),
                  showTitle: false,
                  filtering: filter,
                  searchFieldAlignment: "left",
                  searchFieldStyle: { height: 50 },
                  //zIndex: -1000,
                  headerStyle: {
                    //zIndex: 1,
                    backgroundColor: "#2c2a29",
                    color: "#ffffff",
                    position: "relative",
                    top: 0,
                    fontFamily: "CIBFont Sans Regular",
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    lineHeight: 1.6,
                    letterSpacing: "0.0075em",
                    flex: "1 1 100%",
                  },
                  maxBodyHeight: "650px",
                  selection: false,
                  disableMultipleSelection: true,
                  pageSizeOptions: [10, 20, 30],
                  showSelectAllCheckbox: false,
                  showTextRowsSelected: false,
                  pageSize: 10,
                }}
                components={{
                  Pagination: (props) => (
                    <div className={classes.pagination}>
                      <FormControlLabel
                        className={classes.switch}
                        control={
                          <Checkbox
                            checked={filter}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Mostrar filtros por columnas
                          </Typography>
                        }
                      />
                      <TablePagination
                        {...props}
                        className={classes.pagination}
                      />
                    </div>
                  ),
                }}
                onRowClick={(evt, selectedRow) => {
                  handleClick(selectedRow.idinformes_ethical_hacking);
                  return setSelectedRow(selectedRow.idinformes_ethical_hacking);
                }}
                actions={[
                  loading
                    ? { 
                        icon: () => <CircularProgress />,
                        position: "toolbar",
                        isFreeAction: true,
                      }
                    :
                  {
                    icon: () => (
                      <Link to='NuevoEthicalHacking'>
                        {
                        check.checkPermits(opcionesGenerales, "POST") ? (
                        <Button className='botonPositivo'>Nuevo</Button>
                        ): (
                          ""
                        )}
                        
                      </Link>
                    ),
                    
                    position: "toolbar",
                    isFreeAction: true,
                  },
                  {
                    icon: () => (
                      <Link to="EditarEthicalHacking">
                        {check.checkPermits(opcionesGenerales, "PUT") ? (
                        <Button className="botonGeneral">Editar</Button>
                        ):(
                          ""
                        )
                  }
                      </Link>
                    ),
                    onClick: ver(),
                    position: "toolbar",
                    isFreeAction: true,
                    hidden: ButtonEdit === false,
                  },
                ]}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Buscar",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                  },
                  pagination: {
                    labelRowsSelect: "Filas",
                    firstTooltip: "Primera página",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Última página",
                  },
                }}
              />
            </Paper>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default M_EthicalHacking;






