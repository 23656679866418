import { Container, Hidden } from "@mui/material";
import React, { useState, useEffect, Component, useContext } from "react";
import Sidebar from "./SideBar";
import { Button, Navbar, Nav, NavDropdown, Row, Col, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  HddStack,
  List,
  Grid3x3Gap,
  HddStackFill,
  HouseDoorFill,
} from "react-bootstrap-icons";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { without } from "lodash";
import NavDropDown from "./NavDropDown";
import HomePage from "./HomePage";
import Estructuras from "./Estructuras";
import NuevosActivos from "./activos/NuevosActivos.js";
import EnhancedTable from "./Evaluaciones/Mtable_Evaluaciones.js";
import Riesgos from "./Evaluaciones/MainRiesgos.js";
import Frecuencia from "./Evaluaciones/Frecuencia";
import ActivosInfo from "./activos/Mtable_ActivosInformacion.js";
import Nueva_evaluacion from "./Evaluaciones/Nueva_evaluacion.js";
import EditarEvaluacion from "./Evaluaciones/EditarEvaluacion.js";
import Proveedores from "./proveedores/Mtable_Proveedores.js";
import Nuevo_proveedor from "./proveedores/Nuevo_proveedor.js";
import CuestionarioEvaluacion from "./Evaluaciones/CuestionarioEvaluacion";
import Vul_no_tec from "./Evaluaciones/Vulnerabilidades/Vul_no_tec_Padre";
import Controles from "./Evaluaciones/MainControles.js";
import ResumenEfectividad from "./Evaluaciones/ResumenEfectividad";
import EscenariosMitigacion from "./Evaluaciones/EscenariosMitigacion";
import componentes from "./componentes/Mtable_Componentes.js";
import MainComponente from "./componentes/MainComponente.js";
import nuevoComponente from "./componentes/NuevoComponente.js";
import proyectos from "./proyectos/Mtable_Proyectos.js";
import nuevo_proy from "./proyectos/Nuevo_proyecto.js";
import tecnologias from "./tecnologia/Mtable_Tecnologias.js";
import nueva_tec from "./tecnologia/Nueva_tecnologia.js";
import Mtable_Controles from "./Mcontroles/Mtable_Controles.js";
import NuevosMControles from "./Mcontroles/NuevoMControles.js";
import EditarMControles from "./Mcontroles/EditarMControles.js";
import Mtable_Implementadores from "./Mimplementadores/Mtable_Implementadores.js";
import NuevosMImplementadores from "./Mimplementadores/NuevoMImplementadores.js";
import EditarMImplementadores from "./Mimplementadores/EditarMImplementadores";
import "./index.css";
import logo from "./main_icon.png";
import EditarActivo from "./activos/EditarActivo.js";
import MainEditarComponente from "./componentes/MainEditarComponente.js";
import componentesInfra from "./componentesInfra/Mtable_ComponentesInfra.js";
import nuevoComponenteInfra from "./componentesInfra/NuevoComponenteInfra.js";
import EditarComponenteInfra from "./componentesInfra/EditarComponenteInfra.js";
import CuestionarioAdmin from "./Administracion/CuestionarioAdmin.js";
import CuestionarioConsulta from "./Administracion/CuestionarioConsulta.js";
import EditarProveedor from "./proveedores/EditarProveedor.js";
import EditarTecnologia from "./tecnologia/EditarTecnologia";
import EditarProyecto from "./proyectos/EditarProyecto";
import Vul_tec from "./Evaluaciones/Vulnerabilidades/Vul_tec.js";
import MainVul from "./Evaluaciones/Vulnerabilidades/MainVulnerabilidades.js";
import Eliminar_vul_tec from "./Evaluaciones/Vulnerabilidades/Eliminar_vul_tec.js";
import Descripcion_comp from "./Evaluaciones/descripcion_comp";
import AADService from "./funcionesAuth.js";
import PlanesAccion from "./planesAccion/Mtable_PlanesAccion";
import NuevoPlanAccion from "./planesAccion/NuevoPlanAccion";
import EditarPlanAccion from "./planesAccion/EditarPlanAccion";
import Desiciones from "./decisiones/Decisiones";
import EditarDecisiones from "./decisiones/EditarDecisiones";
import Regulatorio from "./Administracion/Regulatorio.js";
import Informes from "./Administracion/Informes.js";
import InformeVSC from "./Administracion/InformeVSC.js";
import DescargaLogs from "./Administracion/DescargaLogs.js";
import CrearUsuario from "./Administracion/CrearUsuario.js";
import FrecuenciasSOC from "./Administracion/FrecuenciaSOC";
import CheckRole from "./CheckRole";
import { Check } from "@mui/icons-material";
import { DecryptData } from "./DatosEncriptados/EncryptedFunctions";
import MTableRolView from "./Roles/MTableRolView/MTableRolView";
import EditTableRolView from "./Roles/EditTableRolView/EditTableRolView";
import NewRolView from "./Roles/NewRolView/NewRolView";
import MtableRegulatorioView from "./estadisticasRegulatorio/MtableRegulatorioView/MtableRegulatorioView";
import Valoraciones from "./Valoracion/MValoracion.js";
import CrearEfecto from "./Valoracion/CrearEfecto.js";
import EditarEfecto from "./Valoracion/EditarEfecto.js";
import AgregarEfecto from "./Valoracion/AgregarEfecto.js";
import AdminEfectividad from "./Administracion/AdminEfectividad.js";
import IconButton from "@material-ui/core/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import CountdownTimer from "./CountdownTimer";
import EditarAgregarEfectos from "./Valoracion/EditarAgregarEfecto.js";
import Adjuntos from "./Administracion/Adjuntos.js";
import MTableRiesgos from "./Taxonomias/Mtable_Riesgos.js";
import Mtable_vulnerabilidades from "./Taxonomias/Mtable_Vulnerabilidades.js";
import EditarMVulnerabilidad from "./Taxonomias/EditarMVulnerabilidad.js";
import Mtable_amenazas from "./Taxonomias/Mtable_Amenazas.js";
import EditarAmenazas from "./Taxonomias/EditarAmenazas.js";
import MTableLineasBase from "./LineasBase/MTableLineasBase.js";
import EditarLineaBase from "./LineasBase/EditarMLineaBase.js";
import EthicalHacking from "./hacking/Mtable_EthicalHacking.js";
import NuevoEthicalHacking from "./hacking/NuevoEthicalHacking.js";
import EditarEthicalHacking from "./hacking/EditarEthicalHacking.js";
import S3View from "./Administracion/S3View.js";
import M_Opcion from "./opciones/MTableOpcion.js";
import NuevaOpcion from "./opciones/NuevaOpcion.js";
import EditarOpcion from "./opciones/EditarOpcion.js";
//Nueva metodología
import Mtable_NewEvaluaciones from "./Evaluaciones/newEvaluaciones/Mtable_NewEvaluaciones.js";
import NuevosRiesgos from "./Evaluaciones/newEvaluaciones/MainRiesgosNew.js"
import NuevoCuestionarioEvaluacion from "./Evaluaciones/NuevoCuestionarioEvaluacion.js";
import MasterFindings from "./Evaluaciones/newVulnerabilidades/MasterFindings.js";
import EvaluacionesPadre from "./Evaluaciones/EvaluacionesPadre.js";
import MainControlesNew from "./Evaluaciones/newEvaluaciones/MainControlesNew.js";

localStorage.setItem("variable_tipo_activo", 0);
localStorage.setItem("idevaluacion", 0);
class Menu extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.state = {
      count: true,
      parametros: [],
      idrol: 0,
      rol: "",
      id_compania_ppal: 0,
      nombre: "",
      nombre_compania_ppal: "",
      companias: [],
      tipo_activo: 0,
      orden_menu: localStorage.getItem("idevaluacion"),
    };
    this.AzureADService = new AADService();
    this.token = this.AzureADService.getToken();
  }
  
  handler() {
    this.setState({
      orden_menu: localStorage.getItem("idevaluacion"),
    });
  }
  componentDidMount() {
    /*
    if (this.token == null) {
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    } else {
      */
    
    const unicodeCorreo = (correo) => {
      let total = "";
      for (let i = 0; i < correo.length; i++) {
        let uni = correo.charCodeAt(i);
        total = total + "/" + uni;
      }
      correo = Buffer.from(total, "ascii").toString("base64");
      return correo;
    };

    const fetchdata = async () => {
      let correo = this.props.login;
      correo = correo + "@bancolombia.com.co";
      correo = unicodeCorreo(correo);
      localStorage.setItem("usuario", correo);
      const result = await fetch(
        process.env.REACT_APP_API_URL + "usuarios/menu/" + correo + "/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      );
      const encryptedParam = await result.json();
      const decryptedParam = DecryptData(encryptedParam);
      let param = JSON.parse(decryptedParam);
      var arr = [];
      let nombre_rol = "";
      let id_rol = 0;
      let id_posicion = 0;
      let menu_usuario = [];
      Object.keys(param).forEach(function (key) {
        if (param[key].padre === 0 || param[key].padre === 12)
          menu_usuario.push(param[key]);
      });
      Object.keys(menu_usuario).forEach(function (key) {
        Object.keys(param).forEach(function (llave) {
          if (menu_usuario[key].idopcion === param[llave].padre) {
            arr.push(param[llave]);
          }
        });
        menu_usuario[key].items = arr;
        arr = [];
      });
      id_rol = menu_usuario[0]?.idrol;
      nombre_rol = menu_usuario[0]?.nombre_rol;
      id_posicion = menu_usuario[0]?.idposicion;
      let id_compania = menu_usuario[0]?.idcompania;
      let nombre = menu_usuario[0]?.nombre;
      localStorage.setItem("idusuario", menu_usuario[0]?.idusuario);
      localStorage.setItem("rolusuario", id_rol);
      localStorage.setItem("idposicion", id_posicion);
      this.setState({
        parametros: menu_usuario,
        idrol: id_rol,
        rol: nombre_rol,
        id_compania_ppal: id_compania,
        nombre: nombre,
      });
    };
    fetchdata();
    /*
    const fetchcompanies = async () => {
      await fetchdata();
      const result = await fetch("general/compania/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          //Authorization: "Bearer " + this.token,
        },
      });
      let companias = await result.json();
      
    
      localStorage.setItem("idcompania", this.state.id_compania_ppal); // guarda el id de la copañía que hace parte del usuario
      let self = this;
      Object.keys(companias).forEach(function (key) {
        if (companias[key]["idcompania"] === id_compania) {
          let nombre_compania = companias[key]["compania"];
          let temp = companias;
          temp = without(temp, companias[key]);
          self.setState({
            nombre_compania_ppal: nombre_compania,
            companias: temp,
            //id_compania_ppal

          });
        }
      });
    };
    fetchdata();
    fetchcompanies();
     */
  }

  toggleMenu() {
    this.setState({ count: !this.state.count });
    if (this.state.count === false) {
      document.getElementById("contenedor_sidenav").style.width = "0";
      document.getElementById("contenedor_paginas").style.marginLeft = "0";
    } else {
      document.getElementById("contenedor_sidenav").style.width = "240px";
      document.getElementById("contenedor_paginas").style.marginLeft = "240px";
    }
  }
  componentDidUpdate() {
    /* if (this.state.count === false) {
      document.getElementById("contenedor_sidenav").style.display = "none";
    } else {
      document.getElementById("contenedor_sidenav").style.display = "inline";
    } */
  }
  render() {
    return (
      <div id="principal">
        <Router>
          <Row id="menu_titulo">
            <Col xs={12} style={{ paddingRight: "0px" }}>
              <nav className="navbar navbar-dark" id="nav_header">
                <Button
                  variant="#ffffff"
                  size="lg"
                  onClick={() => this.toggleMenu()}
                >
                  <List />
                </Button>
                <Link to="menu">
                  <Button
                    variant="#787878"
                    size="lg"
                    onClick={() => {
                      this.setState({ orden_menu: 0 });
                      localStorage.setItem("idevaluacion", 0);
                    }}
                  >
                    <HouseDoorFill />
                  </Button>
                </Link>
                <Navbar.Brand></Navbar.Brand>
                <Navbar.Brand className="titulo" href="#home">
                  <img src={logo} alt="" className="logonav" />
                  GRC Riesgos tecnológicos y cibernéticos
                </Navbar.Brand>
                <Nav className="mr-auto">
                  <Nav.Link href="#home"></Nav.Link>
                </Nav>
                <label className="texto">{this.state.nombre}</label>
                <label className="texto">{this.state.rol}</label>
                <NavDropDown
                  className="texto"
                  rol={this.state.rol}
                  compania={this.state.nombre_compania_ppal}
                  companias={this.state.companias}
                  id_compania_ppal={this.state.id_compania_ppal}
                />
              </nav>
            </Col>
          </Row>
          <div id="contenedor_principal">
            {/* <Row className="nav-margen" style={{ marginRight: "0px", marginLeft: "0px" }}> */}
            <div id="contenedor_sidenav">
              <Sidebar
                items={this.state.parametros}
                orden_menu={this.state.orden_menu}
                eval_type={localStorage.getItem("eval_type")}
              />
            </div>
            <div id="contenedor_paginas">
              <Route path="/" component={HomePage} exact />
              {/* {this.state.idrol == 1 ? ( */}
              <>
                <Route path="/regulatorio" component={Regulatorio} exact />
                <Route path="/frecuencias" component={FrecuenciasSOC} exact />
              </>
              {/* ) : (
                ""
              )} */}
              {/* {this.state.idrol == 1 ? ( */}
              {/* <Route path='/informes' component={Informes} exact />
              ) : (
                ""
              )}
              {this.state.idrol == 1 ? ( */}
              <Route path="/descarga-logs" component={DescargaLogs} exact />
              {/* ) : (
                ""
              )}
              {this.state.idrol == 1 ? ( */}
              <Route path="/usuario" component={CrearUsuario} exact />
              {/* ) : (
                ""
              )} */}
              {/* {this.state.idrol == 1 || this.state.idrol == 2 || this.state.idrol == 3 || this.state.idrol == 7 ? ( */}
              <>
                <Route path="/estructuras" component={Estructuras} exact />
                <Route
                  path="/dominio-de-informacion"
                  component={ActivosInfo}
                  exact
                />
                <Route path="/NuevoActivo" component={NuevosActivos} exact />
                <Route path="/EditarActivo" component={EditarActivo} exact />
                <Route
                  path="/componente-tecnologico"
                  component={MainComponente}
                  exact
                />
                <Route
                  path="/NuevoComponente"
                  component={nuevoComponente}
                  exact
                />
                <Route
                  path="/MainEditarComponente"
                  component={MainEditarComponente}
                  exact
                />
                <Route
                  path="/componente-infraestructura"
                  component={componentesInfra}
                  exact
                />
                <Route
                  path="/NuevoComponenteInfra"
                  component={nuevoComponenteInfra}
                  exact
                />
                <Route
                  path="/EditarComponenteInfra"
                  component={EditarComponenteInfra}
                  exact
                />
                <Route
                  path="/nuevo-cuestionario-de-riesgos"
                  component={NuevoCuestionarioEvaluacion}
                  exact
                />
                <Route
                  path="/cuestionario-admin"
                  component={CuestionarioAdmin}
                  exact
                />
                <Route
                  path="/cuestionario-consulta"
                  component={CuestionarioConsulta}
                  exact
                />
                <Route path="/informe-vsc" component={InformeVSC} exact />
                <Route
                  path="/admin-efectividad"
                  component={AdminEfectividad}
                  exact
                />
                <Route path="/adjuntos" component={Adjuntos} exact />
                <Route path="/s3view" component={S3View} exact />
                <Route path="/proyecto" component={proyectos} exact />
                <Route path="/NuevoProyecto" component={nuevo_proy} exact />
                <Route
                  path="/EditarProyecto"
                  component={EditarProyecto}
                  exact
                />
                <Route
                  path="/tecnologia-emergente"
                  component={tecnologias}
                  exact
                />
                <Route path="/NuevaTecnologia" component={nueva_tec} exact />
                <Route
                  path="/EditarTecnologia"
                  component={EditarTecnologia}
                  exact
                />
                <Route path="/proveedores" component={Proveedores} exact />
                <Route path="/nuevo_prov" component={Nuevo_proveedor} exact />
                <Route
                  path="/EditarProveedor"
                  component={EditarProveedor}
                  exact
                />
                <Route
                  path="/tipos-de-riesgos"
                  component={MTableRiesgos}
                  exact
                />
                <Route
                  path="/tipos-de-vulnerabilidades"
                  component={Mtable_vulnerabilidades}
                  exact
                />
                <Route
                  path="/EditarMvulnerabilidad"
                  component={EditarMVulnerabilidad}
                  exact
                />
                <Route
                  path="/tipos-de-amenazas"
                  component={Mtable_amenazas}
                  exact
                />
                <Route
                  path="/EditarAmenazas"
                  component={EditarAmenazas}
                  exact
                />
                <Route
                  path="/maestro-controles"
                  component={Mtable_Controles}
                  exact
                />
                <Route
                  path="/NuevoMControl"
                  component={NuevosMControles}
                  exact
                />
                <Route
                  path="/EditarMControl"
                  component={EditarMControles}
                  exact
                />
                <Route
                  path="/maestro-implementador"
                  component={Mtable_Implementadores}
                  exact
                />
                <Route
                  path="/NuevoMimplementador"
                  component={NuevosMImplementadores}
                  exact
                />
                <Route
                  path="/EditarMimplementador"
                  component={EditarMImplementadores}
                  exact
                />
                <Route path="/lineas-base" component={MTableLineasBase} exact />
                <Route path="/roles" component={MTableRolView} exact />
                <Route path="/edit-roles" component={EditTableRolView} exact />
                <Route path="/new-role" component={NewRolView} exact />
                <Route
                  path="/estadisticas-Regulatorio"
                  component={MtableRegulatorioView}
                  exact
                />
              </>
              {/* ) : (
                ""
              )} */}

              {/* {this.state.idrol == 1 ||
              this.state.idrol == 2 ||
              this.state.idrol == 5 ||
              this.state.idrol == 7 ||
              this.state.idrol == 8 ||
              this.state.idrol == 3 ? ( */}
              <>
                <Route
                  path="/evaluaciones"
                  render={(props) => <EnhancedTable action={this.handler} />}
                  exact
                />
                <Route
                  path="/nueva_eval"
                  render={(props) => <Nueva_evaluacion action={this.handler} />}
                  exact
                />
                <Route
                  path="/new-evaluaciones"
                  render={(props) => <Mtable_NewEvaluaciones action={this.handler} />}
                  exact
                />
                <Route
                  path="/detalle-evaluacion"
                  render={(props) => (
                    <EvaluacionesPadre action={this.handler} />
                  )}
                  exact
                />
                <Route
                  path="/EditarEvaluacion"
                  render={(props) => <EditarEvaluacion action={this.handler} />}
                  exact
                />
                <Route
                  path="/descripcion-de-componentes"
                  component={Descripcion_comp}
                  exact
                />
                
                <Route
                  path="/evaluacion-riesgo"
                  component={Riesgos}
                  items={this.state.parametros}
                  exact
                />
                <Route
                  path="/NuevosRiesgos"
                  component={NuevosRiesgos}
                  items={this.state.parametros}
                  exact
                />
                <Route
                  path="/frecuencia-riesgos"
                  component={Frecuencia}
                  exact
                />
                <Route
                  path="/evaluacion-cuestionario-de-riesgos"
                  render={(props) => <CuestionarioEvaluacion />}
                  exact
                />
                <Route
                  path="/evaluacion-vulnerabilidades-no-tecnicas"
                  component={Vul_no_tec}
                  exact
                />
                <Route
                  path="/evaluacion-vulnerabilidades-tecnicas"
                  component={Vul_tec}
                  exact
                />
                <Route
                  path="/evaluacion-eliminar-vulnerabilidades-tecnicas"
                  component={MainVul}
                  exact
                />
                <Route
                  path="/findings"
                  component={MasterFindings}
                  exact
                />
                <Route
                  path="/eliminar-vul"
                  component={Eliminar_vul_tec}
                  exact
                />
                <Route
                  path="/controles-evaluacion"
                  component={Controles}
                  exact
                />
                <Route
                  path="/new-controles-evaluacion"
                  component={MainControlesNew}
                  exact
                />
                {/* <Route
                  path="/resumen-efectividad"
                  component={ResumenEfectividad}
                  exact
                /> */}
                {/* <Route
                  path="/escenarios-mitigacion"
                  component={EscenariosMitigacion}
                  exact
                /> */}
              </>
              {/* ) : (
                ""
              )}
              {this.state.idrol == 1 ||
              this.state.idrol == 2 ||
              this.state.idrol == 5 ||
              this.state.idrol == 3 ||
              this.state.idrol == 7 ||
              this.state.idrol == 4 ? ( */}
              <>
                <Route
                  path="/planes-de-accion"
                  component={PlanesAccion}
                  exact
                />
                <Route
                  path="/NuevoPlanAccion"
                  component={NuevoPlanAccion}
                  exact
                />
                <Route
                  path="/opciones"
                  component={M_Opcion}
                  exact
                />
                <Route
                  path="/NuevaOpcion"
                  component={NuevaOpcion}
                  exact
                />
                <Route
                  path="/EditarOpcion"
                  component={EditarOpcion}
                  exact
                />
                <Route
                  path="/EditarPlanAccion"
                  component={EditarPlanAccion}
                  exact
                />
                <Route path="/decisiones" component={Desiciones} exact />
                <Route
                  path="/EditarDecisiones"
                  component={EditarDecisiones}
                  exact
                />
                <Route
                  path="/EditarLineaBase"
                  component={EditarLineaBase}
                  exact
                />
                {/* se agrega nueva ruta */}
                <Route path="/valoraciones" component={Valoraciones} exact />
                <Route path="/crearEfecto" component={CrearEfecto} exact />
                <Route path="/agregarEfectos" component={AgregarEfecto} exact />
                <Route path="/EditarEfecto" component={EditarEfecto} exact />
                <Route
                  path="/editarAgregarEfectos"
                  component={EditarAgregarEfectos}
                  exact
                />
              </>
              {/* // ) : (
              //   ""
              // )} */}
              {/* {this.state.idrol == 1 ||
              this.state.idrol == 2 ||
              this.state.idrol == 5 ||
              this.state.idrol == 3 ||
              this.state.idrol == 7 ||
              this.state.idrol == 4 ? ( */}
              <>
                <Route
                  path="/ethical-hacking"
                  component={EthicalHacking}
                  exact
                />
                <Route
                  path="/NuevoEthicalHacking"
                  component={NuevoEthicalHacking}
                  exact
                />
                {
                  <Route
                    path="/EditarEthicalHacking"
                    component={EditarEthicalHacking}
                    exact
                  />
                }
              </>
              {/* // ) : (
              //   ""
              // )} */}
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

export default Menu;
