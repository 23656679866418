import React, { useState } from 'react';
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ControlesEvaluacionPadre from './ControlesEvaluacionPadre';
import ResumenEfectividad from './ResumenEfectividad';
import AnalisisBrechas from './AnalisisBrechas';
import EscenariosMitigacion from './EscenariosMitigacion';
import LineasBaseEro from './LineasBaseEro';


const MainControles = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: "#2c2a29", color: "#ffffff" }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Controles" style={{ color: "#ffffff" }} />
                    <Tab label="Resumen Efectividad" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                    <Tab label="Análisis de Brechas" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                    <Tab label="Escenarios de Mitigación" style={{ color: "#ffffff", padding: "3px 7px 3px" }} />
                    <Tab label="Líneas Base" style={{ color: "#ffffff", padding: "3px 7px 3px" }} disabled/>
                </Tabs>
            </AppBar>
            {value === 0 && <ControlesEvaluacionPadre />}
            {value === 1 && <ResumenEfectividad  />}
            {value === 2 && <AnalisisBrechas  />}
            {value === 3 && <EscenariosMitigacion  />}
            {value === 4 && <LineasBaseEro  />}
        </div>
    );
};

export default MainControles;

